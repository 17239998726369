import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import PhoneInput from './PhoneInput';

const SubmitterGroup = ({ userInfo, sendInput, addContact, passedKey, val }) => {
  return (
    <>
      <Grid container justifyContent="flex-start">
        <Grid item>
          <TextField
            required
            variant="outlined"
            style={{ width: 300, margin: 20 }}
            label="Email"
            placeholder="Enter email"
            value={'submitterEmail' in val ? val.submitterEmail : userInfo.emailAddress}
            key={passedKey}
            onChange={(e) => {
              addContact(userInfo.firstName + ' ' + userInfo.lastName, e.target.value, 'submitter');
              sendInput('submitterEmail', e.target.value);
            }}
          />
        </Grid>
        {/* <Grid item xs>
                    <PhoneInput
                        isRequired={true}
                        name="submitterPhone"
                        label="Phone"
                        sendInput={sendInput}
                        val={val.submitterPhone}
                    />
                </Grid> */}
      </Grid>
    </>
  );
};

export default SubmitterGroup;

import { useEffect, useRef, useState, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import { GET_VCRS, CREATE_ERROR } from './graphql';

import { getComparator } from './utils';

import ComplianceIssueForm from './VCTForm';

import VCTDashCards from './VCTDashCards';
import VCTDetailsDialog from './VCTDetailsDialog';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Select,
  Slider,
  Stack,
  TableSortLabel,
  TextField,
  ToggleButton,
  Typography,
} from '@mui/material';

import {
  Add as AddIcon,
  ArrowLeft,
  ArrowRight,
  Close as CloseIcon,
  FilterAlt as FilterAltIcon,
  Sort as SortIcon,
  Sync as SyncIcon,
  Info as InfoIcon,
} from '@mui/icons-material';

// TODO: refactor website theme provider
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#c5050c',
      light: '#c5050c',
      dark: '#c5050c',
      contrastText: '#fff',
    },
  },
  typography: {
    h1: {
      fontFamily: "'Verlag',sans-serif",
      fontSize: '2em',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    h4: {
      fontFamily: "'Verlag',sans-serif",
      fontSize: '1rem',
    },
    label: {
      fontFamily: "'Verlag',sans-serif",
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    tableHead: { fontFamily: "'Verlag',sans-serif", fontSize: '1.25em', fontWeight: 'bold' },
  },
});

const VCTDashboard = ({ user }) => {
  // Data display toolbar state
  const [searchTerms, setSearchTerms] = useState('');
  const [statusFilter, setStatusFilter] = useState(['Overdue', 'Awaiting ACT Resolution', 'Awaiting Lab Resolution']);
  const [buildingFilter, setBuildingFilter] = useState([]);
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [typeFilter, setTypeFilter] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [visibleVCRs, setVisibleVCRs] = useState([]);
  const [filteredVCRLength, setFilteredVCRLength] = useState(0);
  const [createdAtRange, setCreatedAtRange] = useState([0, 7]);
  const vcrId = useParams().vcrId;

  // Dialog state
  const [selectedVCR, setSelectedVCR] = useState({});
  const [detailsDialog, setDetailsDialog] = useState(false);
  const [vcrFormDialog, setVCRFormDialog] = useState(new URLSearchParams(useLocation().search).has('form'));
  const [filterSortDialog, setFilterSortDialog] = useState(false);
  const [dateTooltip, setDateTooltip] = useState(false);

  const [errMessage, setErrMessage] = useState('');

  const [createError] = useMutation(CREATE_ERROR);
  // Get VCR data depending on user access
  const { loading, data, refetch } = useQuery(GET_VCRS, {
    variables: { userId: user._id },
    onCompleted: (data) => {
      setBuildingOptions(data.vcrs.length > 0 ? [...new Set(data.vcrs?.map((vcr) => vcr.buildingName))] : []);
      setTypeOptions(data.vcrs.length > 0 ? [...new Set(data.vcrs?.map((vcr) => vcr.type))] : []);

      if (vcrId) {
        setSelectedVCR(data.vcrs.find((o) => o._id === vcrId));
        setDetailsDialog(true);
      }
    },
    onError: (e) => {
      handleError(e);
    },
  });
  const vcrs = useMemo(() => data?.vcrs || [], [data]);

  // Update records displayed on change from toolbar
  useEffect(() => {
    let filteredVCRs;
    // Filter by search terms OR by filter menu filters
    if (searchTerms !== '') {
      const match = searchTerms.toString().toLowerCase();
      filteredVCRs = vcrs?.filter(
        (vcr) =>
          vcr.buildingName.toLowerCase().match(match) ||
          vcr.protocolLabel.toLowerCase().match(match) ||
          vcr.reportNumber.toString().match(match) ||
          vcr.roomNumber.toLowerCase().match(match)
      );
    } else if (vcrs?.length > 0) {
      // Filter by selected statuses and buildings
      filteredVCRs =
        vcrs?.filter(
          (vcr) =>
            (statusFilter.length > 0 ? statusFilter.includes(vcr.status) : true) &&
            (typeFilter.length > 0 ? typeFilter.includes(vcr.type) : true) &&
            (buildingFilter.length > 0 ? buildingFilter.includes(vcr.buildingName) : true)
        ) || [];
      // Update number of filtered vcrs
      setFilteredVCRLength(filteredVCRs?.length || 0);
    }
    // Sort by field
    const sortedVCRs =
      filteredVCRs
        ?.sort(getComparator(order, orderBy))
        ?.slice(rowsPerPage * (page - 1), Math.min(rowsPerPage * (page - 1) + rowsPerPage, vcrs?.length)) || [];

    // set data to display
    setVisibleVCRs(sortedVCRs);
  }, [vcrs, statusFilter, typeFilter, buildingFilter, order, orderBy, rowsPerPage, page, searchTerms]);

  // Update Details Dialog on change
  useEffect(() => {
    if (Object.keys(selectedVCR).length === 0) return;

    setSelectedVCR((x) => vcrs?.find((o) => o?._id === x?._id));
  }, [vcrs]);

  const sorting = [
    { id: 'statusPriority', label: 'Status' },
    { id: 'timeRemaining', label: 'Time Remaining' },
    { id: 'protocolLabel', label: 'Protocol' },
    { id: 'buildingName', label: 'Building' },
    { id: 'reason', label: 'Reason' },
    { id: 'reportNumber', label: 'Report #' },
    { id: 'createdAt', label: 'Time Submitted' },
  ];

  const statuses = [
    { id: 'Overdue', label: 'Overdue' },
    { id: 'Awaiting Lab Resolution', label: 'Awaiting Lab Resolution' },
    { id: 'Awaiting ACT Resolution', label: 'Awaiting ACT Resolution' },
    { id: 'Resolved', label: 'Resolved' },
  ];

  // HANDLERS

  const handleError = (e, action, data, message) => {
    createError({
      variables: {
        PVI: user.PVI,
        action,
        error: e ? JSON.stringify(e) : undefined,
        data: JSON.stringify({ ...data }),
      },
    });

    setErrMessage(
      `Something went wrong. ${message ? message : ''} 
      The BRMS IT office has received an error report and will work to resolve the issue.`
    );
  };

  const handleOpenDetailsDialog = (vcr) => {
    setSelectedVCR(vcr);
    setDetailsDialog(true);
  };

  const handleCloseDetailsDialog = () => {
    setSelectedVCR({});
    setDetailsDialog(false);
  };

  const handleStatusFilter = (status) => {
    if (statusFilter.includes(status)) {
      setStatusFilter(statusFilter.filter((x) => x !== status));
    } else {
      const newSF = statusFilter.slice();
      newSF.push(status);
      setStatusFilter(newSF);
    }

    // Reset page
    setPage(1);
  };

  const handleTypeFilter = (type) => {
    if (typeFilter.includes(type)) {
      setTypeFilter(typeFilter.filter((x) => x !== type));
    } else {
      const newTF = typeFilter.slice();
      newTF.push(type);
      setTypeFilter(newTF);
    }

    // Reset page
    setPage(1);
  };

  const handleBuildingFilter = (building) => {
    // Set toggle buttons
    if (buildingFilter.includes(building)) {
      setBuildingFilter(buildingFilter.filter((x) => x !== building));
    } else {
      const newBF = buildingFilter.slice();
      newBF.push(building);
      setBuildingFilter(newBF);
    }

    // Reset page
    setPage(1);
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // COMPONENT RENDER
  return (
    // <StandardWrapper xl>
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'center',
        paddingInline: '5%',
        paddingBlockStart: '2em',
        paddingBlockEnd: '1em',
      }}
    >
      <ThemeProvider theme={theme}>
        <Grid container gap={3} marginBottom="1em">
          <Grid item xs={12}>
            <Typography variant="h1">Vivarium Compliance Tool</Typography>
          </Grid>

          <Grid
            container
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '0.5em',
              }}
            >
              <Box sx={{ minWidth: '5em', alignSelf: 'center', flexGrow: 1 }}>
                <TextField
                  placeholder="Enter report #, protocol, building, or room to search"
                  value={searchTerms}
                  onChange={(e) => setSearchTerms(e.target.value)}
                  size="small"
                  inputProps={{ maxLength: 20 }}
                  fullWidth
                />
              </Box>

              {user?.groups?.filter((g) => ['admin', 'brms', 'brmsSuperuser'].includes(g)).length > 0 && (
                <Button variant="contained" onClick={() => setVCRFormDialog(true)} startIcon={<AddIcon />} sx={{}}>
                  Submit New Report
                </Button>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} align="center">
            {/* Filter, Sort Toolbar */}
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap-reverse',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '.25em',
              }}
            >
              <VCTPaginator
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                page={page}
                filteredVCRLength={filteredVCRLength}
                setPage={setPage}
              />
              <Box sx={{ alignSelf: 'center' }}>
                <Button variant="outlined" size="small" onClick={() => setFilterSortDialog(true)}>
                  Filter and Sort <FilterAltIcon />
                  <SortIcon />
                </Button>
                <Button variant="outlined" size="small" onClick={() => refetch()}>
                  <SyncIcon />
                </Button>
              </Box>
            </Box>
            {loading ? (
              <CircularProgress />
            ) : (
              <VCTDashCards
                vcrs={visibleVCRs}
                statusToggle={statusFilter}
                handleOpenDetailsDialog={handleOpenDetailsDialog}
                order={order}
                orderBy={orderBy}
                rowsPerPage={rowsPerPage}
                page={page}
              />
            )}
            <Box sx={{ padding: '.25em' }}>
              <VCTPaginator
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                page={page}
                filteredVCRLength={filteredVCRLength}
                setPage={setPage}
              />
            </Box>
          </Grid>
        </Grid>

        {/* Details Dialog */}
        <Dialog open={detailsDialog} onClose={handleCloseDetailsDialog} fullWidth>
          <DialogTitle>
            <Box sx={{ display: 'flex', gap: '1em', alignItems: 'center', justifyContent: 'space-between' }}>
              Report Details
              <IconButton onClick={handleCloseDetailsDialog}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <VCTDetailsDialog
            vcr={selectedVCR}
            refetch={refetch}
            user={user}
            handleCloseDetailsDialog={handleCloseDetailsDialog}
          />
        </Dialog>

        {/* Submit VCR Dialog */}
        <Dialog open={vcrFormDialog} onClose={() => setVCRFormDialog(false)} fullWidth>
          <DialogTitle variant="title">
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '1em',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              Report Compliance Issue
              <IconButton onClick={() => setVCRFormDialog(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <ComplianceIssueForm
            user={user}
            refetch={refetch}
            closeDialog={() => setVCRFormDialog(false)}
            handleError={handleError}
          />
        </Dialog>

        <Popover
          open={dateTooltip}
          anchorEl={document.getElementById('date-info')}
          onClose={() => setDateTooltip(false)}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{ sx: { backgroundColor: '#DADFE1', padding: '1em' } }}
        >
          To view records generated over 30 days ago, contact <a href="mailto:help@brms.wisc.edu">help@brms.wisc.edu</a>
        </Popover>

        <Dialog open={filterSortDialog} onClose={() => setFilterSortDialog(false)}>
          <Stack spacing=".5em" padding="1em">
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={() => setFilterSortDialog(false)} size="small">
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
            {/* Filter by Type */}
            <Typography variant="h4">Filter by type:</Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '.5em',
                flexWrap: 'wrap',
                paddingLeft: '1em',
              }}
            >
              {typeOptions.map((t) => (
                <FilterToggleButton
                  key={t}
                  value={t}
                  selected={typeFilter.includes(t)}
                  onChange={() => handleTypeFilter(t)}
                  color="primary"
                  size="small"
                >
                  {t}
                </FilterToggleButton>
              ))}
            </Box>

            {/* Filter by Status */}
            <Typography variant="h4">Filter by status:</Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '.5em',
                flexWrap: 'wrap',
                paddingLeft: '1em',
              }}
            >
              {statuses.map((s) => (
                <FilterToggleButton
                  key={s.id}
                  value={s.id}
                  selected={statusFilter.includes(s.id)}
                  onChange={() => handleStatusFilter(s.id)}
                  color="primary"
                  size="small"
                >
                  {s.label}
                </FilterToggleButton>
              ))}
            </Box>
            {/* Filter by Building */}
            <Typography variant="h4">Filter by building:</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '.5em', flexWrap: 'wrap', paddingLeft: '1em' }}>
              {buildingOptions.map((building, i) => (
                <FilterToggleButton
                  key={i}
                  value={building}
                  selected={buildingFilter.includes(building)}
                  onChange={() => handleBuildingFilter(building)}
                  color="primary"
                  size="small"
                >
                  {building}
                </FilterToggleButton>
              ))}
            </Box>
            {/* Filter by Date */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h4">Filter by date:</Typography>
              <IconButton id="date-info" size="small" sx={{ margin: '0' }} onClick={() => setDateTooltip(true)}>
                <InfoIcon />
              </IconButton>
            </Box>
            <Box sx={{ padding: '.5em .5em .5em 2em' }}>
              <Slider
                sx={{ width: 'max(50%, 200px)', fontSize: '.75rem' }}
                size="small"
                value={createdAtRange}
                onChange={(e) => {
                  setCreatedAtRange(e.target.value);
                  refetch({ createdAt: e.target.value });
                }}
                color="primary"
                step={null}
                marks={[
                  { label: 'Today', value: 0 },
                  { label: '1 week', value: 7 },
                  { label: '2 week', value: 14 },
                  { label: '3 week', value: 21 },
                  { label: '30 days', value: 30 },
                ]}
                max={30}
                disableSwap
              />
            </Box>
            {/* Sort by */}
            <Typography variant="h4">Sort by:</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '.5em', flexWrap: 'wrap', paddingLeft: '1em' }}>
              {sorting.map((s, i) => (
                <FilterToggleButton
                  key={i}
                  value={s.id}
                  selected={orderBy === s.id}
                  color="primary"
                  size="small"
                  onClick={createSortHandler(s.id)}
                >
                  {s.label}
                  <TableSortLabel active={orderBy === s.id} direction={orderBy === s.id ? order : 'asc'} />
                </FilterToggleButton>
              ))}
            </Box>
          </Stack>
        </Dialog>
        {/* Error Alert */}
        <Dialog open={!!errMessage} onClose={() => setErrMessage('')}>
          <DialogTitle>{'Error'}</DialogTitle>
          <DialogContent>
            <DialogContentText>{errMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setErrMessage(false)} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </Paper>
    // </StandardWrapper>
  );
};

export default VCTDashboard;

// SUBCOMPONENTS

function FilterToggleButton(props) {
  return <ToggleButton {...props} sx={{ paddingInline: '.5em', paddingBlock: '.25em', fontSize: '.75rem' }} />;
}

function VCTPaginator({ rowsPerPage, setRowsPerPage, page, filteredVCRLength, setPage }) {
  const incrementPage = () => {
    const maxPages = Math.ceil(filteredVCRLength / rowsPerPage);

    if (page >= maxPages) return;
    setPage((x) => x + 1);
  };

  const decrementPage = () => {
    if (page <= 1) return;
    setPage((x) => x - 1);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '.5em', flexWrap: 'wrap' }}>
      <Typography variant="h4">Reports per page:</Typography>
      <Select
        value={rowsPerPage}
        onChange={(e) => {
          setRowsPerPage(e.target.value);
          setPage(1);
        }}
        size="small"
        color="primary"
        sx={{ fontFamily: "'Verlag', sans-serif", height: '2em' }}
        // inputProps={{ sx: { fontSize: '1em' } }}
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={25}>25</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>

      <IconButton onClick={decrementPage}>
        <ArrowLeft />
      </IconButton>
      {filteredVCRLength <= 0
        ? '0 - 0 / 0'
        : `${rowsPerPage * (page - 1) + 1} - ${Math.min(
            rowsPerPage * (page - 1) + rowsPerPage,
            filteredVCRLength
          )} / ${filteredVCRLength}`}
      <IconButton onClick={incrementPage}>
        <ArrowRight />
      </IconButton>
    </Box>
  );
}

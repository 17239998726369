import React, { useState } from 'react'
import { FormControlLabel, TextField, Checkbox, Grid, Hidden } from '@material-ui/core';

const HazardousSubstanceGroup = ({ sendInput, val, active, requireDescription }) => {
    const [isChecked, setIsChecked] = useState(false);

    return (
        <>
            <Grid item xs>
                <FormControlLabel
                    className="checkbox"
                    style={{ marginBottom: 10 }}
                    control={<Checkbox
                        id="hazard-box"
                        checked={val.hazardExposure || false}
                        onChange={(e) => {
                            setIsChecked(!isChecked)
                            sendInput("hazardExposure", e.target.checked)
                        }}
                    ></Checkbox>}
                    label="Exposed to Hazardous Substances"
                ></FormControlLabel>
            </Grid>
            {(isChecked || active) && (
                <>
                    <Hidden xsDown>
                        <TextField
                            required={requireDescription}
                            variant="outlined"
                            label={(isChecked || active) ? "Hazard Description" : ""}
                            placeholder="Describe details of expected hazards"
                            multiline={isChecked || active}
                            fullWidth
                            rows={3}
                            rowsMax={6}
                            value={val.biohazardAgent || null}
                            onChange={(e) => {
                                sendInput("biohazardAgent", e.target.value)
                            }}
                        ></TextField>
                    </Hidden>
                    <Hidden smUp>
                        <Grid item xs>
                            <TextField
                                required={requireDescription}
                                variant="outlined"
                                label={(isChecked || active) ? "Hazard Description" : ""}
                                placeholder="Describe details of expected hazards"
                                multiline={isChecked || active}
                                fullWidth
                                style={{ margin: 15, width: 300 }}
                                rows={3}
                                rowsMax={6}
                                value={val.biohazardAgent || null}
                                onChange={(e) => {
                                    sendInput("biohazardAgent", e.target.value)
                                }}
                            ></TextField>
                        </Grid>
                    </Hidden>
                </>)
            }
        </>
    );
}

export default HazardousSubstanceGroup;
import React from 'react';
import NotAuthorized from './NotAuthorized';
import { useParams } from "react-router-dom";
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import Login from './Login';
import Auth from "./Auth";
import { LinearProgress } from '@material-ui/core';
import StandardWrapper from './design/StandardWrapper';

/* Default authorized groups are ['user', 'arrowUser', 'superUser', 'admin'] */
const checkGroups = (user, authorizedGroups) => {
  if (!user || !Array.isArray(user.groups)) {
    return false
  }
  for (let i = 0; i < user.groups.length; i++) {
    if (authorizedGroups.includes(user.groups[i])) {
      return true;
    }
  }
  return false;
}

const PrivateRoute = ({ component: Component, authorized, authenticated, authInitialized, onAuth, user, authorizedGroups, resetState, ...rest }) => {
  localStorage.removeItem('pathname')
  authorizedGroups = authorizedGroups || ['user', 'arrowUser', 'superUser', 'admin', 'vet'];
  const token = localStorage.getItem('rmaToken')
  if (!token && authInitialized) {
    // Force auth check if token is missing
    // Prevents preserved session after logout in separate tab
    resetState()
    return (
      <StandardWrapper>
        <LinearProgress />
      </StandardWrapper>
    );
  }

  return (
    <Route
      {...rest}
      render={({ location: { pathname } }) => {
        if (!authInitialized) return <Auth onAuth={onAuth} pathname={pathname} />
        if (!authenticated) return <Login pathname={pathname} />
        if (!authorized) return <NotAuthorized user={user} />
        return checkGroups(user, authorizedGroups) ? <Component user={user} /> : <NotAuthorized user={user} />
      }}
    />
  );
};

export default PrivateRoute;
import React, { useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  FormControlLabel,
  FormControl,
  MenuItem,
  Select,
  Checkbox,
  InputLabel,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import ProtocolSearch from '../inputs/ProtocolSearch';
import BuildingSearch from '../inputs/BuildingSearch';
import UserSearch from '../inputs/UserSearch';
import { gql } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { buildingNameToCode } from '../../utils';
import StandardWrapper from '../design/StandardWrapper';
import SpeciesSearch from '../inputs/SpeciesSearch';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export const notificationActions = [
  {
    action: 'submitAnimalOrder',
    title: 'Animal Order',
  },
  {
    action: 'submitTransfer',
    title: 'Transfer',
  },
  {
    action: 'submitImport',
    title: 'Import',
  },
  {
    action: 'submitExport',
    title: 'Export',
  },
  {
    action: 'orderInventory',
    title: 'Supply Order',
  },
  {
    action: 'submitSAR',
    title: 'Sick Animal Report',
  },
  {
    action: 'emergencyAlert',
    title: 'Emergency Alert',
  },
  {
    action: 'emergencyAlertNoReply',
    title: 'Emergency Alert (NO REPLIES)',
  },
  {
    action: 'fastRequest',
    title: 'Fasting Request',
  },
  {
    action: 'procedureRequest',
    title: 'Procedure Request',
  },
  {
    action: 'createError',
    title: 'New Error',
  },
];

const buildings = [
  'Ansci-Animal Sciences',
  'Biochemistry',
  'Biochemistry Addition',
  'Biotech',
  'Biotron',
  'Birge',
  'Bock Lab',
  'Brogden',
  'Charmany Instructional',
  'CSC-Clinical Science Center',
  'Harlow',
  'Integrative Biology Research',
  'Livestock Lab',
  'McArdle',
  'Microbial',
  'MSC-Medical Sciences Center',
  'NutSci-Nutritional Sciences',
  'PRL-Poultry Lab',
  'Rennebohm',
  'Robert P Hanson Biomedical Sciences Laboratories',
  'Russell Lab',
  'SMI Bardeen',
  'SVM-Veterinary Medicine',
  'Waisman Center',
  'Water Science and Engineering Laboratory',
  'WIMR',
  'Wisconsin Institute of Discovery',
  'WisPIC-Wisconsin Psychiatric Institute And Clinics',
];

const GET_USERS = gql`
  {
    users {
      PVI
      firstName
      lastName
      emailAddress
      groups
    }
  }
`;

const CreateNotificationCondition = ({ user, onSubmit, onClose }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState();
  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(true);
  const [action, setAction] = useState('');
  const [protocolNumber, setProtocolNumber] = useState();
  const [building, setBuilding] = useState();
  const [arrowProtocol, setArrowProtocol] = useState(true);
  const [toast, setToast] = useState();
  const [errorDialog, setErrorDialog] = useState();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [userText, setUserText] = useState();
  const [PVI, setPVI] = useState();
  const [species, setSpecies] = useState('');
  const [key, setKey] = useState(false);

  const { loading: loadingUsers, error, data, refetch } = useQuery(GET_USERS);

  const handleCheck = (checked) => {
    setProtocolNumber('');
    setArrowProtocol(checked);
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToast(false);
    setErrorDialog(false);
  };

  const resetForm = () => {
    setEmailAddress('');
    setAction('');
    setProtocolNumber('');
    setBuilding('');
    setUserText('');
    setPVI('');
    setArrowProtocol(true);
    setKey(!key);
    setPhoneNumber('1');
    setPhoneNumberIsValid(true);
    setSpecies('');
  };

  const CREATE_ERROR = gql`
    mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
      createError(PVI: $PVI, action: $action, error: $error, data: $data)
    }
  `;
  const [createError] = useMutation(CREATE_ERROR);

  const CREATE_NOTIFICATION_CONDITION = gql`
    mutation CreateNotificationCondition(
      $PVI: String
      $emailAddress: String
      $phoneNumber: String
      $value: Boolean
      $action: String
      $species: String
      $protocolNumber: String
      $building: String
    ) {
      createNotificationCondition(
        PVI: $PVI
        emailAddress: $emailAddress
        phoneNumber: $phoneNumber
        value: $value
        species: $species
        action: $action
        protocolNumber: $protocolNumber
        building: $building
      ) {
        autoGenerated
      }
    }
  `;

  const getVariables = () => {
    return {
      value: true,
      protocolNumber,
      building: buildingNameToCode(building) || building,
      action,
      species,
      emailAddress,
      phoneNumber: phoneNumber === '1' ? '' : phoneNumber,
      PVI,
    };
  };
  const [createNotificationCondition] = useMutation(CREATE_NOTIFICATION_CONDITION, {
    onError(e) {
      setLoading(false);
      setErrorDialog(true);
      setToast(false);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'createNotificationCondition',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            vars: getVariables(),
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setLoading(false);
        setToast(true);
        resetForm();
        onSubmit();
        setSubmitting(false);
      } else setToast(false);
    },
  });

  const validate = () => {
    if (phoneNumber && phoneNumber !== '1' && phoneNumber.length !== 11) {
      setPhoneNumberIsValid(false);
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    const variables = getVariables();
    console.log('variables: ', variables);
    createNotificationCondition({ variables });
  };

  const handleSelectUser = (user) => {
    const { PVI, emailAddress, firstName, lastName } = user;
    setPVI(PVI);
    setEmailAddress(emailAddress);
    setUserText(`${firstName} ${lastName}`);
  };

  return submitting ? (
    <StandardWrapper>
      <CircularProgress />
    </StandardWrapper>
  ) : (
    <StandardWrapper>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (validate()) {
            setLoading(true);
            handleSubmit();
          }
        }}
      >
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <h1> Create New Notification </h1>
          </Grid>
          <Grid item xs={9}>
            <UserSearch
              sendInput={(name, value) => setUserText(value)}
              name="userText"
              val={userText}
              key={key}
              onSelectUser={handleSelectUser}
              loading={loadingUsers}
              error={error}
              data={data}
              required={false}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              variant="outlined"
              style={{ width: 300, margin: 10 }}
              required={!phoneNumber}
              key={key}
              label="Email Address"
              type="email"
              value={emailAddress}
              onChange={(e) => {
                e.preventDefault();
                setEmailAddress(e.target.value);
              }}
            />
          </Grid>
          <div style={{ margin: '10px auto' }}>
            <PhoneInput
              inputProps={{ style: { width: 300, height: 54 }, required: !emailAddress }}
              isValid={phoneNumberIsValid}
              placeholder="Phone Number"
              countryCodeEditable={false}
              key={key}
              country={'us'}
              disableDropdown
              required={!emailAddress}
              defaultErrorMessage="Invalid Phone Number"
              value={phoneNumber}
              onChange={(value) => {
                setPhoneNumber(value);
              }}
            />
          </div>
          <Grid item xs={9}>
            {arrowProtocol ? (
              <ProtocolSearch
                name="protocol"
                required={false}
                key={key}
                onSelectProtocol={(x) => {
                  console.log('x: ', x);

                  if (x) {
                    setProtocolNumber(x.protocolNumber);
                  } else {
                    setProtocolNumber(x);
                  }
                }}
                val={protocolNumber}
                allowFreeFill
              />
            ) : (
              <TextField
                variant="outlined"
                style={{ width: 300, marginTop: 10, marginBottom: 10 }}
                label="Protocol"
                value={protocolNumber}
                onChange={(e) => {
                  setProtocolNumber(e.target.value);
                }}
              />
            )}
          </Grid>
          <Grid item xs={9}>
            <FormControlLabel
              className="checkbox"
              style={{ width: 300, marginLeft: 10, marginBottom: 10 }}
              control={
                <Checkbox
                  checked={arrowProtocol}
                  onChange={(e) => {
                    handleCheck(e.target.checked);
                  }}
                ></Checkbox>
              }
              label="ARROW Protocol"
            ></FormControlLabel>
          </Grid>
          <Grid item xs={9}>
            <FormControl style={{ width: 300, margin: 10 }}>
              {/* <InputLabel id="action-select-label">Event</InputLabel> */}
              <Select
                labelId="action-select-label"
                id="action-select"
                value={action}
                defaultValue={action}
                onChange={(e) => {
                  setAction(e.target.value);
                }}
                displayEmpty
                style={{ textAlign: 'left' }}
                variant="outlined"
              >
                <MenuItem value="">
                  <span style={{ color: '#666', fontWeight: 300 }}>Event</span>
                </MenuItem>
                {notificationActions.map(({ action, title }) => (
                  <MenuItem value={action} id={action}>
                    {title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={9}>
            <BuildingSearch
              key={key}
              val={building}
              options={buildings}
              sendInput={(x, value) => {
                setBuilding(value);
              }}
              label="Building"
              required={false}
            />
          </Grid>
          <Grid item xs={9}>
            <SpeciesSearch
              key={key}
              isRequired={false}
              useStandardList
              val={species}
              sendInput={(path, value) => {
                setSpecies(value);
              }}
            />
          </Grid>

          <Grid item xs={9}>
            {onClose && (
              <Button
                style={{ width: 125, marginRight: 50 }}
                variant="contained"
                // color="secondary"
                onClick={onClose}
              >
                Close
              </Button>
            )}
            <Button style={{ width: 125 }} variant="contained" color="primary" type="submit" disabled={loading}>
              Submit
              {loading && (
                <CircularProgress disableShrink style={{ position: 'absolute', zIndex: 2, color: 'white' }} size={24} />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar open={toast} autoHideDuration={6000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          Notification has been created!
        </Alert>
      </Snackbar>
      <Dialog open={errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Failed to create notification</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </StandardWrapper>
  );
};

export default CreateNotificationCondition;

import React, { useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  FormControlLabel,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Checkbox,
  MenuItem,
  Hidden,
  List,
  Chip,
  useMediaQuery,
  RadioGroup,
  FormLabel,
  FormControl,
  Radio,
  Typography,
  Tooltip,
  Avatar,
} from '@material-ui/core';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import StandardWrapper from '../design/StandardWrapper';
import ProtocolSearch from '../inputs/ProtocolSearch';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import { buildingNameToCode } from '../../utils';
import { KeyboardArrowLeft } from '@material-ui/icons';
import BuildingSearch from '../inputs/BuildingSearch';
import SpeciesSearch from '../inputs/SpeciesSearch';
import SelectInput from '../inputs/SelectInput';
import HazardousSubstanceGroup from '../inputs/HazardousSubstanceGroup';
import ConditionalClearInput from '../inputs/ConditionalClearInput';

const ProcedureRequestForm = ({ user }) => {
  const [userInfo] = useState(user);

  // Mutation variables
  const [protocolNumber, setProtocolNumber] = useState();
  const [contactName, setContactName] = useState((userInfo.firstName || '') + ' ' + (userInfo.lastName || ''));
  const [contactEmail, setContactEmail] = useState(userInfo.emailAddress);
  const [contactPhone, setContactPhone] = useState();
  const [billingString, setBillingString] = useState();
  const [building, setBuilding] = useState();
  const [room, setRoom] = useState();
  const [species, setSpecies] = useState();
  const [numMales, setNumMales] = useState();
  const [numFemales, setNumFemales] = useState();
  const [numNoSex, setNumNoSex] = useState();
  const [bsl, setBsl] = useState(0);
  const [hazardExposure, setHazardExposure] = useState(false);
  const [biohazardAgent, setBiohazardAgent] = useState();
  const [servicesRequested, setServicesRequested] = useState([]);
  const [animalIds, setAnimalIds] = useState();
  const [additionalDetails, setAdditionalDetails] = useState();
  const [PIName, setPIName] = useState();
  const [dateRequested, setDateRequested] = useState();
  const [timeRequested, setTimeRequested] = useState();
  // const [text, setText] = useState()

  // Append to additional details
  const [collectionLocation, setCollectionLocation] = useState();
  const [organ, setOrgan] = useState();
  const [organType, setOrganType] = useState();
  const [organPart, setOrganPart] = useState();
  const [cutType, setCutType] = useState();
  const [perfusionRequired, setPerfusionRequired] = useState();
  const [fixative, setFixative] = useState();
  const [transportMethod, setTransportMethod] = useState();

  // UI and misc. form state variables
  const [errorDialog, setErrorDialog] = useState();
  const [dateWeekendDialog, setDateWeekendDialog] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState();
  const [numAnimalDialog, setNumAnimalDialog] = useState();
  const [key, setKey] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [protocol, setProtocol] = useState();
  const [arrowProtocol, setArrowProtocol] = useState(true);
  const [save, setSave] = useState(false);
  const [defaultLoc, setDefaultLoc] = useState(true);
  const smallScreen = useMediaQuery('(max-width:600px)');

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
    setErrorDialog(false);
    setNumAnimalDialog(false);
    setDateWeekendDialog(false);
    setDateError(false);
  };

  const resetForm = () => {
    if (!save) {
      setProtocolNumber();
      setContactName((userInfo.firstName || '') + ' ' + (userInfo.lastName || ''));
      setContactEmail(userInfo.emailAddress);
      setContactPhone();
      setBillingString();
      setBuilding();
      setRoom();
      setSpecies();
      setNumMales();
      setNumFemales();
      setNumNoSex();
      setBsl(0);
      setHazardExposure(false);
      setBiohazardAgent();
      setServicesRequested([]);
      setPIName();
      setDateRequested();
      setTimeRequested();
      setAnimalIds();
      setAdditionalDetails();
      resetConditionalDetails();
      setKey(!key);
    }
  };

  const resetConditionalDetails = () => {
    setCollectionLocation();
    setOrgan();
    setCutType();
    setFixative();
    setTransportMethod();
    setPerfusionRequired();
    setKey(!key);
  };

  const CREATE_ERROR = gql`
    mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
      createError(PVI: $PVI, action: $action, error: $error, data: $data)
    }
  `;
  const [createError] = useMutation(CREATE_ERROR);

  // const ADD_NOTE_PROCEDURE_REQUEST = gql`
  //     mutation addNoteProcedureRequest (
  //         $text: String!
  //         $_id: ID!
  //     ) {
  //         addNoteProcedureRequest (
  //             text: $text
  //             _id: $_id
  //         ) {
  //             _id
  //         }
  //     }
  // `;

  const CREATE_PROCEDURE_REQUEST = gql`
    mutation createProcedureRequest(
      $protocolNumber: String!
      $PIName: String!
      $contactName: String!
      $contactEmail: String!
      $contactPhone: String!
      $billingString: String!
      $building: String!
      $room: String
      $species: String!
      $numMales: Int!
      $numFemales: Int!
      $numNoSex: Int!
      $bsl: Int!
      $hazardExposure: Boolean!
      $biohazardAgent: String
      $servicesRequested: [String!]!
      $animalIds: String
      $additionalDetails: String
      $dateRequested: String!
    ) {
      createProcedureRequest(
        protocolNumber: $protocolNumber
        PIName: $PIName
        contactName: $contactName
        contactEmail: $contactEmail
        contactPhone: $contactPhone
        billingString: $billingString
        building: $building
        room: $room
        species: $species
        numMales: $numMales
        numFemales: $numFemales
        numNoSex: $numNoSex
        bsl: $bsl
        hazardExposure: $hazardExposure
        biohazardAgent: $biohazardAgent
        servicesRequested: $servicesRequested
        animalIds: $animalIds
        additionalDetails: $additionalDetails
        dateRequested: $dateRequested
      ) {
        _id
      }
    }
  `;

  // const appendNewRow = (key, e) => {
  //     let temp = newRow;
  //     temp[key] = e.target.value;
  //     setNewRow(temp);
  // }
  const append = (x, y) => {
    if (!y) {
      if (x) {
        return x;
      }
      return '';
    }
    if (typeof x === 'string' && x.length > 0) {
      return `${x}, ${y}`;
    } else return y;
  };

  const appendServices = () => {
    let result = '';
    if (additionalDetails) {
      result = append(result, additionalDetails);
    }
    if (collectionLocation && servicesRequested.includes('Blood Collection')) {
      result = append(result, `collection location: ${collectionLocation}`);
    }
    if (Array.isArray(servicesRequested) && servicesRequested.includes('Tissue Collection')) {
      if (organ) {
        result = append(result, `organ: ${organ}`);
      }
      if (cutType) {
        result = append(result, `cut type: ${cutType}`);
      }
      if (fixative) {
        result = append(result, `fixative: ${fixative}`);
      }
      if (transportMethod) {
        result = append(result, `transport method: ${transportMethod}`);
      }
      result = perfusionRequired ? append(result, 'perfusion required') : append(result, 'perfusion NOT required');
    }
    return result;
  };

  const getDateRequested = () => {
    const dateMoment = moment(dateRequested);
    const timeMoment = moment(timeRequested);
    dateMoment.hour(timeMoment.hour());
    dateMoment.minute(timeMoment.minute());
    dateMoment.second(timeMoment.second());

    return dateMoment.toDate();
  };

  const getVariables = () => {
    return {
      protocolNumber,
      PIName,
      contactName,
      contactEmail,
      contactPhone,
      billingString,
      building: defaultLoc ? 'SMI Bardeen' : buildingNameToCode(building) || building,
      room: defaultLoc ? '' : room,
      species,
      numMales: numMales || 0,
      numFemales: numFemales || 0,
      numNoSex: numNoSex || 0,
      bsl,
      hazardExposure,
      biohazardAgent,
      servicesRequested,
      animalIds,
      additionalDetails: appendServices(),
      dateRequested: getDateRequested(),
    };
  };
  const handleFieldChange = (event) => {
    event.persist();
    let val = event.target.value;
    setServicesRequested(val);
  };

  // const [addNoteProcedureRequest] = useMutation(
  //     ADD_NOTE_PROCEDURE_REQUEST,
  //     {
  //         onError(e) {
  //             setLoading(false);
  //             setErrorDialog(true);
  //             setToast(false);
  //             createError({
  //                 variables: {
  //                     PVI: user.PVI,
  //                     action: "addNoteProcedureRequest",
  //                     error: e ? JSON.stringify(e) : undefined,
  //                     data: JSON.stringify({
  //                         user,
  //                         vars: {text}
  //                     })
  //                 }
  //             })
  //         },
  //         onCompleted() {
  //             setLoading(false);
  //         },
  //     }
  // )
  const [createProcedureRequest] = useMutation(CREATE_PROCEDURE_REQUEST, {
    onError(e) {
      setLoading(false);
      setErrorDialog(true);
      setToast(false);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'createProcedureRequest',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            vars: getVariables(),
          }),
        },
      });
    },
    onCompleted() {
      // if(text)
      //     addNoteProcedureRequest({ text })
      if (!errorDialog) {
        setLoading(false);
        setToast(true);
        resetForm();
      } else setToast(false);
    },
  });

  const handleSubmit = () => {
    const variables = getVariables();
    console.log('variables: ', variables);
    createProcedureRequest({ variables });
  };

  const validate = () => {
    let date = moment(getDateRequested());
    if (!date.isValid()) {
      setValidationError('Invalid date requested.');
      return false;
    }

    if (date.unix() < moment().unix()) {
      setValidationError('Date cannot be in the past.');
      return false;
    }

    if (!bsl) {
      setValidationError('Please select a biosafety level.');
      return false;
    }

    if (!Array.isArray(servicesRequested) || servicesRequested.length < 1) {
      setValidationError('Please select at least one service.');
      return false;
    }

    return true;
  };

  const handleSelectProtocol = ({ protocol: _protocol }) => {
    setProtocol(_protocol);
    if (_protocol && _protocol.protocolNumber) setProtocolNumber(_protocol.protocolNumber);
    else setProtocolNumber('');
    if (_protocol && _protocol.label)
      setPIName(
        `${_protocol.label.split(':')[1].split(',')[1].trim()} ${_protocol.label.split(':')[1].split(',')[0].trim()}`
      );
    else setPIName('');
  };

  return (
    <StandardWrapper>
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconButton
            aria-label="back"
            onClick={() => {
              window.location.href = '/procedures';
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (validate()) {
              if (numMales + numFemales + numNoSex <= 0) {
                setNumAnimalDialog(true);
                return;
              }
              setLoading(true);
              handleSubmit();
            }
          }}
        >
          <Grid item xs={12}>
            <h1>New Procedure Request</h1>
          </Grid>
          {arrowProtocol ? (
            <Grid container>
              <Grid item xs>
                <ProtocolSearch
                  name="protocol"
                  required
                  key={key}
                  onSelectProtocol={(protocol) => handleSelectProtocol({ protocol })}
                  val={protocolNumber}
                  sendInput={(_, value) => {
                    setProtocolNumber(value);
                  }}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  variant="outlined"
                  key={PIName}
                  style={{ width: 300, margin: 10 }}
                  label="PI"
                  value={PIName}
                  disabled
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs>
                <TextField
                  required
                  variant="outlined"
                  style={{ width: 300, margin: 10 }}
                  label="Protocol Number"
                  placeholder="Enter Protocol Number"
                  value={protocolNumber}
                  onChange={(e) => setProtocolNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  required
                  variant="outlined"
                  style={{ width: 300, margin: 10 }}
                  placeholder="Enter PI Name"
                  label="PI"
                  value={PIName}
                  onChange={(e) => setPIName(e.target.value)}
                />
              </Grid>
            </Grid>
          )}
          <Grid container>
            <Grid item xs>
              <FormControlLabel
                className="checkbox"
                style={{ width: 300, margin: 10 }}
                control={
                  <Checkbox
                    key={key}
                    defaultChecked
                    checked={arrowProtocol}
                    onChange={(e) => {
                      setArrowProtocol(e.target.checked);
                      setProtocol();
                      setProtocolNumber('');
                      setPIName('');
                      setBuilding('');
                      setSpecies('');
                      setKey(!key);
                    }}
                  />
                }
                label="ARROW Protocol"
              ></FormControlLabel>
            </Grid>
            <Grid item xs>
              <TextField
                required
                key={key}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Billing String"
                placeholder="Enter billing string"
                value={billingString}
                onChange={(e) => setBillingString(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <TextField
                required
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Contact Name"
                placeholder="Enter name"
                value={contactName ? contactName : (userInfo.firstName || '') + ' ' + (userInfo.lastName || '')}
                key={key}
                onChange={(e) => {
                  setContactName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs>
              <TextField
                required
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Contact Email"
                placeholder="Enter email"
                value={contactEmail ? contactEmail : userInfo.emailAddress}
                key={key}
                onChange={(e) => {
                  setContactEmail(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <TextField
                required
                key={key}
                style={{ width: 300, margin: 10 }}
                type="tel"
                variant="outlined"
                value={contactPhone}
                onChange={(e) => setContactPhone(e.target.value)}
                label="Contact Phone"
              />
            </Grid>

            <Grid item xs />
          </Grid>
          <Grid container>
            <Grid item xs>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  value={dateRequested || null}
                  inputVariant="outlined"
                  disablePast
                  style={{ width: 300, margin: 10 }}
                  className="to-clear"
                  label="Requested Service Date"
                  placeholder="Requested Service Date"
                  key={dateWeekendDialog + key}
                  format="MM/dd/yyyy"
                  InputProps={{
                    inputProps: {
                      required: true,
                      id: 'procedure-date-input',
                    },
                  }}
                  InputLabelProps={{
                    required: true,
                    shrink: true,
                  }}
                  onChange={(e) => {
                    let date = new Date(Date.parse(e));
                    let now = new Date();
                    let diff = Math.abs(now - date) / 36e5;

                    const isWeekend = date.getDay() === 0 || date.getDay() === 6;
                    if (isWeekend) setDateWeekendDialog(true);

                    if (!(userInfo.groups.includes('rs') || userInfo.groups.includes('admin'))) {
                      if (diff < 48) {
                        setDateError(true);
                        return;
                      }
                    }

                    setDateRequested(moment(e));
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  label="Requested Service Time"
                  placeholder="Requested Service Time"
                  mask="__:__ _M"
                  value={timeRequested || null}
                  onChange={setTimeRequested}
                  inputVariant="outlined"
                  style={{ width: 300, margin: 10 }}
                  InputLabelProps={{
                    required: true,
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: {
                      required: true,
                    },
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <FormControlLabel
                className="checkbox"
                style={{ width: 300, margin: 10 }}
                control={<Checkbox defaultChecked onChange={(e) => setDefaultLoc(e.target.checked)} />}
                label="Perform procedure in Research Services Core"
              />
            </Grid>
            <Grid item xs={6} />
          </Grid>
          {!defaultLoc && (
            <Grid container>
              <Grid item xs>
                <BuildingSearch
                  required={false}
                  key={key}
                  freeSolo={!arrowProtocol}
                  val={building}
                  protocol={protocol}
                  required={false}
                  sendInput={(x, value) => {
                    setBuilding(value);
                  }}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  required={building && building !== 'SMI Bardeen'}
                  key={key}
                  variant="outlined"
                  style={{ width: 300, margin: 10 }}
                  label="Room"
                  placeholder="Enter room #"
                  value={room}
                  onChange={(e) => setRoom(e.target.value)}
                />
              </Grid>
            </Grid>
          )}
          <Grid container>
            <Grid item xs>
              <SpeciesSearch
                isRequired
                noType={arrowProtocol}
                key={key}
                protocol={protocol}
                sendInput={(_, value) => {
                  setSpecies(value);
                }}
                val={species}
              />
            </Grid>
            <Grid item xs>
              <TextField
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                key={key}
                label="Animal IDs"
                value={animalIds}
                onChange={(e) => {
                  setAnimalIds(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <TextField
                required={(numMales || 0) + (numFemales || 0) + (numNoSex || 0) < 1}
                type="number"
                onWheel={(e) => e.target.blur()}
                InputProps={{ inputProps: { min: 0 } }}
                key={key}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Num. Males"
                placeholder="Enter # of males"
                value={numMales}
                onChange={(e) => setNumMales(parseInt(e.target.value))}
              />
            </Grid>
            <Grid item xs>
              <TextField
                required={(numMales || 0) + (numFemales || 0) + (numNoSex || 0) < 1}
                type="number"
                onWheel={(e) => e.target.blur()}
                InputProps={{ inputProps: { min: 0 } }}
                key={key}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Num. Females"
                placeholder="Enter # of females"
                value={numFemales}
                onChange={(e) => setNumFemales(parseInt(e.target.value))}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <TextField
                required={(numMales || 0) + (numFemales || 0) + (numNoSex || 0) < 1}
                type="number"
                onWheel={(e) => e.target.blur()}
                InputProps={{ inputProps: { min: 0 } }}
                key={key}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Num. No Sex Preference"
                placeholder="# of animals of arbitrary sex"
                value={numNoSex}
                onChange={(e) => setNumNoSex(parseInt(e.target.value))}
              />
            </Grid>
            <Grid item xs>
              <SelectInput
                menu={[
                  { value: 1, text: '1' },
                  { value: 2, text: '2' },
                ]}
                label="Biosafety Level"
                key={key}
                val={bsl}
                required
                onChange={(e) => setBsl(parseInt(e.target.value))}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            alignItems={smallScreen ? '' : 'flex-start'}
            style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 15 }}
          >
            <HazardousSubstanceGroup
              val={{
                hazardExposure: hazardExposure,
                biohazardAgent: biohazardAgent,
              }}
              key={key}
              sendInput={(name, val) => {
                name === 'hazardExposure' ? setHazardExposure(val) : setBiohazardAgent(val);
              }}
              active={bsl === 2}
              requireDescription
            />
          </Grid>
          <Grid container>
            <Grid item xs>
              <TextField
                select
                key={key}
                name="services"
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Services Requested"
                SelectProps={{
                  multiple: true,
                  value: servicesRequested,
                  onChange: handleFieldChange,
                }}
              >
                <MenuItem value="Administering Special Diets / Water">Administering Special Diets / Water</MenuItem>
                <MenuItem value="Blood Collection">Blood Collection</MenuItem>
                <MenuItem value="Tissue Collection">Tissue Collection</MenuItem>
                <MenuItem value="Perfusion">Perfusion</MenuItem>
                <MenuItem value="Intraperitoneal Injections"> Intraperitoneal Injections</MenuItem>
                <MenuItem value="Subcutaneous Injections">Subcutaneous Injections</MenuItem>
                <MenuItem value="Tamoxifen Administration">Tamoxifen Administration</MenuItem>
                <MenuItem value="Retro-orbital Injections">Retro-orbital Injections</MenuItem>
                <MenuItem value="Tail Vein Injections">Tail Vein Injections</MenuItem>
                <MenuItem value="Intramuscular Injections">Intramuscular Injections</MenuItem>
                <MenuItem value="Timed Mating/Embryo Harvest">Timed Mating/Embryo Harvest</MenuItem>
                <MenuItem value="Tumor/Cell Grafts">Tumor/Cell Grafts</MenuItem>
                <MenuItem value="Glucose Tolerance Testing">Glucose Tolerance Testing</MenuItem>
                <MenuItem value="Oral Gavage">Oral Gavage</MenuItem>
                <MenuItem value="Renal Capsule Transplant">Renal Capsule Transplant</MenuItem>
                <MenuItem value="Other">Other (specify in add'l description)</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6} />
          </Grid>
          <List className="service-list">
            {servicesRequested.map((service) => {
              return (
                <Chip
                  color="secondary"
                  onDelete={({ id = service }) => {
                    let temp = servicesRequested.filter((entry) => entry !== id);
                    setServicesRequested(temp);
                  }}
                  // avatar={<Avatar>{service.charAt(0).toUpperCase()}</Avatar>}
                  label={service}
                  style={{
                    margin: 5,
                  }}
                ></Chip>
              );
            })}
          </List>
          <Hidden xsDown>
            <Grid container>
              <Grid item xs>
                <TextField
                  required={servicesRequested.includes('Other')}
                  variant="outlined"
                  key={key}
                  label="Additional Details"
                  multiline
                  fullWidth
                  rows={3}
                  rowsMax={6}
                  value={additionalDetails}
                  style={{ margin: 10, width: 620 }}
                  onChange={(e) => setAdditionalDetails(e.target.value)}
                ></TextField>
              </Grid>
            </Grid>
            {servicesRequested.includes('Blood Collection') ? (
              <Grid container>
                <Grid item xs>
                  <SelectInput
                    menu={[
                      { value: 'Maxillary', text: 'Maxillary' },
                      { value: 'Axillary', text: 'Axillary' },
                      { value: 'Retro-orbital', text: 'Retro-orbital' },
                      { value: 'Cardiac', text: 'Cardiac' },
                    ]}
                    label="Collection Location"
                    key={key}
                    val={collectionLocation}
                    required
                    onChange={(e) => setCollectionLocation(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} />
              </Grid>
            ) : (
              <></>
            )}
            {servicesRequested.includes('Tissue Collection') ? (
              <>
                <Grid container>
                  <Grid item xs>
                    <TextField
                      variant="outlined"
                      label="Desired Organ"
                      style={{ margin: 10, width: 300 }}
                      key={key}
                      value={organ}
                      onChange={(e) => setOrgan(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Organ Type</FormLabel>
                      <RadioGroup
                        row
                        aria-label="organ type"
                        name="organ type"
                        value={organType}
                        onChange={setOrganType}
                      >
                        <FormControlLabel value="partial" control={<Radio />} label="Partial Organ" />
                        <FormControlLabel value="whole" control={<Radio />} label="Whole Organ" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                {organType === 'partial' ? (
                  <>
                    <TextField
                      variant="outlined"
                      label="Desired Organ Part"
                      style={{ margin: 10, width: 300 }}
                      key={key}
                      value={organPart}
                      onChange={(e) => setOrganPart(e.target.value)}
                    />
                  </>
                ) : (
                  <></>
                )}
                <Grid container>
                  <Grid item xs>
                    <SelectInput
                      menu={[
                        { value: 'Longitudinal', text: 'Longitudinal' },
                        { value: 'Coronal', text: 'Coronal' },
                        { value: 'Traverse', text: 'Traverse' },
                      ]}
                      label="Cut Type"
                      key={key}
                      val={cutType}
                      required
                      onChange={(e) => setCutType(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs>
                    <SelectInput
                      menu={[
                        { value: 'Room Temperature', text: 'Room Temperature' },
                        { value: 'Ice', text: 'Ice' },
                        { value: 'Liquid Nitrogen', text: 'Liquid Nitrogen' },
                      ]}
                      label="Transport Method"
                      key={key}
                      val={transportMethod}
                      required
                      onChange={(e) => setTransportMethod(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs>
                    <FormControlLabel
                      className="checkbox"
                      style={{ width: 300, margin: 10 }}
                      control={
                        <Checkbox
                          key={key}
                          checked={perfusionRequired}
                          onChange={(e) => {
                            setPerfusionRequired(e.target.checked);
                          }}
                        />
                      }
                      label="Perfusion Required"
                    ></FormControlLabel>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      required
                      variant="outlined"
                      style={{ width: 300, margin: 10 }}
                      label="Fixative"
                      placeholder="Enter fixative"
                      value={fixative}
                      key={key}
                      onChange={(e) => {
                        setFixative(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
            {/* <Grid container>
                            <Grid item xs>
                                <TextField
                                    variant="outlined"
                                    key={key}
                                    label="Message"
                                    multiline
                                    fullWidth
                                    rows={3}
                                    rowsMax={6}
                                    value={text}
                                    style={{ margin: 10, width: 620 }}
                                    onChange={e => setText(e.target.value)}
                                ></TextField>
                            </Grid>
                        </Grid> */}
            <Grid container>
              <Grid item xs>
                <ConditionalClearInput section="all" effect={setSave} label="Save form after submission" />
              </Grid>
              <Grid item xs={6} />
            </Grid>
            <Grid container>
              <Typography style={{ width: 620, margin: 10 }} variant="caption">
                Please be advised that we cannot guarantee dates/times of your request until reviewed and approved. All
                procedures that are requested must be described in an approved animal protocol. Procedures are completed
                on a first come first serve basis. We require at minimum 48 hours advance notice to start or cancel an
                experiment. If cancellation occurs within less than 48 hours you may be charged.
              </Typography>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  style={{ width: 125, margin: 10 }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  Submit
                  {loading && (
                    <CircularProgress
                      disableShrink
                      style={{ position: 'absolute', zIndex: 2, color: 'white' }}
                      size={24}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item>
              <TextField
                required={servicesRequested.includes('Other')}
                variant="outlined"
                key={key}
                label="Additional Details"
                multiline
                fullWidth
                rows={3}
                rowsMax={6}
                value={additionalDetails}
                style={{ margin: 10, width: 300 }}
                onChange={(e) => setAdditionalDetails(e.target.value)}
              ></TextField>
            </Grid>
            {servicesRequested.includes('Blood Collection') ? (
              <Grid item>
                <SelectInput
                  menu={[
                    { value: 'Maxillary', text: 'Maxillary' },
                    { value: 'Axillary', text: 'Axillary' },
                    { value: 'Retro-orbital', text: 'Retro-orbital' },
                    { value: 'Cardiac', text: 'Cardiac' },
                  ]}
                  label="Collection Location"
                  key={key}
                  val={collectionLocation}
                  required
                  onChange={(e) => setCollectionLocation(e.target.value)}
                />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item>
              <ConditionalClearInput section="all" label="Save form after submission" effect={setSave} />
            </Grid>
            <Grid item xs>
              <Typography style={{ width: 620, margin: 10 }} variant="caption">
                Please be advised that we cannot guarantee dates/times of your request until reviewed and approved. All
                procedures that are requested must be described in an approved animal protocol. Procedures are completed
                on a first come first serve basis. We require at minimum 48 hours advance notice to start or cancel an
                experiment. If cancellation occurs within less than 48 hours you may be charged.
              </Typography>
            </Grid>
            <Grid item>
              <Button
                style={{ margin: 10, width: 300 }}
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                Submit
                {loading && (
                  <CircularProgress
                    disableShrink
                    style={{ position: 'absolute', zIndex: 2, color: 'white' }}
                    size={24}
                  />
                )}
              </Button>
            </Grid>
          </Hidden>
        </form>
      </Grid>
      <Snackbar open={!!toast} autoHideDuration={6000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          Procedure Request Submitted!
        </Alert>
      </Snackbar>
      <Dialog open={!!errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Failed to submit procedure request. The BRMS IT office has been alerted of this issue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!validationError}
        onClose={() => {
          setValidationError();
        }}
      >
        <DialogTitle>{'Invalid Input'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{validationError}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setValidationError();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dateWeekendDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Requested date cannot be on a weekend.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={numAnimalDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Total number of animals must exceed zero.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dateError} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You may not submit a report that is less than 48 hours from the requested date.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {/*<Dialog
                open={!!bypassOpen}
            >
                <DialogTitle>{"Late Submission Warning"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You are attempting to submit a procedure request after the deadline. The deadline for procedure requests is 12:30 pm America/Chicago the day before the fast is to begin.
                        As a {user.groups.includes('admin') ? "website administrator" : "BRMS superuser"} you are authorized to bypass this restriction. By doing so, you agree
                        to take responsibitity for ensuring that the animal is fasted.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setBypassOpen(false) }} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={bypass} color="primary">
                        Agree and Bypass
                    </Button>
                </DialogActions>
            </Dialog>*/}
    </StandardWrapper>
  );
};

export default ProcedureRequestForm;

import React, { useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import CancelIcon from '@material-ui/icons/Cancel';
import { AppBar, Toolbar, Typography, IconButton, Drawer, ListItem, Link, List, ListItemText } from '@material-ui/core';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { logout } from '../utils';

const Navbar = ({ logout, user, authorizedGroups }) => {
  const [drawer, setDrawer] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawer(open);
  };

  const navLink = React.useMemo(() =>
    React.forwardRef((linkProps, ref) => <Link ref={ref} {...linkProps} component={RouterLink}></Link>)
  );

  return (
    <div id="site-navbar">
      <AppBar color="primary" position="sticky">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Link href="/" style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
            <Typography variant="h6" id="nav-title">
              Animal Health and Welfare
            </Typography>
          </Link>
        </Toolbar>
      </AppBar>
      <Drawer open={drawer} onClose={toggleDrawer(false)}>
        <List id="nav-list">
          <ListItem
            button
            component={navLink}
            to="/"
            onClick={() => {
              setDrawer(false);
            }}
          >
            <ListItemText>Home</ListItemText>
          </ListItem>
          <ListItem
            button
            component={navLink}
            to="/animal-order"
            onClick={() => {
              setDrawer(false);
            }}
          >
            <ListItemText>BRMS Animal Order</ListItemText>
          </ListItem>
          <ListItem
            button
            component={navLink}
            to="/internal-transfer"
            onClick={() => {
              setDrawer(false);
            }}
          >
            <ListItemText>Transfer</ListItemText>
          </ListItem>
          <ListItem
            button
            component={navLink}
            to="/import"
            onClick={() => {
              setDrawer(false);
            }}
          >
            <ListItemText>Import</ListItemText>
          </ListItem>
          <ListItem
            button
            component={navLink}
            to="/export"
            onClick={() => {
              setDrawer(false);
            }}
          >
            <ListItemText>Export</ListItemText>
          </ListItem>
          {/* <ListItem
            button
            component={navLink}
            to="/usda-fasting"
            onClick={() => {
              setDrawer(false);
            }}
          >
            <ListItemText>Fasting Requests</ListItemText>
          </ListItem> */}
          {/* <ListItem
            button
            component={navLink}
            to="/procedures"
            onClick={() => {
              setDrawer(false);
            }}
          >
            <ListItemText>Procedure Requests</ListItemText>
          </ListItem> */}
          <ListItem
            button
            component={navLink}
            to="/sds"
            onClick={() => {
              setDrawer(false);
            }}
          >
            <ListItemText>SDS</ListItemText>
          </ListItem>
          {/* {user && ['admin', 'brmsSuperuser', 'brms'].some((group) => user.groups.includes(group)) && (
            <ListItem
              button
              component={navLink}
              to="/BRMS-inventory-tools"
              onClick={() => {
                setDrawer(false);
              }}
            >
              <ListItemText>BRMS Supplies</ListItemText>
            </ListItem>
          )} */}
          {user && (
            <ListItem
              button
              component={navLink}
              to="/resource-scheduler"
              onClick={() => {
                setDrawer(false);
              }}
            >
              <ListItemText>Resource Scheduler</ListItemText>
            </ListItem>
          )}
          {user && (
            <ListItem
              button
              component={navLink}
              to="/user-notifications"
              onClick={() => {
                setDrawer(false);
              }}
            >
              <ListItemText>User Notifications</ListItemText>
            </ListItem>
          )}
          {/* {user && authorizedGroups.some((group) => user.groups.includes(group)) && (
            <ListItem
              button
              component={navLink}
              to="/admin"
              onClick={() => {
                setDrawer(false);
              }}
            >
              <ListItemText>Admin</ListItemText>
            </ListItem>
          )} */}
          <ListItem
            button
            component={navLink}
            to="/authentication-required"
            onClick={() => {
              logout();
              setDrawer(false);
            }}
          >
            <ListItemText>Log Out</ListItemText>
          </ListItem>
          <ListItem
            button
            component={navLink}
            onClick={() => {
              setDrawer(false);
            }}
          >
            <CancelIcon />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
};

export default withRouter(Navbar);

import React, { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import {
  Paper,
  LinearProgress,
  Typography,
  Grid,
  TextField,
  Tooltip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import { createFilterOptions, Autocomplete } from '@material-ui/lab';
import { isAuthError, buildingCodeToName } from '../utils';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import DownloadIcon from '@material-ui/icons/SaveAlt';
import { CSVLink, CSVDownload } from 'react-csv';
import CreateSurplusItemForm from './forms/CreateSurplusItemForm';
import SurplusItem from './SurplusItem';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddIcon from '@material-ui/icons/Add';
import StandardWrapper from './design/StandardWrapper';
import Fab from '@material-ui/core/Fab';
import SurplusItemDetail from './SurplusItemDetail';

const SURPLUS_ITEMS_QUERY = gql`
  query {
    surplusItems {
      _id
      name
      quantity
      building
      images {
        filename
        encoding
      }
      description
      dateSubmitted
      submittedBy {
        firstName
        lastName
        emailAddress
        PVI
      }
    }
  }
`;

const getRows = ({ data, filterInput }) => {
  if (data && Array.isArray(data.surplusItems)) {
    return [...data.surplusItems]
      .sort((x, y) => moment(x.dateSubmitted).unix() - moment(y.dateSubmitted).unix())
      .map((x) => {
        const {
          submittedBy: { firstName, lastName },
        } = x;
        const y = {
          ...x,
          building: buildingCodeToName(x.building) || x.building,
          contact: `${firstName} ${lastName}`,
          images: x.images.filter((x) => !!x),
        };
        delete y.__typename;

        return y;
      })
      .filter(({ name, building, contact, description }) => {
        if (typeof filterInput === 'string' && filterInput.length > 0) {
          const term = filterInput.toLowerCase();
          if (name.toLowerCase().includes(term)) {
            return true;
          }
          if (building.toLowerCase().includes(term)) {
            return true;
          }
          if (contact.toLowerCase().includes(term)) {
            return true;
          }
          if (typeof description === 'string' && description.toLowerCase().includes(term)) {
            return true;
          }
          return false;
        }
        return true;
      });
  }
  return [];
};

const SurplusItems = ({ user }) => {
  const { loading, error, data, refetch } = useQuery(SURPLUS_ITEMS_QUERY);
  const [key, setKey] = useState(false);
  const [filterInput, setFilterInput] = useState();
  const [creating, setCreating] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  let rows = getRows({ data, filterInput });
  // rows = []
  if ((loading || !data) && error) {
    if (isAuthError(error)) {
      window.location.href = '/login';
    }
    console.error('Error fetching surplus items.');
    console.error(error);
  }

  if (error && rows.length === 0) {
    console.log('error: ', error);
    return (
      <div>
        <Error />
      </div>
    );
  }
  console.log('rows: ', rows);

  if (creating) {
    return (
      <StandardWrapper>
        <CreateSurplusItemForm
          user={user}
          onSubmit={() => {
            setKey(!key);
            refetch();
          }}
          onClose={() => {
            setCreating(false);
          }}
        />
      </StandardWrapper>
    );
  }

  if (selectedItem) {
    return (
      <StandardWrapper>
        <SurplusItemDetail
          surplusItem={selectedItem}
          onClose={() => {
            setSelectedItem();
          }}
          user={user}
          onSubmit={refetch}
          onDelete={refetch}
          key={key}
        />
      </StandardWrapper>
    );
  }

  return loading ? (
    <LinearProgress style={{ width: '100%' }} />
  ) : (
    <Grid
      container
      style={{ minHeight: 500, padding: '8px, auto', marginBottom: 16 }}
      justifyContent="center"
      spacing={2}
    >
      <Grid item xs={12}>
        <h1>Surplus Items</h1>
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={filterInput}
          style={{ width: 490, backgroundColor: 'white' }}
          onChange={(e) => setFilterInput(e.target.value)}
          placeholder="Search"
          variant="outlined"
          color="primary"
        />
        <Tooltip title="Add Item">
          <IconButton
            aria-label="add item"
            onClick={() => {
              setCreating(true);
            }}
          >
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      {rows.length > 0 ? (
        rows.map((surplusItem) => (
          <Grid item key={surplusItem._id}>
            <SurplusItem
              surplusItem={surplusItem}
              onClick={() => {
                setSelectedItem(surplusItem);
              }}
            />
          </Grid>
        ))
      ) : filterInput ? (
        <Typography variant="h5" component="h2">
          There are currently no surplus items that match your search
        </Typography>
      ) : (
        <Typography variant="h5" component="h2">
          There are currently no surplus items available
        </Typography>
      )}
    </Grid>
  );
};

export default SurplusItems;

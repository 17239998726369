import React, { useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Hidden,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import StandardWrapper from '../design/StandardWrapper';
import { KeyboardArrowLeft } from '@material-ui/icons';
import _ from 'lodash';

const APIKeyForm = ({ user }) => {
  const [text, setText] = useState();
  const [allClear, setAllClear] = useState(false);

  const [errorDialog, setErrorDialog] = useState();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState();
  const [key, setKey] = useState(false);

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast();
    setErrorDialog(false);
  };

  const resetForm = () => {
    setText();
    setAllClear();
    setKey(!key);
  };

  const CREATE_ERROR = gql`
    mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
      createError(PVI: $PVI, action: $action, error: $error, data: $data)
    }
  `;
  const [createError] = useMutation(CREATE_ERROR);

  const SEND_EMERGENCY_ALERT = gql`
    mutation name($text: String!, $allClear: Boolean) {
      sendEmergencyAlert(text: $text, allClear: $allClear)
    }
  `;
  const getVariables = () => {
    return {
      text,
      allClear,
    };
  };

  const [sendEmergencyAlert] = useMutation(SEND_EMERGENCY_ALERT, {
    onError(e) {
      setLoading(false);
      setErrorDialog(true);
      setToast();
      createError({
        variables: {
          PVI: user.PVI,
          action: 'sendEmergencyAlert',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            vars: getVariables(),
          }),
        },
      });
    },
    onCompleted(res) {
      if (!errorDialog) {
        setLoading(false);
        setToast('Emergency Alert Sent!');
        resetForm();
      } else setToast();
    },
  });

  const handleSubmit = () => {
    const variables = getVariables();
    console.log('variables: ', variables);
    sendEmergencyAlert({ variables });
  };

  return (
    <StandardWrapper>
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconButton aria-label="back to home" href="/">
            <KeyboardArrowLeft />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setLoading(true);
            handleSubmit();
          }}
        >
          <Grid item xs={12}>
            <h1>BRMS Emergency Alert</h1>
          </Grid>

          <Hidden xsDown>
            <Grid container>
              <Grid item>
                <TextField
                  variant="outlined"
                  key={key}
                  required
                  label="Alert Message"
                  multiline
                  fullWidth
                  rows={3}
                  rowsMax={6}
                  value={text}
                  style={{ margin: 10, width: 620 }}
                  onChange={(e) => setText(e.target.value)}
                ></TextField>
              </Grid>
            </Grid>

            <Grid container justifyContent="space-between">
              <Grid item style={{ marginTop: 16 }}>
                <FormControlLabel
                  className="checkbox"
                  style={{ marginLeft: 0 }}
                  control={
                    <Checkbox
                      key={key}
                      checked={allClear}
                      onChange={(e) => {
                        setAllClear(e.target.checked);
                      }}
                    ></Checkbox>
                  }
                  label="All Clear"
                />
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  style={{ width: 125, margin: 10 }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading || !text}
                >
                  Submit
                  {loading && (
                    <CircularProgress
                      disableShrink
                      style={{ position: 'absolute', zIndex: 2, color: 'white' }}
                      size={24}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item>
              <TextField
                variant="outlined"
                required
                key={key}
                label="Alert Message"
                multiline
                fullWidth
                rows={3}
                rowsMax={6}
                value={text}
                style={{ margin: 10, width: 300 }}
                onChange={(e) => setText(e.target.value)}
              ></TextField>
            </Grid>
            <Grid item>
              <FormControlLabel
                className="checkbox"
                style={{ marginLeft: 0 }}
                control={
                  <Checkbox
                    key={key}
                    checked={allClear}
                    onChange={(e) => {
                      setAllClear(e.target.checked);
                    }}
                  ></Checkbox>
                }
                label="All Clear"
              />
            </Grid>

            <Grid item>
              <Button
                style={{ margin: 10, width: 300 }}
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading || !text}
              >
                Submit
                {loading && (
                  <CircularProgress
                    disableShrink
                    style={{ position: 'absolute', zIndex: 2, color: 'white' }}
                    size={24}
                  />
                )}
              </Button>
            </Grid>
          </Hidden>
        </form>
      </Grid>
      <Snackbar open={!!toast} autoHideDuration={6000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          {toast}
        </Alert>
      </Snackbar>
      <Dialog open={!!errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Failed to send alert. The BRMS IT office has been alerted of this issue, please alert appropriate staff by
            other means..
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </StandardWrapper>
  );
};

export default APIKeyForm;

import React from 'react';
import { Typography, Divider } from '@material-ui/core';

const FormDivider = ({text}) => {
    
    return (
        <>
            <br/><br/>
            <Typography
                color="textSecondary"
                display="block"
                align="left"
                variant="caption"
                className="form-divider"
            >
                {text}
            </Typography>
            <Divider/>
            <br/><br/>
        </>
    );
};

export default FormDivider;
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Button,
  Hidden,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  FormControlLabel,
  Checkbox,
  createTheme,
  MuiThemeProvider,
  Step,
  Stepper,
  StepLabel,
  Container,
  Chip,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import StandardWrapper from './design/StandardWrapper';
import { useQuery, useMutation } from '@apollo/client';
import { LinearProgress } from '@material-ui/core';
import { gql } from '@apollo/client';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { KeyboardArrowLeft, Edit } from '@material-ui/icons';
import { buildingCodeToName, getName } from '../utils';
import UpdateProcedureRequest from './forms/UpdateProcedureRequestForm';
import Messenger from './Messages/Messenger';
import UserSearch from './inputs/UserSearch';
import EmailList from './EmailList/EmailList';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

const GET_PROCEDURE_REQUEST = gql`
  query ProcedureRequestByNumber($number: Int!) {
    procedureRequestByNumber(number: $number) {
      _id
      protocolNumber
      submittedBy {
        firstName
        lastName
      }
      dateSubmitted
      number
      building
      animalIds
      dateRequested
      servicesRequested
      bsl
      hazardExposure
      biohazardAgent
      numMales
      numFemales
      numNoSex
      contactName
      contactEmail
      contactPhone
      billingString
      building
      room
      species
      additionalDetails
      cancelledBy {
        firstName
        lastName
      }
      updatedBy {
        firstName
        lastName
      }
      completedBy {
        firstName
        lastName
      }
      approvedBy {
        firstName
        lastName
      }
      assignedTo {
        firstName
        lastName
      }
      notes {
        text
        dateSubmitted
        submittedBy {
          firstName
          lastName
        }
      }
      dateApproved
      dateStarted
      dateCompleted
      dateUpdated
      dateCancelled
      permittedPVIs
      status
      PIName
      emailList
      secondaryAssignees {
        firstName
        lastName
        PVI
      }
    }
  }
`;

const CREATE_ERROR = gql`
  mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
    createError(PVI: $PVI, action: $action, error: $error, data: $data)
  }
`;

const TAKE_ACTION = gql`
  mutation TakeAction($_id: ID!, $action: String!) {
    takeActionProcedureRequest(_id: $_id, action: $action) {
      status
    }
  }
`;

const ADD_NOTE = gql`
  mutation AddNote($_id: ID!, $text: String!) {
    addNoteProcedureRequest(_id: $_id, text: $text) {
      notes {
        dateSubmitted
        text
        submittedBy {
          firstName
          lastName
        }
      }
    }
  }
`;

const ASSIGN = gql`
  mutation Assign($_id: ID!, $PVI: String!, $primary: Boolean) {
    assignProcedureRequest(_id: $_id, PVI: $PVI, primary: $primary) {
      _id
    }
  }
`;

const UNASSIGN_SECONDARY = gql`
  mutation Unassign($_id: ID!, $PVI: String!) {
    unassignSecondaryProcedureRequest(_id: $_id, PVI: $PVI) {
      _id
    }
  }
`;

const ADD_EMAIL = gql`
  mutation AddEmail($_id: ID!, $PVI: String, $emailAddress: String!) {
    addEmailProcedureRequest(_id: $_id, PVI: $PVI, emailAddress: $emailAddress) {
      _id
    }
  }
`;

const REMOVE_EMAIL = gql`
  mutation RemoveEmail($_id: ID!, $emailAddress: String!) {
    removeEmailProcedureRequest(_id: $_id, emailAddress: $emailAddress) {
      _id
    }
  }
`;

export default function ProcedureRequest({ user, number: numberProp }) {
  const params = useParams();
  const [toast, setToast] = useState();
  const [errorDialog, setErrorDialog] = useState();
  const [loading, setLoading] = useState(false);
  const [lastAction, setLastAction] = useState();
  const [lastActionVerb, setLastActionVerb] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [updating, setUpdating] = useState(false);
  const [reviewAcknowledged, setReviewAcknowledged] = useState(false);
  const [reviewing, setReviewing] = useState(false);
  const [key, setKey] = useState(false);
  const [userText, setUserText] = useState();
  const [secondaryUserText, setSecondaryUserText] = useState();

  const { number: numberParam } = params;
  const getNumber = () => {
    if (numberProp) {
      if (typeof parseInt(numberProp) === 'number') {
        return parseInt(numberProp);
      }
      console.error('invalid number passed as prop to ProcedureRequest');
    }
    if (numberParam) {
      if (typeof parseInt(numberParam) === 'number') {
        return parseInt(numberParam);
      }
      console.error('invalid number passed as param to ProcedureRequest');
    }
    throw new Error('No request order number passed to ProcedureRequest component');
  };

  const {
    loading: requestLoading,
    error,
    data,
    refetch: _refetch,
  } = useQuery(GET_PROCEDURE_REQUEST, {
    variables: {
      number: getNumber(),
    },
  });

  const refetch = () => {
    setKey(!key);
    _refetch();
  };

  // Checks to see if this is an error that can be handled gracefully
  // If so alerts the user and returns true, indicating that the error was handled and
  // an error does not need to be sent to the server/ticketing system

  const [createError] = useMutation(CREATE_ERROR);
  const [takeAction] = useMutation(TAKE_ACTION, {
    onError(e) {
      refetch();
      console.log('e: ', e);
      setLoading(false);
      setToast(false);
      setErrorDialog(true);
      createError({
        variables: {
          PVI: user.PVI,
          action: lastAction,
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            data,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setToast(`Request ${lastActionVerb}!`);
        setLoading(false);
        refetch();
      } else setToast(false);
    },
  });

  const [addNote] = useMutation(ADD_NOTE, {
    onError(e) {
      refetch();
      console.log('e: ', e);
      setLoading(false);
      setToast(false);
      setErrorDialog(true);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'addNoteProcedureREquest',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            data,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setToast('Message Added!');
        setLoading(false);
        refetch();
      } else setToast(false);
    },
  });

  const [assign] = useMutation(ASSIGN, {
    onError(e) {
      refetch();
      console.log('e: ', e);
      setLoading(false);
      setToast(false);
      setErrorDialog(true);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'assignProcedureRequest',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            userText,
            secondaryUserText,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setToast('Procedure Assigned!');
        setLoading(false);
        refetch();
      } else setToast(false);
    },
  });

  const [unassignSecondary] = useMutation(UNASSIGN_SECONDARY, {
    onError(e) {
      refetch();
      console.log('e: ', e);
      setLoading(false);
      setToast(false);
      setErrorDialog(true);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'unassignSecondaryProcedureRequest',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setToast('Secondary Assignee Removed!');
        setLoading(false);
        refetch();
      } else setToast(false);
    },
  });

  const [addEmail] = useMutation(ADD_EMAIL, {
    onError(e) {
      refetch();
      console.log('e: ', e);
      setLoading(false);
      setToast(false);
      setErrorDialog(true);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'addEmailProcedureRequest',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setToast('Email Added!');
        setLoading(false);
        refetch();
      } else setToast(false);
    },
  });

  const [removeEmail] = useMutation(REMOVE_EMAIL, {
    onError(e) {
      refetch();
      console.log('e: ', e);
      setLoading(false);
      setToast(false);
      setErrorDialog(true);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'removeEmailProcedureRequest',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setToast('Email removed!');
        setLoading(false);
        refetch();
      } else setToast(false);
    },
  });

  if (requestLoading) {
    return (
      <StandardWrapper>
        <LinearProgress />
      </StandardWrapper>
    );
  }

  if (error || (!loading && !data)) {
    console.log('error: ', error);

    return (
      <StandardWrapper>
        <Typography variant="h4">Could not display procedure request.</Typography>
      </StandardWrapper>
    );
  }

  console.log('data: ', data);
  const { procedureRequestByNumber: procedureRequest } = data;
  if (!procedureRequest) {
    return (
      <StandardWrapper>
        <Typography variant="h4">Could not procedure request.</Typography>
      </StandardWrapper>
    );
  }

  const {
    _id,
    protocolNumber,
    number,
    building,
    animalIds,
    terminal,
    additionalDetails,
    PIName,
    status,
    dateUpdated,
    dateRequested,
    servicesRequested,
    bsl,
    biohazardAgent,
    numMales,
    numFemales,
    numNoSex,
    contactName,
    contactEmail,
    contactPhone,
    billingString,
    room,
    species,
    dateStarted,
    dateApproved,
    notes,
    assignedTo,
    emailList,
    hazardExposure,
    secondaryAssignees,
  } = procedureRequest;

  const validateAction = (action) => {
    if (!action || typeof action !== 'string') {
      throw new Error('Invalid action format passed to validateAction');
    }
    switch (action) {
      case 'approve':
        return status === 'Under Review';
      case 'start':
        return status === 'Approved';
      case 'complete':
        return status === 'Started';
      case 'cancel':
        return !['Completed', 'Cancelled'].includes(status);
      case 'update':
        return ['Under Review', 'Approved'].includes(status);
      case 'assign':
        return !['Completed', 'Cancelled'].includes(status);
      default:
        throw new Error('Invalid action passed to validateAction');
    }
  };

  const handleApprove = () => {
    if (!validateAction('approve')) {
      throw new Error("Can't perform action");
    }
    setLoading(true);
    setLastAction('approve');
    setLastActionVerb('approved');
    takeAction({ variables: { _id, action: 'approve' } });
  };

  const handleStart = () => {
    if (!validateAction('start')) {
      throw new Error("Can't perform action");
    }
    setLoading(true);
    setLastAction('start');
    setLastActionVerb('started');
    takeAction({ variables: { _id, action: 'start' } });
  };

  const handleComplete = () => {
    if (!validateAction('complete')) {
      throw new Error("Can't perform action");
    }
    setLoading(true);
    setLastAction('complete');
    setLastActionVerb('completed');
    takeAction({ variables: { _id, action: 'complete' } });
  };

  const handleCancel = () => {
    if (!validateAction('cancel')) {
      throw new Error("Can't perform action");
    }
    setLoading(true);
    setLastAction('cancel');
    setLastActionVerb('cancelled');
    takeAction({ variables: { _id, action: 'cancel' } });
  };

  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
    setErrorDialog(false);
    setErrorMessage();
  };

  const handleReviewedSubmit = () => {
    if (reviewAcknowledged) {
      setLoading(true);
      setReviewing(false);
    }
  };

  const completeColor = '#488948';
  const cancelColor = '#dadfe1';
  const inProgressColor = '#006a8a';

  const getColor = () => {
    if (status === 'Completed') {
      return completeColor;
    }
    if (status === 'Cancelled') {
      return cancelColor;
    }
    return inProgressColor;
  };

  if (updating) {
    return (
      // TODO: Replace when procedure action mutator is made available
      <UpdateProcedureRequest
        procedureRequest={procedureRequest}
        onSubmit={() => {
          setUpdating(false);
          refetch();
        }}
        onClose={() => {
          setUpdating(false);
        }}
        user={user}
      />
    );
  }

  const getActiveStep = () => {
    switch (status) {
      case 'Under Review':
        return 1;
      case 'Approved':
        return 2;
      case 'Started':
        return 3;
      case 'Completed':
        return 4;
      case 'Cancelled':
        return 5;
      default:
        return 'Unknown stepIndex';
    }
  };

  const getSteps = (x) => {
    switch (x) {
      case 'Under Review':
        return ['Request Submitted', 'Request Review', 'Procedure Start', 'Procedure Complete'];
      case 'Approved':
        return ['Request Submitted', 'Approved', 'Procedure Start', 'Procedure Complete'];
      case 'Started':
        return ['Request Submitted', 'Approved', 'Procedure Started', 'Procedure Complete'];
      case 'Completed':
        return ['Request Submitted', 'Approved', 'Procedure Started', 'Procedure Completed'];
      case 'Cancelled':
        // If cancelled display the steps as it's last known progress state and replace completion with cancellation
        const progress = getProgressBeforeCancellation();
        console.log('progress: ', progress);

        const steps = getSteps(progress);
        console.log('steps modified: ', steps);

        return [...steps, 'Cancelled'];
      default:
        return 'Unknown stepIndex';
    }
  };

  const getSkippedSteps = () => {
    if (status !== 'Cancelled') return [];
    switch (getProgressBeforeCancellation()) {
      case 'Under Review':
        return [1, 2, 3];
      case 'Approved':
        return [2, 3];
      case 'Started':
        return [3];
      default:
        return [];
    }
  };

  const getProgressBeforeCancellation = () => {
    console.log('dateStarted: ', dateStarted);

    if (dateStarted) return 'Started';
    if (dateApproved) return 'Approved';
    return 'Under Review';
  };

  const handleSelectUser = (user, primary = true) => {
    const { PVI } = user;
    assign({ variables: { _id, PVI, primary } });
  };

  const messages = [...notes];
  messages.sort((x, y) => {
    const xUnix = moment(x.dateSubmitted).unix();
    const yUnix = moment(y.dateSubmitted).unix();
    if (xUnix < yUnix) {
      return 1;
    }
    if (xUnix > yUnix) {
      return -1;
    }
    return 0;
  });

  const servicePermissions = ['admin', 'rs'].some((group) => user.groups.includes(group));

  return (
    <StandardWrapper>
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconButton
            aria-label="back to procedure request table"
            onClick={() => {
              window.location.href = '/procedure-requests';
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>
        </Grid>

        {validateAction('update') && servicePermissions && (
          <Grid item>
            <IconButton
              aria-label="edit procedure request"
              onClick={() => {
                setUpdating(true);
              }}
            >
              <Edit />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <div style={{ marginBottom: 30 }}>
        <Typography variant="h4">Request&nbsp;#{number}</Typography>
        <br />
        <div
          style={{
            padding: 10,
            width: 175,
            margin: '15px auto',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: getColor(),
            borderRadius: 10,
          }}
        >
          <Typography variant="h6">&nbsp;{capitalize(status)}</Typography>
        </div>
        <MuiThemeProvider
          theme={createTheme({
            palette: {
              primary: {
                main: getColor(),
              },
            },
          })}
        >
          <Hidden smUp>
            <Stepper activeStep={getActiveStep()} orientation={'vertical'}>
              {getSteps(status).map((step, i) =>
                status === 'Cancelled' ? (
                  <Step key={step} completed={!getSkippedSteps().includes(i)}>
                    <StepLabel>{step}</StepLabel>
                  </Step>
                ) : (
                  <Step key={step}>
                    <StepLabel>{step}</StepLabel>
                  </Step>
                )
              )}
            </Stepper>
          </Hidden>
          <Hidden xsDown>
            <Stepper activeStep={getActiveStep()}>
              {getSteps(status).map((step, i) =>
                status === 'Cancelled' ? (
                  <Step key={step} completed={!getSkippedSteps().includes(i)}>
                    <StepLabel>{step}</StepLabel>
                  </Step>
                ) : (
                  <Step key={step}>
                    <StepLabel>{step}</StepLabel>
                  </Step>
                )
              )}
            </Stepper>
          </Hidden>
        </MuiThemeProvider>
        {/* Actions container  */}
        {servicePermissions && (
          <Grid container justifyContent="space-around" style={{ marginTop: 20, marginBottom: 40 }}>
            <Hidden xsDown>
              {validateAction('cancel') && (
                <Grid item sm={4}>
                  <Button
                    style={{ borderWidth: 2 }}
                    variant="outlined"
                    // color="primary"
                    onClick={handleCancel}
                    disabled={loading}
                  >
                    Cancel Procedure
                  </Button>
                </Grid>
              )}
              {validateAction('approve') && (
                <Grid item sm={4}>
                  <Button
                    style={{ borderWidth: 2, borderColor: inProgressColor }}
                    variant="outlined"
                    // color="primary"
                    onClick={handleApprove}
                    disabled={loading}
                  >
                    Approve Procedure
                  </Button>
                </Grid>
              )}

              {validateAction('start') && (
                <Grid item sm={4}>
                  <Button
                    style={{ borderWidth: 2, borderColor: inProgressColor }}
                    variant="outlined"
                    // color="primary"
                    onClick={handleStart}
                    disabled={loading}
                  >
                    Start Procedure
                  </Button>
                </Grid>
              )}

              {validateAction('complete') && (
                <Grid item sm={4}>
                  <Button
                    style={{ borderWidth: 2, borderColor: completeColor }}
                    variant="outlined"
                    // color="primary"
                    onClick={handleComplete}
                    disabled={loading}
                  >
                    Complete Procedure
                  </Button>
                </Grid>
              )}
            </Hidden>
            <Hidden smUp>
              {validateAction('approve') && (
                <Grid item xs={12}>
                  <Button
                    style={{
                      width: '100%',
                      margin: '10px 0',
                      height: 50,
                      borderWidth: 2,
                      borderColor: inProgressColor,
                    }}
                    variant="outlined"
                    // color="primary"
                    onClick={handleApprove}
                    disabled={loading}
                  >
                    Approve Procedure
                  </Button>
                </Grid>
              )}

              {validateAction('start') && (
                <Grid item xs={12}>
                  <Button
                    style={{
                      width: '100%',
                      margin: '10px 0',
                      height: 50,
                      borderWidth: 2,
                      borderColor: inProgressColor,
                    }}
                    variant="outlined"
                    // color="primary"
                    onClick={handleStart}
                    disabled={loading}
                  >
                    Start Procedure
                  </Button>
                </Grid>
              )}

              {validateAction('complete') && (
                <Grid item xs={12}>
                  <Button
                    style={{ width: '100%', margin: '10px 0', height: 50, borderWidth: 2, borderColor: completeColor }}
                    variant="outlined"
                    // color="primary"
                    onClick={handleComplete}
                    disabled={loading}
                  >
                    Complete Procedure
                  </Button>
                </Grid>
              )}
              {validateAction('cancel') && (
                <Grid item xs={12}>
                  <Button
                    style={{ width: '100%', margin: '10px 0', height: 50, borderWidth: 2 }}
                    variant="outlined"
                    // color="primary"
                    onClick={handleCancel}
                    disabled={loading}
                  >
                    Cancel Procedure
                  </Button>
                </Grid>
              )}
            </Hidden>
          </Grid>
        )}
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Project Lead:</span>&nbsp;{getName(assignedTo)}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>PI:</span>&nbsp;{PIName}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Protocol Number:</span>&nbsp;{protocolNumber}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Requested Service Date:</span>&nbsp;
          {moment(dateRequested).format('M/D/YYYY h:mm a')}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Contact Name:</span>&nbsp;{contactName}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Contact Email:</span>&nbsp;{contactEmail}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Contact Phone:</span>&nbsp;{contactPhone}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Billing String:</span>&nbsp;{billingString}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Building:</span>&nbsp;{buildingCodeToName(building) || building}
        </Typography>
        {room && (
          <Typography style={{ textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>Room:</span>&nbsp;{room}
          </Typography>
        )}
      </div>
      <div style={{ marginTop: 30 }}>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Services Requested:</span>&nbsp;{servicesRequested.join(', ')}
        </Typography>
      </div>
      <div style={{ marginTop: 30 }}>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Species:</span>&nbsp;{species}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Animal ID(s):</span>&nbsp;{animalIds}
        </Typography>

        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Terminal:</span>&nbsp;{terminal ? 'Yes' : 'No'}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Number of Males:</span>&nbsp;{numMales}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Number of Females:</span>&nbsp;{numFemales}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Number with No Sex Preference:</span>&nbsp;{numNoSex}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Exposed to Hazardous Substances:</span>&nbsp;
          {hazardExposure ? 'Yes' : 'No'}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Biosafety Level:</span>&nbsp;{bsl}
        </Typography>
        {biohazardAgent && (
          <Typography style={{ textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>Hazardous Agent(s):</span>&nbsp;{biohazardAgent}
          </Typography>
        )}
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Additional Details:</span>&nbsp;{additionalDetails}
        </Typography>
      </div>

      {/* Project Lead Assignment */}
      {servicePermissions && (
        <Grid container justifyContent="space-between" style={{ marginTop: 30 }}>
          {validateAction('assign') && (
            <Hidden smUp>
              <Grid item xs={12}>
                <UserSearch
                  sendInput={(name, value) => setUserText(value)}
                  name="userText"
                  val={userText}
                  defaultValue={assignedTo}
                  key={key}
                  onSelectUser={handleSelectUser}
                  required={false}
                  label="Project Lead"
                  filter={(x) => {
                    return Array.isArray(x.groups) && ['rs'].some((group) => x.groups.includes(group));
                  }}
                  showPVI={false}
                  style={{ marginLeft: 0, width: '100%' }}
                />
              </Grid>
            </Hidden>
          )}
          {validateAction('assign') && (
            <Hidden xsDown>
              <Grid item>
                <UserSearch
                  sendInput={(name, value) => setUserText(value)}
                  name="userText"
                  val={userText}
                  defaultValue={assignedTo}
                  key={key}
                  onSelectUser={handleSelectUser}
                  required={false}
                  label="Project Lead"
                  filter={(x) => {
                    return Array.isArray(x.groups) && ['rs'].some((group) => x.groups.includes(group));
                  }}
                  showPVI={false}
                  style={{ marginLeft: 0 }}
                />
              </Grid>
            </Hidden>
          )}
        </Grid>
      )}

      {/* Secondary Assignment */}
      {servicePermissions && (
        <Grid container justifyContent="space-between">
          {validateAction('assign') && (
            <Hidden smUp>
              <Grid item xs={12}>
                <UserSearch
                  sendInput={(name, value) => setSecondaryUserText(value)}
                  name="secondaryUserText"
                  val={secondaryUserText}
                  key={key}
                  onSelectUser={(user) => handleSelectUser(user, false)}
                  required={false}
                  label="Add Secondary Assignee"
                  filter={(x) => {
                    return Array.isArray(x.groups) && ['rs'].some((group) => x.groups.includes(group));
                  }}
                  showPVI={false}
                  style={{ marginLeft: 0, width: '100%' }}
                />
              </Grid>
            </Hidden>
          )}
          {validateAction('assign') && (
            <Hidden xsDown>
              <Grid item>
                <UserSearch
                  sendInput={(name, value) => setUserText(value)}
                  name="secondaryUserText"
                  val={secondaryUserText}
                  key={key}
                  onSelectUser={(user) => handleSelectUser(user, false)}
                  required={false}
                  label="Add Secondary Assignee"
                  filter={(x) => {
                    return Array.isArray(x.groups) && ['rs'].some((group) => x.groups.includes(group));
                  }}
                  showPVI={false}
                  style={{ marginLeft: 0 }}
                />
              </Grid>
            </Hidden>
          )}

          <Grid item>
            <Typography variant="h6">Current Secondary Assignees</Typography>
            <Container>
              {secondaryAssignees.map((assignee, i) => {
                return (
                  <li key={i + 'secondaryAssignee'} style={{ listStyle: 'none' }}>
                    <Chip
                      label={getName(assignee)}
                      onDelete={() => {
                        unassignSecondary({ variables: { _id, PVI: assignee.PVI } });
                      }}
                      // className={classes.chip}
                    />
                  </li>
                );
              })}
            </Container>
          </Grid>
        </Grid>
      )}
      <EmailList
        title="Procedure Email List"
        options={emailList}
        canAddUser={servicePermissions}
        canDelete={servicePermissions}
        onSubmit={({ emailAddress, PVI }) => addEmail({ variables: { _id, emailAddress, PVI } })}
        onDelete={(emailAddress) => removeEmail({ variables: { _id, emailAddress } })}
      />
      <Messenger
        label="Add a Message"
        placeholder="Add a message to this procedure. Users in the procedure email list above will receive an email notification with your message."
        messages={messages}
        title="Messages & Activity"
        onSubmit={(text) => addNote({ variables: { _id, text } })}
        validate={() => !loading}
      />
      <Dialog
        open={reviewing}
        onClose={() => {
          setReviewing(false);
        }}
      >
        <DialogTitle>Please Review Updated Request Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This request has been edited after it was originally submitted. Please review the details of the request
            carefully to ensure you are following the most recent instructions.
          </DialogContentText>
          <div>
            <FormControlLabel
              className="checkbox"
              control={
                <Checkbox
                  key={key}
                  checked={reviewAcknowledged}
                  onChange={(e) => {
                    setReviewAcknowledged(e.target.checked);
                  }}
                ></Checkbox>
              }
              label="I acknowledge that I have thuroughly reviewed the new details of this request."
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Button
              onClick={() => {
                setReviewing(false);
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleReviewedSubmit();
              }}
              style={reviewAcknowledged ? { borderColor: inProgressColor, borderWidth: 2 } : {}}
              variant="outlined"
              disabled={!reviewAcknowledged}
            >
              Submit
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>

      <Snackbar open={toast} autoHideDuration={6000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          {toast}
        </Alert>
      </Snackbar>
      <Dialog open={errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Failed to update procedure request. BRMS IT has been alerted of the issue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={errorMessage}
        onClose={() => {
          handleToastClose();
          refetch();
        }}
      >
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleToastClose();
              refetch();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </StandardWrapper>
  );
}

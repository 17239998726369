import React, { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import { InputAdornment, CircularProgress, TextField } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import { createFilterOptions, Autocomplete } from '@material-ui/lab';
import { isAuthError } from '../../utils';

const loadingProps = {
    startAdornment: (
        <InputAdornment position="start">
            <CircularProgress />
        </InputAdornment>
    ),
};

const errorProps = {
    startAdornment: (
        <InputAdornment position="start">
            <Error />
        </InputAdornment>
    ),
};

const GET_USERS = gql`{
    users{
        PVI
        firstName
        lastName
        emailAddress
        groups
    }
}`;





const UserSearch = props => {
    const { loadingUsers: _loading, error: _error, data: _data, refetch: _refetch } = useQuery(GET_USERS);
    const {
        key,
        name,
        val,
        sendInput,
        onSelectUser,
        loading = _loading,
        error = _error,
        data = _data,
        refetch = _refetch,
        required = true,
        label = "User",
        filter = () => true,
        showPVI = true,
        defaultValue,
        style = {},
    } = props
    console.log("data: ", data)


    if ((!loading && !data) || error) {
        if (isAuthError(error)) {
            window.location.href = '/login';
        }
        console.error('Error fetching users in UserSearch.');
        console.error(error);
    }

    const handleSelect = (e, value) => {
        console.log("value: ", value)

        if (value === null) return;
        sendInput(name, value.PVI);
        onSelectUser(value);
    }

    const [initialized, setInitialized] = useState(false);
    const [showOptions, setShowOptions] = useState(false)
    useEffect(() => {
        // refetch from server on render. Prevent UI bugs where cached results do not reflect recent changes
        if (!initialized) {
            refetch();
            setInitialized(true);
        }
    })


    const filterOptions = createFilterOptions({
        limit: showOptions ? 5 : 0,
    });

    return (
        <Autocomplete
            key={key + data}
            clearOnEscape={true}
            filterOptions={filterOptions}
            options={data && data.users ? data.users.filter(filter) : []}
            getOptionLabel={option => showPVI ? option.PVI + ": " + option.firstName + ' ' + option.lastName : option.firstName + ' ' + option.lastName}
            style={{ width: 300, display: "inline-block", margin: 10, ...style }}
            onChange={handleSelect}
            onInputChange={(e, value) => sendInput(name, value)}
            noOptionsText={val ? "No matches" : "Type to search"}
            value={defaultValue}
            renderInput={params => data ? (
                <TextField
                    {...params}
                    required={required}
                    label={label}
                    variant={"outlined"}
                    placeholder="Enter user PVI / name"
                    value={val}
                    onKeyDown={(e) => {
                        if (!showOptions) {
                            setShowOptions(true)
                        }
                        if (e.keyCode === 13) // Don't submit form on enter
                            e.preventDefault();
                    }}
                />
            ) : (
                <TextField
                    {...params}
                    label="User"
                    variant={"outlined"}
                    placeholder="Enter user PVI / name"
                    value={val}
                    InputProps={loading ? loadingProps : errorProps}
                    onKeyDown={(e) => {
                        if (!showOptions) {
                            setShowOptions(true)
                        }
                        if (e.keyCode === 13)
                            e.preventDefault();
                    }}
                />
            )}
        />
    );
}

export default UserSearch;
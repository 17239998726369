import React, { useState, useEffect } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CancelReservationForm from './CancelReservationForm';
import { RRule, RRuleSet } from 'rrule';
import _ from 'lodash';
import {
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  Box,
  Stack,
  FormControl,
  FormControlLabel,
  InputLabel,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  RadioGroup,
  Radio,
  OutlinedInput,
  FormHelperText,
  Dialog,
  CircularProgress,
  Grid,
} from '@mui/material/';

import { DateTimePicker, DatePicker } from '@mui/x-date-pickers';

import moment from 'moment';

import { validateRRule, parseRRuleString, getEODMoment, getMaxDate, formatEventForLog } from './utils';

export default function ResourceReservationForm(props) {
  const {
    setOpen,
    editingAppointment,
    setEditingAppointment,
    handleFormClose,
    schedulerCategories,
    activeReservations,
    addReservation,
    isCancelling,
    setIsCancelling,
    updateReservation,
    cancelReservation,
    updateOccurrence,

    submissionLoading,
    setSubmissionLoading,
  } = props;

  // STATE

  const [monthSelection, setMonthSelection] = useState('date');
  const [rRule, setRRule] = useState(editingAppointment.rRule || {});
  const [titleError, setTitleError] = useState('');
  const [startError, setStartError] = useState('');
  const [endError, setEndError] = useState('');
  const [untilError, setUntilError] = useState('');
  const [formError, setFormError] = useState('');
  // If the apt has an ID it exists already and we should treat it as an edit
  const [isUpdating, setIsUpdating] = useState(!!editingAppointment.id);
  const [initialized, setInitialized] = useState(false);

  const [titleInput, setTitleInput] = useState(editingAppointment.title || '');
  const [scheduleWarningAccepted, setScheduleWarningAccepted] = useState(false);
  const [originalStart, setOriginalStart] = useState(moment(editingAppointment.startDate));
  const maxDate = getMaxDate();
  const isSeries = rRule && !_.isEmpty(rRule);
  const [originalEventWasSeries] = useState(isSeries);
  const [updatingOccurrence, setUpdatingOccurrence] = useState(isSeries && !!editingAppointment.id);
  const [startDateInput, setStartDateInput] = useState();
  const [endDateInput, setEndDateInput] = useState();

  // HANDLERS

  const updateFormTitle = (e) => {
    setEditingAppointment({ ...editingAppointment, title: e.target.value });
  };

  const handleUpdateRRuleStart = (_start) => {
    const start = moment(_start);
    // if (start.isValid()) {
    // if (isUpdating && !updatingOccurrence && editingAppointment.exDate && rRule && rRule.dtstart && moment(rRule.dtstart).isValid()) {
    //   const changeInMilliseconds = moment.duration(moment(rRule.dtstart).diff(start)).asMilliseconds()
    //   let exDate = ""
    //   editingAppointment.exDate.split(',').forEach(x => {
    //     if (exDate.length > 0) {
    //       exDate += ','
    //     }
    //     exDate += moment(x).add(changeInMilliseconds, 'ms').format()
    //   })
    //   setEditingAppointment(current => ({ ...current, exDate }))
    // }
    setRRule({ ...rRule, dtstart: new Date(start.utc()) });
  };

  function handleStartDateAccept(e) {
    // ! resets state after date, cancels time
    setEditingAppointment({ ...editingAppointment, startDate: e._d });
    if (isSeries) {
      handleUpdateRRuleStart(e._d);
    }
  }

  function handleUntilAccept(e) {
    // Make sure the until date accounts for whole day and doesn't start at midnight
    let until = moment(e._d);
    if (until.isValid()) {
      until = getEODMoment(until);
    }
    setRRule({ ...rRule, until: new Date(until.utc()) });
  }

  const setReservationResource = (e) => {
    setEditingAppointment({ ...editingAppointment, resource: e.target.value });
  };

  const setReservationProtocol = (e) => {
    setEditingAppointment({ ...editingAppointment, protocol: e.target.value });
  };

  // HELPERS

  const expandRecurringEvent = (event, betweenStart, betweenEnd) => {
    // TODO: take extra steps to prevent infinite expansion
    if (!event.startDate || !event.endDate) {
      throw new Error('Must have start and end date to project recurrence');
    }

    const start = moment(event.startDate);
    const end = moment(event.endDate);
    var duration = moment.duration(end.diff(start)).asHours();

    if (!start.isValid() || !end.isValid()) {
      throw new Error('Must have valid start and end date to project recurrence');
    }
    if (!validateRRule(event.rRule)) {
      throw new Error('Must have valid rRule to project recurrence');
    }

    // Use a set instead of a rule to exclude exdates from expansion

    const set = new RRuleSet();
    set.rrule(new RRule(event.rRule));
    if (typeof event.exDate === 'string') {
      event.exDate.split(',').forEach((x) => {
        set.exdate(new Date(moment(x).utc()));
      });
    }
    let events = [event];

    if (betweenStart && betweenEnd) {
      // If range is requested only process that range
      if (moment(betweenStart).isValid() && moment(betweenEnd).isValid()) {
        events = set.between(new Date(betweenStart.utc()), new Date(betweenEnd.utc()));
      } else {
        console.error('Invalid range provided to expandRecurringEvent, returning results outside of range');
        events = set.all();
      }
    } else {
      // If no range is provided provide all occurrences
      events = set.all();
    }

    return events.map((x) => {
      return {
        startDate: moment(x),
        endDate: moment(x).add(duration, 'hours'),
        id: event.id,
        resource: event.resource,
        rRule: event.rRule,
      };
    });
  };

  const singleEventsOverlap = (a, b, allowSelfOverlap = true, allowSeriesOverlap = false) => {
    if (!a.startDate || !a.endDate || !moment(a.startDate).isValid() || !moment(a.endDate).isValid()) {
      throw new Error('Could not evaluate. Event A does not have a valid start and end date in singleEventsOverlap');
    }
    if (!b.startDate || !b.endDate || !moment(b.startDate).isValid() || !moment(b.endDate).isValid()) {
      throw new Error('Could not evaluate. Event B does not have a valid start and end date in singleEventsOverlap');
    }
    if (allowSelfOverlap) {
      // make sure A and B are not the same event (relevant with updates)
      if (a.id && b.id && a.id === b.id) {
        return false;
      }
    }

    if (allowSeriesOverlap) {
      if ((a.id && a.id === b.seriedId) || (b.id && b.id === a.seriedId)) {
        return false;
      }
    }
    // An existing event that is being checked, call it event B
    const aStart = moment(a.startDate).unix();
    const aEnd = moment(a.endDate).unix();
    const bStart = moment(b.startDate).unix();
    const bEnd = moment(b.endDate).unix();

    if (aStart === bStart) {
      // if they start at the same time it's an overlap
      // otherwise if the event that starts first ends after the next begins it's an overlap
      return true;
    } else if (aStart < bStart) {
      // if A starts before B it overlaps if it ends after B starts
      if (aEnd > bStart) {
        return true;
      }
    } else {
      // if A starts after B it overlaps if B ends after A starts
      if (bEnd > aStart) {
        return true;
      }
    }
    return false;
  };

  const getFirstOverlappingEvent = (event) => {
    // TODO: make sure id is available for event if being edited
    // bad input means we cant evaluate so assume no overlap

    if (!event.resource) return false;

    const start = moment(event.startDate);
    if (!event.startDate || !start.isValid()) {
      return false;
    }
    const end = moment(event.endDate);
    if (!event.endDate || !end.isValid()) {
      return false;
    }

    // Calling event passed to this function event A
    let aEvents = [];
    // Expand event in question if it is a series
    // Updated single occurrence will not repeat, rRule will be stripped on submission, do not project
    if (validateRRule(event.rRule) && !(isUpdating && updatingOccurrence)) {
      aEvents = expandRecurringEvent(event);
    } else {
      aEvents = [event];
    }

    // Only expand existing events to the end of the last event in question or max date
    const expandUntil = aEvents.length > 0 ? moment(aEvents[aEvents.length - 1]) : moment(maxDate);



    // Expand all series to get all possible reservation instances to check for overlap
    // Filter to specific resource
    let unexpandedEvents = formatActiveReservations().filter((x) => x.resource === event.resource);
    if (!(isUpdating && updatingOccurrence)) {
      unexpandedEvents = unexpandedEvents.filter((x) => x.id !== event.id);
    }

    let existingEvents = [];
    unexpandedEvents.forEach((_x) => {
      const x = { ..._x };
      if (_x.rRule) {
        if (x.id === event.id && isUpdating && updatingOccurrence) {
          // Updating a single event could overlap with other events in it's own series but shouldn't
          // be considered invalid if it overlaps with itself. Exclude it from the projection
          x.exDate = x.exDate ? originalStart.format() : `${x.exDate},${originalStart.format()}`;
        }
        existingEvents = existingEvents.concat(expandRecurringEvent(x, moment(), expandUntil));
      } else {
        existingEvents.push(x);
      }
    });

    let detectedOverlap;

    // Must flag comare function to flag edited single occurrence intersecting with other events in it's series
    // Edited occurrence is omitted from expansion
    const allowSelfOverlap = !(isUpdating && isSeries && updatingOccurrence);
    const allowSeriesOverlap = isUpdating && isSeries && !updatingOccurrence;
    aEvents.forEach((a) => {
      if (detectedOverlap) return;
      existingEvents.forEach((b) => {
        if (detectedOverlap) return;
        if (singleEventsOverlap(a, b, allowSelfOverlap, allowSeriesOverlap)) {
          // console.log("---- Overlap Detected ----")
          // console.log('Proposed event', formatEventForLog(a))
          // console.log('Overlapping event', formatEventForLog(b))
          console.log("overlap: ", b)

          detectedOverlap = { ...b };
        }
      });
    });

    return detectedOverlap;
  };

  const formatActiveReservations = () => {
    return activeReservations.map((x) => {
      if (x.rRule && typeof x.rRule === 'string') {
        try {
          const parsedRule = parseRRuleString(x.rRule);
          return {
            ...x,
            rRule: parsedRule,
          };
        } catch (e) {
          console.log('e: ', e);
          console.error('Invalid rRule in event: ', x);
        }
      }

      return { ...x };
    });
  };

  formatActiveReservations();

  const clearFieldErrors = () => {
    setTitleError('');
    setStartError('');
    setEndError('');
    setUntilError('');
    setFormError('');
  };

  const validateTitle = (title) => {
    setTitleError('');
    if (typeof title !== 'string' || title.length < 3) {
      setTitleError('Title must be at least three characters');
      return false;
    }
    return true;
  };

  const validateStart = (start) => {
    setStartError('');
    if (!start || !moment(start).isValid()) {
      setStartError('Invalid date');
      return false;
    }
    // if (moment(start).unix() < moment().unix()) {
    //   setStartError('Start cannot be in the past');
    //   return false;
    // }
    return true;
  };

  const validateEnd = (_start, _end) => {
    setEndError('');

    const start = moment(_start);
    const end = moment(_end);
    if (!_end || !moment(end).isValid()) {
      setEndError('Invalid date');
      return false;
    }
    if (!_start || !start.isValid()) {
      // Can't evaluate without a start date return false because not a valid range
      // Allow error text to be displayed by validateStart function, not here
      return false;
    }
    if (start.unix() >= end.unix()) {
      setEndError('Reservation cannot end before it starts');
      return false;
    }
    if (end.unix() > moment(maxDate).unix()) {
      setEndError(`Cannot book beyond ${moment(maxDate).format('M/D/YYYY')}`);
      return false;
    }
    return true;
  };

  const validateRepition = (_rRule, end) => {
    setUntilError('');
    const until = moment(_rRule.until);
    if (!_rRule.until || !until.isValid()) {
      setUntilError('Invalid date');
      return false;
    }

    if (_rRule.freq === RRule.WEEKLY && !(Array.isArray(_rRule.byweekday) && _rRule.byweekday.length > 0)) {
      setFormError('Must select at least 1 day of the week to repeat on');
      return false;
    }
    if (until.unix() < moment(end).unix()) {
      setUntilError('Repetition end date must be on or after reservation end date');
      return false;
    }
    if (until.unix() > moment(maxDate).unix()) {
      setUntilError(`Cannot book beyond ${moment(maxDate).format('M/D/YYYY')}`);
      return false;
    }
    return true;
  };

  const validate = (_start = editingAppointment.startDate, _end = editingAppointment.endDate, _rRule = rRule) => {
    clearFieldErrors();
    let isValid = true;
    const { resource, id, title } = editingAppointment;
    const start = moment(_start);

    const end = moment(_end);

    if (!validateTitle(title)) {
      isValid = false;
    }

    // Check if start and end are valid
    if (!validateStart(start)) {
      isValid = false;
    }

    if (!validateEnd(start, end)) {
      isValid = false;
    }

    if (_rRule && _rRule.until) {
      if (!validateRepition(_rRule, end)) {
        isValid = false;
      }
    }

    // Only perform the following checks if the rest of the form is valid
    if (isValid) {
      const expansionEvent = {
        startDate: start,
        endDate: end,
        resource,
        id,
        rRule: _rRule,
        exDate: editingAppointment.exDate,
      };


      if (moment(end).isAfter(moment(start).add(12, 'h'))) {
        setEndError('Reservation exceeds 12 hours');
        isValid = false;
      }

      const overlap = getFirstOverlappingEvent(expansionEvent);
      if (overlap) {
        setFormError(
          `This reservation overlaps with existing event on ${moment(overlap.startDate).format('M/D/YYYY')}`
        );
        isValid = false;
      }
      // Warn user if time is outside of normal light hours
      if ((start.hour() < 6 || start.hour() > 18) && scheduleWarningAccepted) {
        alert(
          `WARNING:\nPlease remember that animal housing rooms throughout the program are on specific light/dark cycles.  
          Personnel working in rooms during dark cycles may have a negative impact on experiments.  
          This fact is especially important for shared housing rooms with colonies from different investigators.`
        );
        isValid = false; // it might actually be valid but this prevents a submission from going through after accepting the prompt
        setScheduleWarningAccepted(true); // only show once
      }
    }

    return isValid;
  };

  // Recurrence Handlers
  const handleRepeatSwitch = () => {
    setFormError('');
    if (!rRule.freq) {
      setRRule({
        freq: RRule.DAILY,
        interval: 1,
        dtstart: new Date(moment(editingAppointment.startDate).utc()),
        // until: new Date(getEODMoment(editingAppointment.endDate).utc()),
      });
    } else {
      setRRule({});
    }
  };

  const handleFrequencySelect = (e) => {
    const freq = e.target.value;
    const recurrenceObject = {
      ...rRule,
      freq: freq,
    };
    // TODO Fill out defaults
    if (freq === RRule.DAILY) {
    }
    if (freq === RRule.WEEKLY) {
      // ? set day of week (unnecessary?)
      // recurrenceObject.byweekday = moment(editingAppointment.startDate).day();
    }
    if (freq === RRule.MONTHLY) {
      const startingDate = moment(editingAppointment.startDate);
      // set date
      recurrenceObject.bymonthday = startingDate.date();
      // set position in month
      recurrenceObject.bysetpos = 1;
      // set day of week
      recurrenceObject.byweekday = 1;
    }
    setRRule({ ...recurrenceObject });
  };

  const handleIntervalChange = (e) => {
    // TODO Validate

    setRRule({ ...rRule, interval: parseInt(e.target.value) });
  };

  const handleDaySelection = (e, selectedDays) => {
    setRRule({ ...rRule, byweekday: selectedDays });
  };

  const handleMonthSelection = (e, selection) => {
    setMonthSelection(selection);
  };

  const handleMonthDayChange = (e) => {
    setRRule({ ...rRule, bymonthday: e.target.value });
  };

  const handlePositionSelect = (e) => {
    setRRule({ ...rRule, bysetpos: e.target.value });
  };

  const handleDayOfMonthSelect = (e) => {
    setRRule({ ...rRule, byweekday: e.target.value });
  };

  function handleEndDateAccept(e) {
    setEditingAppointment({ ...editingAppointment, endDate: e._d });
  }

  const handleUntilDateChange = (value) => {
    // Make sure the until date accounts for whole day and doesn't start at midnight
    let until = moment(value);
    if (until.isValid()) {
      until = getEODMoment(until);
    }
    setRRule({ ...rRule, until: new Date(until.utc()) });
  };

  const _addReservation = async (e) => {
    const input = {
      ...editingAppointment,
      startDate: moment(editingAppointment.startDate).format(),
      endDate: moment(editingAppointment.endDate).format(),
      rRule: validateRRule(rRule) ? new RRule({ ...rRule }).toString() : '',
    };

    await addReservation({
      variables: { input },
    });
  };

  const _updateReservation = async (e) => {
    const input = {
      ...editingAppointment,
      startDate: moment(editingAppointment.startDate).format(),
      endDate: moment(editingAppointment.endDate).format(),
      rRule: validateRRule(rRule) ? new RRule({ ...rRule }).toString() : '',
      _id: editingAppointment.id,
    };
    delete input.id;
    if (isSeries && updatingOccurrence) {
      if (!originalStart || !moment(originalStart).isValid || !rRule || _.isEmpty(rRule)) {
        throw new Error('There was a problem updating this occurrence');
      }
      await updateOccurrence({
        variables: { input, occurrenceDate: moment(originalStart).format() },
      });
    } else {
      // simply modify the entry if we are updating the whole series or a single event

      await updateReservation({
        variables: { input },
      });
    }
  };

  // INIT
  // Validate start and end to immediately notify of problems with dates selected in calendar
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      validateStart(editingAppointment.startDate);
      validateEnd(editingAppointment.startDate, editingAppointment.endDate);
    }
  }, [initialized, validateStart, validateEnd, editingAppointment]);

  // SUBCOMPONENTS

  const MonthlyByDate = () => {
    return (
      <Box sx={{ display: 'flex' }}>
        <p>On day </p>{' '}
        <TextField
          type="number"
          onWheel={(e) => e.target.blur()}
          value={rRule.bymonthday}
          onChange={handleMonthDayChange}
          disabled={monthSelection === 'day'}
          style={{ width: '5em', marginInline: '.5em' }}
        ></TextField>
        <p> of every month</p>
      </Box>
    );
  };

  const MonthlyByDay = () => {
    return (
      <Box sx={{ display: 'flex' }}>
        <p>The </p>
        <Select
          value={rRule.bysetpos}
          onChange={handlePositionSelect}
          disabled={monthSelection === 'date'}
          style={{
            width: '7em',
            marginInlineStart: '.5em',
            marginInlineEnd: '.25em',
          }}
        >
          <MenuItem value={1}>First</MenuItem>
          <MenuItem value={2}>Second</MenuItem>
          <MenuItem value={3}>Third</MenuItem>
          <MenuItem value={4}>Fourth</MenuItem>
          <MenuItem value={-1}>Last</MenuItem>
        </Select>
        <Select
          value={rRule.byweekday}
          onChange={handleDayOfMonthSelect}
          disabled={monthSelection === 'date'}
          style={{
            width: '8.5em',
            marginInlineStart: '.25em',
            marginInlineEnd: '.5em',
          }}
        >
          <MenuItem value={6}>Sunday</MenuItem>
          <MenuItem value={0}>Monday</MenuItem>
          <MenuItem value={1}>Tuesday</MenuItem>
          <MenuItem value={2}>Wednesday</MenuItem>
          <MenuItem value={3}>Thursday</MenuItem>
          <MenuItem value={4}>Friday</MenuItem>
          <MenuItem value={5}>Saturday</MenuItem>
        </Select>
        <p> of every month</p>
      </Box>
    );
  };

  const renderHelperText = () => {
    if (isUpdating && isSeries && !updatingOccurrence) {
      return (
        <FormHelperText>This change will override any changes made to individual events in the series.</FormHelperText>
      );
    }
    return null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setSubmissionLoading(true);
      if (isUpdating) {
        _updateReservation();
      } else {
        _addReservation();
      }
    }
  };

  // COMPONENT RENDER

  return isCancelling ? (
    <CancelReservationForm
      reservation={{ ...editingAppointment, rRule }}
      cancelReservation={cancelReservation}
      onClose={() => setIsCancelling(false)}
      setSubmissionLoading={setSubmissionLoading}
      submissionLoading={submissionLoading}
    />
  ) : (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box component="form" onSubmit={handleSubmit}>
        <Stack spacing={2} padding={2}>
          <Typography variant="h5">{`${isUpdating ? 'Update' : 'Create'} Reservation`}</Typography>
          <TextField
            label="Title"
            value={titleInput}
            required
            onChange={(e) => {
              if (titleError) {
                setTitleError('');
              }
              setTitleInput(e.target.value);
            }}
            onBlur={(e) => {
              updateFormTitle(e);
              validateTitle(e.target.value);
            }}
            fullWidth
            error={!!titleError}
            helperText={titleError}
          />
          <FormControl fullWidth>
            <InputLabel>Select Resource *</InputLabel>
            <Select
              value={editingAppointment.resource || ''}
              placeholder="Select a resource *"
              onChange={setReservationResource}
              input={<OutlinedInput label="Select resource *" required />}
            >
              {schedulerCategories
                .find((category) => category.fieldName === 'resource')
                .instances.map((resource) => (
                  <MenuItem key={resource.id} value={resource.id}>
                    {resource.text}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          {/* Only render protocols input if user is on protocol */}
          {schedulerCategories.find((category) => category.fieldName === 'protocol').instances.length > 1 && (
            <FormControl fullWidth>
              <InputLabel>Select Protocol</InputLabel>
              <Select
                value={editingAppointment.protocol || ''}
                placeholder="Select a protocol"
                onChange={setReservationProtocol}
                label="Select Protocol"
              >
                {schedulerCategories
                  .find((category) => category.fieldName === 'protocol')
                  .instances.map((protocol) => (
                    <MenuItem key={protocol.id} value={protocol.id}>
                      {protocol.text}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}

          <Grid container gap="1em 0">
            <Grid item xs={12} sm={6}>
              <DateTimePicker
                label="Reservation Start"
                disablePast
                // value={editingAppointment.startDate || null}
                value={startDateInput || editingAppointment.startDate}
                onChange={(val) => {
                  setStartDateInput(val);
                  if (startError) {
                    setStartError('');
                  }
                }}
                onAccept={(e) => {
                  handleStartDateAccept(e);
                  validateStart(e);
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      onBlur={(e) => {
                        validateStart(e.target.value);
                        setEditingAppointment({
                          ...editingAppointment,
                          startDate: moment(e.target.value),
                        });
                        if (isSeries) {
                          handleUpdateRRuleStart(e.target.value);
                        }
                      }}
                      required
                      error={!!startError}
                      helperText={startError}
                      fullWidth
                    />
                  );
                }}
                inputFormat="M/D/YYYY h:mm a"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateTimePicker
                label="Reservation End"
                disablePast
                value={endDateInput || editingAppointment.endDate}
                onChange={(val) => {
                  setEndDateInput(val);
                  if (endError) {
                    setEndError('');
                  }
                }}
                onAccept={(e) => {
                  // Due to the timing of state we need to manually validate the end date even though it should be validated in the full validator
                  validate(editingAppointment.startDate, moment(e));
                  handleEndDateAccept(e);
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      required
                      onBlur={(e) => {
                        validate(editingAppointment.startDate, e.target.value);
                        setEditingAppointment({ ...editingAppointment, endDate: moment(e.target.value) });
                      }}
                      error={!!endError}
                      helperText={endError}
                      fullWidth
                    />
                  );
                }}
                inputFormat="M/D/YYYY h:mm a"
              />
            </Grid>
          </Grid>

          {/* When editing dont provide option to switch from series to single occurrence*/}
          {!originalEventWasSeries && (
            <Box
              sx={{
                width: 250,
              }}
            >
              <FormControlLabel
                label="Reservation repeats"
                control={<Switch checked={!!rRule.freq} onChange={handleRepeatSwitch} />}
              />
            </Box>
          )}

          {/* Reservation Repeats Form */}
          {rRule.freq && !updatingOccurrence && (
            <Stack spacing={2}>
              <FormControl>
                <InputLabel>Reccurence</InputLabel>

                <Select
                  placeholder="Select Recurrence Period"
                  value={rRule.freq}
                  onChange={handleFrequencySelect}
                  label="Recurrence"
                >
                  <MenuItem value={RRule.DAILY}>Daily</MenuItem>
                  <MenuItem value={RRule.WEEKLY}>Weekly</MenuItem>
                  {/* <MenuItem value={RRule.MONTHLY}>Monthly</MenuItem> */}
                </Select>
              </FormControl>

              {/* DAILY */}

              {rRule.freq === RRule.DAILY && (
                <Box sx={{ display: 'flex' }}>
                  <p>Repeat Every </p>
                  <TextField
                    value={rRule.interval}
                    onChange={(e) => {
                      validate(editingAppointment.startDate, editingAppointment.endDate, {
                        ...rRule,
                        interval: parseInt(e.target.value),
                      });
                      handleIntervalChange(e);
                    }}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    InputProps={{ inputProps: { min: 1 } }}
                    style={{ width: '5em', marginInline: '.5em' }}
                  />
                  <p> day(s)</p>
                </Box>
              )}

              {/* WEEKLY */}
              {rRule.freq === RRule.WEEKLY && (
                <Stack spacing={2}>
                  <Box sx={{ display: 'flex' }}>
                    <p>Repeat Every </p>
                    <TextField
                      value={rRule.interval}
                      onChange={(e) => {
                        validate(editingAppointment.startDate, editingAppointment.endDate, {
                          ...rRule,
                          interval: parseInt(e.target.value),
                        });
                        handleIntervalChange(e);
                      }}
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      InputProps={{ inputProps: { min: 1 } }}
                      style={{ width: '5em', marginInline: '.5em' }}
                    />
                    <p> week(s) on:</p>
                  </Box>
                  <ToggleButtonGroup
                    size="small"
                    value={rRule.byweekday}
                    onChange={(e, selectedDays) => {
                      setFormError('');
                      validate(editingAppointment.startDate, editingAppointment.endDate, {
                        ...rRule,
                        byweekday: selectedDays,
                      });
                      handleDaySelection(e, selectedDays);
                    }}
                    aria-label="day selection"
                  >
                    <ToggleButton value={RRule.SU} aria-label="Sunday">
                      SUN
                    </ToggleButton>
                    <ToggleButton value={RRule.MO} aria-label="Monday">
                      MON
                    </ToggleButton>
                    <ToggleButton value={RRule.TU} aria-label="Tuesday">
                      TUE
                    </ToggleButton>
                    <ToggleButton value={RRule.WE} aria-label="Wednesday">
                      WED
                    </ToggleButton>
                    <ToggleButton value={RRule.TH} aria-label="Thursday">
                      THU
                    </ToggleButton>
                    <ToggleButton value={RRule.FR} aria-label="Friday">
                      FRI
                    </ToggleButton>
                    <ToggleButton value={RRule.SA} aria-label="Saturday">
                      SAT
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              )}

              {/* MONTHLY */}

              {rRule.freq === RRule.MONTHLY && (
                <Stack spacing={2}>
                  <Box sx={{ display: 'flex' }}>
                    <p>Repeat Every </p>
                    <TextField
                      value={rRule.interval}
                      onChange={handleIntervalChange}
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      style={{ width: '5em', marginInline: '.5em' }}
                    />
                    <p> month(s)</p>
                  </Box>

                  <RadioGroup value={monthSelection} onChange={handleMonthSelection}>
                    <Stack spacing={1}>
                      <Box sx={{ display: 'flex' }}>
                        <FormControlLabel value="date" control={<Radio />} label="" />
                        <MonthlyByDate />
                      </Box>

                      <Box sx={{ display: 'flex' }}>
                        <FormControlLabel value="day" label="" control={<Radio />} />
                        <MonthlyByDay />
                      </Box>
                    </Stack>
                  </RadioGroup>
                </Stack>
              )}
              {rRule.freq && (
                <DatePicker
                  label="Repeat Until"
                  disablePast
                  value={rRule.until || null}
                  onChange={(val) => {
                    handleUntilDateChange(val);
                    if (untilError) {
                      setUntilError('');
                    }
                  }}
                  maxDate={maxDate}
                  onAccept={(val) => {

                    validate(editingAppointment.startDate, editingAppointment.endDate, {
                      ...rRule,
                      until: getEODMoment(moment(val)),
                    });
                    handleUntilAccept(val);
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        required
                        onBlur={(e) => {
                          validate(editingAppointment.startDate, editingAppointment.endDate, {
                            ...rRule,
                            until: getEODMoment(moment(e.target.value)),
                          });
                          handleUntilDateChange(e.target.value);
                        }}
                        error={!!untilError}
                        helperText={untilError}
                      />
                    );
                  }}
                  inputFormat="MM/DD/YYYY"
                />
              )}
            </Stack>
          )}
          {!!formError && <FormHelperText error>{formError}</FormHelperText>}
          {renderHelperText()}
          {isUpdating && isSeries && (
            <Box>
              <FormControl>
                <RadioGroup
                  row
                  onChange={(e) => {
                    if (e.target.value === 'o') setUpdatingOccurrence(true);
                    else setUpdatingOccurrence(false);
                  }}
                  value={updatingOccurrence ? 'o' : 's'}
                >
                  <FormControlLabel value="o" control={<Radio />} label="Update occurrence" />
                  <FormControlLabel value="s" control={<Radio />} label="Update entire series" />
                </RadioGroup>
              </FormControl>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box>
              <Button variant="outlined" onClick={handleFormClose}>
                Close
              </Button>
              <Button variant="contained" type="submit">
                Save
              </Button>
            </Box>
          </Box>
        </Stack>
      </Box>
      <Dialog open={submissionLoading}>
        <Box padding={5}>
          <CircularProgress />
        </Box>
      </Dialog>
    </LocalizationProvider>
  );
}

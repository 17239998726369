import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import ProtocolSearch from '../inputs/ProtocolSearch';
import SpecialConditionsTable from '../SpecialConditionsTable';
import StandardWrapper from '../design/StandardWrapper';

const SpecialNotificationConditionLookup = ({ user }) => {
  const [protocol, setProtocol] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [key, setKey] = useState(false);

  return submitted ? (
    <SpecialConditionsTable
      protocolNumber={protocol.label.split(':')[0]}
      PIName={protocol.label.split(':')[1].replace(' ', '')}
      onClose={() => setSubmitted(false)}
    />
  ) : (
    <StandardWrapper>
      <form onSubmit={() => setSubmitted(true)}>
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <h1> Select a Protocol to View Special Considerations </h1>
          </Grid>
          <Grid item>
            <ProtocolSearch
              name="protocol"
              required
              key={key}
              onSelectProtocol={setProtocol}
              val={protocol ? protocol.protocolNumber : undefined}
              allowFreeFill
            />
          </Grid>
          <Grid item>
            <Button
              style={{ width: 125, marginTop: 20 }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={!protocol}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
    </StandardWrapper>
  );
};

export default SpecialNotificationConditionLookup;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Divider, Grid, Hidden } from '@material-ui/core';
import EmailChips from './EmailChips';
import EmailInput from './EmailInput';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: 20,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '50%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  divider: { margin: 20 },
}));

const EmailList = ({ options, onDelete, onSubmit, canAddUser, validate, canDelete, title = 'Email Recipients' }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" id="panel1c-header">
          <Typography className={classes.heading}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Grid container justifyContent="flex-start">
            <Grid item xs={12} lg={4}>
              <Typography className={classes.heading}>Add Email</Typography>
              <EmailInput validate={validate} onSubmit={onSubmit} canAddUser={canAddUser} />
            </Grid>
            <Grid item xs={12} lg={8}>
              <Hidden lgUp>
                <Divider className={classes.divider} />
              </Hidden>
              <Typography className={classes.heading}>Current Email Recipients</Typography>
              <EmailChips options={options} onDelete={onDelete} canDelete={canDelete} />
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
      </Accordion>
    </div>
  );
};

export default EmailList;

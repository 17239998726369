import React, { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  List,
  ListItemText,
  CircularProgress,
  FormControl,
  RadioGroup,
  FormLabel,
  Radio,
} from '@material-ui/core';
import ProtocolSearch from '../inputs/ProtocolSearch';
import BuildingSearch from '../inputs/BuildingSearch';
import SpeciesSearch from '../inputs/SpeciesSearch';
import { useMutation, useQuery } from '@apollo/client';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import PhoneInput from '../inputs/PhoneInput';
import EmailList from '../inputs/EmailList';
import FormDivider from '../design/FormDivider';
import SubmitterGroup from '../inputs/SubmitterGroup';
import TableSwitch, { isUSDAAnimal } from '../inputs/TableSwitch';
import ContainerTable from '../inputs/ContainerTable';
import SelectInput from '../inputs/SelectInput';
import ConditionalClearInput from '../inputs/ConditionalClearInput';
import { dateIsValid, dateFormat, buildingNameToCode, sleep } from '../../utils';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import StandardWrapper from '../design/StandardWrapper';
import { GET_TOASTS } from '../../global/graphql';

const EXPORT_MUTATION = gql`
  mutation SubmitExport(
    $sendingProtocolNumber: String!
    $sendingBuilding: String!
    $sendingRoom: String!
    $sendingContactName: String!
    $sendingContactEmail: String!
    $sendingContactPhone: String!
    $sendingBillingString: String!
    $mtaStatus: String!
    $receivingInstitution: String!
    $receivingPI: String!
    $receivingContactName: String!
    $receivingContactEmail: String!
    $receivingTransferCoordinatorName: String
    $receivingTransferCoordinatorEmail: String
    $receivingVetName: String
    $receivingVetEmail: String
    $bsl: Int!
    $biohazardAgent: String
    $immunodeficient: Boolean!
    $addlRequirements: String
    $species: String!
    $containers: String
    $animals: String
    $addlEmails: String
    $dateRequired: String
    $submitterEmail: String!
    $submitterPhone: String
    $naive: Boolean
  ) {
    submitExport(
      sendingProtocolNumber: $sendingProtocolNumber
      sendingBuilding: $sendingBuilding
      sendingRoom: $sendingRoom
      sendingContactName: $sendingContactName
      sendingContactEmail: $sendingContactEmail
      sendingContactPhone: $sendingContactPhone
      sendingBillingString: $sendingBillingString
      mtaStatus: $mtaStatus
      receivingInstitution: $receivingInstitution
      receivingPI: $receivingPI
      receivingContactName: $receivingContactName
      receivingContactEmail: $receivingContactEmail
      receivingTransferCoordinatorName: $receivingTransferCoordinatorName
      receivingTransferCoordinatorEmail: $receivingTransferCoordinatorEmail
      receivingVetName: $receivingVetName
      receivingVetEmail: $receivingVetEmail
      bsl: $bsl
      biohazardAgent: $biohazardAgent
      immunodeficient: $immunodeficient
      addlRequirements: $addlRequirements
      species: $species
      containers: $containers
      animals: $animals
      addlEmails: $addlEmails
      dateRequired: $dateRequired
      submitterEmail: $submitterEmail
      submitterPhone: $submitterPhone
      naive: $naive
    ) {
      success
      message
    }
  }
`;

const ExportForm = ({ user }) => {
  const [toast, setToast] = useState();
  const [errorDialog, setErrorDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateWeekendDialog, setDateWeekendDialog] = useState(false);
  const [dateCheckDialog, setDateCheckDialog] = useState(false);
  const [formReset, setFormReset] = useState(false);
  const [sendingReset, setSendingReset] = useState(false);
  const [receivingReset, setReceivingReset] = useState(false);
  const [userInfo] = useState(user);
  const [vars, setVars] = useState({
    immunodeficient: false,
    submitterEmail: userInfo.emailAddress,
    // dateRequired: dateFormat(Date.now()),
    naive: false,
  });
  const [namedContacts, setNamedContacts] = useState([
    {
      name: userInfo.firstName + ' ' + userInfo.lastName,
      email: userInfo.emailAddress,
      origin: 'submitter',
    },
  ]);
  const [protocol, setProtocol] = useState();
  const [sendingSave, setSendingSave] = useState();
  const [receivingSave, setReceivingSave] = useState();
  const [validationDialogMessage, setValidationDialogMessage] = useState();
  const [USDA, setUSDA] = useState(isUSDAAnimal(vars.species ? vars.species : '').isUSDA);
  const [infoToasts, setInfoToasts] = useState([])

  // Info toast logic
  const { data: toastData, loading: toastsLoading } = useQuery(GET_TOASTS, {
    variables: { form: "export" },
  });

  useEffect(() => {
    if (!toastsLoading && Array.isArray(toastData?.toasts)) {
      setInfoToasts(toastData.toasts.map(x => ({ ...x, active: true })))
    }
  }, [toastsLoading, toastData?.toasts])

  const disableInfoToast = _id => {
    setInfoToasts(infoToasts.map(x => {
      if (_id === x._id) {
        return { ...x, active: false }
      }
      return { ...x }
    }))
  }

  // End info toast logic

  const validate = () => {
    if (USDA.isUSDA) {
      if (!vars.animals || !Array.isArray(JSON.parse(vars.animals)) || JSON.parse(vars.animals).length < 1) {
        setValidationDialogMessage('You must add an animal in the table');
        return false;
      }
    } else if (
      !vars.containers ||
      !Array.isArray(JSON.parse(vars.containers)) ||
      JSON.parse(vars.containers).length < 1
    ) {
      setValidationDialogMessage('You must add a container in the table');
      return false;
    }

    // if (!vars.dateRequired) {
    //     setValidationDialogMessage('Please select a different transfer date')
    //     return false;
    // }

    if (!vars.mtaStatus) {
      setValidationDialogMessage('Please select an MTA status');
      return false;
    }
    console.log('!vars.bsl: ', !vars.bsl);
    if (!vars.bsl) {
      setValidationDialogMessage('Please select a biosafety level status');
      return false;
    }
    return true;
  };

  const resetForm = () => {
    const _vars = {};
    Object.keys(vars).forEach((key) => {
      _vars[key] = '';
    });

    if (!sendingSave) {
      sendingReset ? setSendingReset(false) : setSendingReset(true);
    } else {
      _vars.sendingProtocolNumber = vars.sendingProtocolNumber;
      _vars.sendingBuilding = vars.sendingBuilding;
      _vars.sendingRoom = vars.sendingRoom;
      _vars.sendingContactName = vars.sendingContactName;
      _vars.sendingContactEmail = vars.sendingContactEmail;
      _vars.sendingContactPhone = vars.sendingContactPhone;
      _vars.sendingBillingString = vars.sendingBillingString;
      // _vars.dateRequired = vars.dateRequired;
    }
    if (!receivingSave) {
      receivingReset ? setReceivingReset(false) : setReceivingReset(true);
    } else {
      _vars.receivingContactName = vars.receivingContactName;
      _vars.receivingContactEmail = vars.receivingContactEmail;
      _vars.receivingContactPhone = vars.receivingContactPhone;
      _vars.receivingInstitution = vars.receivingInstitution;
      _vars.receivingPI = vars.receivingPI;
      _vars.receivingTransferCoordinatorName = vars.receivingTransferCoordinatorName;
      _vars.receivingTransferCoordinatorEmail = vars.receivingTransferCoordinatorEmail;
      _vars.receivingTransferCoordinatorPhone = vars.receivingTransferCoordinatorPhone;
    }

    formReset ? setFormReset(false) : setFormReset(true);

    setVars({
      ..._vars,
      immunodeficient: false,
      submitterEmail: userInfo.emailAddress,
      submitterPhone: '',
      // dateRequired: vars.dateRequired,
      naive: false,
    });
  };

  const CREATE_ERROR = gql`
    mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
      createError(PVI: $PVI, action: $action, error: $error, data: $data)
    }
  `;
  const [createError] = useMutation(CREATE_ERROR);

  const [addExport] = useMutation(EXPORT_MUTATION, {
    onError(err) {
      setLoading(false);
      console.log(err);
      setErrorDialog(true);
      setToast(false);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'submitExport',
          error: err ? JSON.stringify(err) : undefined,
          data: JSON.stringify({
            user,
            vars,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setLoading(false);
        setToast(true);
        resetForm();
      } else setToast(false);
    },
  });

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setDateCheckDialog(false);
    setDateWeekendDialog(false);
    setToast(false);
    setErrorDialog(false);
    setValidationDialogMessage(false);
  };

  const handleInput = (name, item) => {
    setVars((prevState) => {
      let obj = { ...prevState };
      item !== null ? (obj[name] = item) : (obj[name] = '');
      return obj;
    });
  };

  const addNamedContact = (name, email, origin) => {
    let flag = false;

    setNamedContacts((prevState) => {
      for (let i = 0; i < prevState.length; i++) {
        if (prevState[i].origin === origin) {
          prevState[i].name = name;
          prevState[i].email = email;
          flag = true;
          break;
        }
      }

      if (!flag) {
        prevState.push({
          name: name,
          email: email,
          origin: origin,
        });
      }

      return prevState;
    });
  };

  return (
    <StandardWrapper>
      <h1 class="form-header">Exports</h1>
      <form
        id="export-form"
        onSubmit={(e) => {
          e.preventDefault();
          if (!validate()) {
            return;
          }
          setLoading(true);
          sleep(500);
          const variables = { ...vars };
          isUSDAAnimal(variables.species).isUSDA ? delete variables.containers : delete variables.animals;
          if (buildingNameToCode(variables.sendingBuilding)) {
            variables.sendingBuilding = buildingNameToCode(variables.sendingBuilding);
          }
          addExport({ variables });
        }}
        onKeyPress={(event) => {
          if (event.which === 13 /* Enter */) {
            event.preventDefault();
          }
        }}
      >
        <div class="container-div">
          <FormDivider text="Instructions" />
          <List className="instruction-list">
            <ListItemText primaryTypographyProps={{ style: { fontWeight: 'bold', color: 'black' } }}>
              &bull; Contact the{' '}
              <a
                style={{ fontWeight: 'bold', color: 'black' }}
                target="_blank"
                href="https://www.rsp.wisc.edu/contracts/mta.cfm"
                rel="noreferrer"
              >
                Office of Research and Sponsored Programs
              </a>{' '}
              to determine if a UW Material Transfer Agreement (MTA) is needed. This step is required for all research
              materials leaving campus.
            </ListItemText>
            <br></br>
            <ListItemText primaryTypographyProps={{ style: { fontWeight: 'bold', color: 'black' } }}>
              &bull;Prior to completing this form, have the name and email addresses of the receiving contacts ready.
              Including the transfers coordinator and facility veterinarian at the Receiving institution.
            </ListItemText>
            <br></br>
            <ListItemText primaryTypographyProps={{ style: { fontWeight: 'bold', color: 'black' } }}>
              &bull; Please review{' '}
              <a
                style={{ fontWeight: 'bold', color: 'black' }}
                href="https://www.rarc.wisc.edu/tools_and_guides/animal_transfer.html"
                target="_blank"
                rel="noreferrer"
              >
                RARC Animal Transfer Page
              </a>{' '}
              for more information on the Export Process.
            </ListItemText>
            <br></br>
            <ListItemText primaryTypographyProps={{ style: { fontWeight: 'bold', color: 'black' } }}>
              &bull; Lab staff or facility designee, are responsible for packing animals and meeting the courier driver.
              Additional shipping details will be provided by the RARC Transfers Coordinator.
            </ListItemText>{' '}
            <br />
            <ListItemText primaryTypographyProps={{ style: { fontWeight: 'bold', color: 'black' } }}>
              &bull; All animals for import are assumed to be BSL1. If animals will be inoculated with any Biohazard
              and/or Chemical Hazard <em>prior to shipment</em> you must include details below in "Special
              Requirements".
            </ListItemText>
          </List>
          <FormDivider text="Submitter Information" />
          <SubmitterGroup
            userInfo={userInfo}
            sendInput={handleInput}
            addContact={addNamedContact}
            key={formReset + 'Submitter Group'}
            val={vars}
          />
          <FormDivider text="Sending Information (UW)" />
          <Grid container>
            <Grid item xs>
              <ProtocolSearch
                required
                key={sendingReset}
                name="sendingProtocolNumber"
                onSelectProtocol={setProtocol}
                val={vars.sendingProtocolNumber}
                sendInput={handleInput}
              />
            </Grid>
            <Grid item xs>
              <BuildingSearch
                required
                key={sendingReset}
                name="sendingBuilding"
                protocol={protocol}
                val={vars.sendingBuilding}
                sendInput={handleInput}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <TextField
                required
                key={sendingReset}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Animal Housing Room"
                placeholder="Enter room #"
                value={vars.sendingRoom}
                onChange={(e) => handleInput('sendingRoom', e.target.value)}
              />
            </Grid>
            <Grid item xs>
              <TextField
                required
                key={sendingReset}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Sending Lab Contact Name"
                placeholder="Enter name"
                value={vars.sendingContactName}
                onChange={(e) => {
                  if (vars.sendingContactEmail) addNamedContact(e.target.value, vars.sendingContactEmail, 'sending');
                  handleInput('sendingContactName', e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <TextField
                required
                key={sendingReset}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Sending Lab Contact Email"
                placeholder="Enter email"
                type="email"
                value={vars.sendingContactEmail}
                onChange={(e) => {
                  if (vars.sendingContactName) addNamedContact(vars.sendingContactName, e.target.value, 'sending');
                  handleInput('sendingContactEmail', e.target.value);
                }}
              />
            </Grid>
            <Grid item xs>
              <PhoneInput
                isRequired={true}
                name="sendingContactPhone"
                label="Sending Lab Contact Phone"
                key={sendingReset}
                val={vars.sendingContactPhone}
                sendInput={handleInput}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <TextField
                required
                key={sendingReset}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Sending Billing String"
                placeholder="Enter billing string"
                value={vars.sendingBillingString}
                onChange={(e) => handleInput('sendingBillingString', e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs>
              <SelectInput
                required
                label="MTA Status"
                key={formReset}
                val={vars.mtaStatus}
                onChange={(e) => handleInput('mtaStatus', e.target.value)}
                menu={[
                  { value: 'submitted', text: 'Submitted' },
                  { value: 'approved', text: 'Approved/Signed' },
                  { value: 'notRequired', text: 'Not Required by Recipent' },
                ]}
              />
            </Grid>
          </Grid>

          <FormDivider text="Receiving Information (Other Institution)" />
          <Grid container>
            <Grid item xs>
              <TextField
                required
                key={receivingReset}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Institution"
                placeholder="Enter name of institution"
                value={vars.receivingInstitution}
                onChange={(e) => handleInput('receivingInstitution', e.target.value)}
              />
            </Grid>
            <Grid item xs>
              <TextField
                required
                key={receivingReset}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="PI Name"
                placeholder="Enter name"
                value={vars.receivingPI}
                onChange={(e) => handleInput('receivingPI', e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <TextField
                required
                key={receivingReset}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Lab Contact Name"
                placeholder="Enter name"
                value={vars.receivingContactName}
                onChange={(e) => {
                  if (vars.receivingContactEmail)
                    addNamedContact(e.target.value, vars.receivingContactEmail, 'receiving');
                  handleInput('receivingContactName', e.target.value);
                }}
              />
            </Grid>
            <Grid item xs>
              <TextField
                required
                key={receivingReset}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Lab Contact Email"
                placeholder="Enter email"
                type="email"
                value={vars.receivingContactEmail}
                onChange={(e) => {
                  if (vars.receivingContactName)
                    addNamedContact(vars.receivingContactName, e.target.value, 'receiving');
                  handleInput('receivingContactEmail', e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <TextField
                key={sendingReset}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Transfer Coordinator Name"
                required
                placeholder="Enter name"
                value={vars.receivingTransferCoordinatorName}
                onChange={(e) => handleInput('receivingTransferCoordinatorName', e.target.value)}
              />
            </Grid>
            <Grid item xs>
              <TextField
                variant="outlined"
                key={sendingReset}
                style={{ width: 300, margin: 10 }}
                label="Transfer Coordinator Email"
                required
                placeholder="Enter email"
                type="email"
                value={vars.receivingTransferCoordinatorEmail}
                onChange={(e) => handleInput('receivingTransferCoordinatorEmail', e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <TextField
                key={sendingReset}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Facility Vet Name"
                placeholder="Enter name"
                required
                value={vars.receivingVetName}
                onChange={(e) => handleInput('receivingVetName', e.target.value)}
              />
            </Grid>
            <Grid item xs>
              <TextField
                variant="outlined"
                key={sendingReset}
                style={{ width: 300, margin: 10 }}
                label="Facility Vet Email"
                placeholder="Enter email"
                type="email"
                value={vars.receivingVetEmail}
                onChange={(e) => handleInput('receivingVetEmail', e.target.value)}
                required
              />
            </Grid>
          </Grid>

          <FormDivider text="Animal Information" />
          <Grid container>
            <Grid item xs>
              <SpeciesSearch
                isRequired
                key={formReset}
                protocol={protocol}
                sendInput={handleInput}
                val={vars.species}
              />
            </Grid>
            <Grid item xs>
              <div
                style={{ display: 'flex-col', paddingLeft: 25, textAlign: 'left', flexDirection: 'col', width: 295 }}
              >
                <FormControlLabel
                  className="checkbox"
                  control={
                    <Checkbox
                      key={formReset}
                      checked={vars.immunodeficient}
                      onChange={(e) => handleInput('immunodeficient', e.target.checked)}
                    ></Checkbox>
                  }
                  label="Immunodeficient"
                ></FormControlLabel>
              </div>
              <div
                style={{ display: 'flex-col', paddingLeft: 25, textAlign: 'left', flexDirection: 'col', width: 295 }}
              >
                {/* <FormControlLabel
                                    className="checkbox"
                                    control={
                                        <Checkbox
                                            key={formReset}
                                            checked={vars.naive}
                                            onChange={e => handleInput("naive", e.target.checked)}
                                        ></Checkbox>
                                    }
                                    label="Naive (not previously used on study)"
                                ></FormControlLabel> */}

                <FormControl>
                  <FormLabel>Have these animals been used in a previous study?</FormLabel>
                  <RadioGroup
                    onChange={(e) => handleInput('naive', e.target.value === 'Yes' ? false : true)}
                    defaultValue={'Yes'}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel value={'Yes'} control={<Radio />} label="Yes" />
                    <FormControlLabel value={'No'} control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <SelectInput
                menu={[
                  { value: 1, text: '1' },
                  { value: 2, text: '2' },
                  { value: 3, text: '3' },
                ]}
                label="Biosafety Level"
                required
                key={formReset}
                val={vars.bsl}
                onChange={(e) => handleInput('bsl', parseInt(e.target.value))}
              />
            </Grid>
            <Grid item xs>
              {vars.bsl >= 2 ? (
                <TextField
                  required
                  variant="outlined"
                  label="Biohazardous Agents"
                  placeholder="Describe the hazardous agent(s)"
                  style={{ width: 300, marginTop: 10 }}
                  multiline
                  rows={3}
                  rowsMax={6}
                  value={vars.biohazardAgent}
                  onChange={(e) => handleInput('biohazardAgent', e.target.value)}
                ></TextField>
              ) : (
                <div className="row-spacer" />
              )}
            </Grid>
          </Grid>
          <TableSwitch
            key={formReset + USDA + 'table-switch'}
            sendInput={handleInput}
            species={vars.species ? vars.species : ''}
            val={vars.animals}
            allRequired={true}
            USDAStatus={USDA}
            setUSDAStatus={setUSDA}
          >
            <FormDivider text="Cages / Tanks" />
            <Grid container style={{ paddingLeft: 25, paddingRight: 25 }}>
              <ContainerTable key={formReset + 'container-table'} val={vars.containers} sendInput={handleInput} />
            </Grid>
          </TableSwitch>
          <FormDivider text="Other Contacts" />
          <EmailList
            key={formReset}
            sendInput={handleInput}
            existingContacts={namedContacts}
            instructions="A copy of this document will be sent to the contacts provided above .You may add email addresses for additional contacts from sending or receiving groups."
          />
          <br />
          <Grid container direction="column" spacing={3} style={{ paddingLeft: 25, paddingRight: 25 }}>
            <Grid item xs>
              <TextField
                key={formReset}
                variant="outlined"
                label="Special Requirements / Other Information"
                placeholder="Enter additional comments/instructions"
                fullWidth
                multiline
                rows={3}
                rowsMax={6}
                value={vars.addlRequirements}
                onChange={(e) => handleInput('addlRequirements', e.target.value)}
              ></TextField>
            </Grid>
          </Grid>
          <br></br>
          <Grid container style={{ paddingLeft: 25, paddingRight: 25 }}>
            <Grid item xs>
              <Button
                style={{ width: 120, float: 'right' }}
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                Submit
                {loading && (
                  <CircularProgress
                    disableShrink
                    style={{ position: 'absolute', zIndex: 2, color: 'white' }}
                    size={24}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
          <br />
        </div>
      </form>

      <Snackbar open={toast} autoHideDuration={6000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          Export has been submitted!
        </Alert>
      </Snackbar>
      <Dialog open={dateCheckDialog} onClose={handleToastClose}>
        <DialogTitle>{'Information'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A notice of 8 business days is preferred in advance of an export. Contact the veterinarian if this transfer
            is urgent.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dateWeekendDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Exports cannot be requested to arrive on weekends.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>The export failed to submit.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={validationDialogMessage} onClose={handleToastClose}>
        <DialogTitle>{'Form not Completed'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{validationDialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {infoToasts.map(toast => (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={toast.active}
          onClose={() => disableInfoToast(toast._id)}
          key={toast._id}
        >
          <Alert severity="info">
            {toast.message}
          </Alert>
        </Snackbar>
      ))}
    </StandardWrapper>
  );
};

export default ExportForm;

import React, { useState } from 'react';
import { Container, IconButton, Grid } from '@material-ui/core';
import ToolTile from './design/ToolTile';
import StandardWrapper from './design/StandardWrapper';
import { KeyboardArrowLeft } from '@material-ui/icons';

const Dashboard = ({ user }) => {
  return (
    <StandardWrapper>
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconButton
            aria-label="back"
            onClick={() => {
              window.location.href = '/';
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>
        </Grid>
      </Grid>
      <Container>
        <h1 class="form-header">Procedure Requests</h1>
        <br />
        <hr />
        <br />
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <ToolTile title="New Procedure Request" link="/request-procedure" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ToolTile title="View Existing Requests" link="/procedure-requests" />
          </Grid>
        </Grid>
        <br />
        <br />
      </Container>
    </StandardWrapper>
  );
};

export default Dashboard;

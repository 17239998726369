import React, { useState } from 'react';
import {
  Button,
  Grid,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import StandardWrapper from './design/StandardWrapper';
import { KeyboardArrowLeft } from '@material-ui/icons';

const UpdateArrow = ({ user }) => {
  const [errorDialog, setErrorDialog] = useState();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState();
  const [key, setKey] = useState(false);
  const [reinitializeNCs, setReinitializeNCs] = useState(false);
  const [ncSwitchDialog, setNCSwitchDialog] = useState(false);

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast();
    setErrorDialog(false);
  };

  const resetForm = () => {
    setKey(!key);
  };

  const CREATE_ERROR = gql`
    mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
      createError(PVI: $PVI, action: $action, error: $error, data: $data)
    }
  `;
  const [createError] = useMutation(CREATE_ERROR);

  const UPDATE_ARROW = gql`
    mutation UpdateArrow {
      updateArrowData
    }
  `;

  const [updateArrow] = useMutation(UPDATE_ARROW, {
    onError(e) {
      setLoading(false);
      setErrorDialog('Failed to update ARROW data.');
      setToast();
      createError({
        variables: {
          PVI: user.PVI,
          action: 'updateArrow',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
          }),
        },
      });
    },
    onCompleted(res) {
      if (!errorDialog) {
        setLoading(false);
        setToast('Site data updated with latest ARROW changes!');
        resetForm();
      } else setToast();
    },
  });

  const REINITIALIZE_NCS = gql`
    mutation updateArrowAndNCs {
      updateArrowAndNCData
    }
  `;

  const [updateArrowAndNCs] = useMutation(REINITIALIZE_NCS, {
    onError(e) {
      setLoading(false);
      setErrorDialog('Failed to update ARROW data.');
      setToast();
      createError({
        variables: {
          PVI: user.PVI,
          action: 'updateArrowAndNCs',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
          }),
        },
      });
    },
    onCompleted(res) {
      if (!errorDialog) {
        setLoading(false);
        setToast('Site data updated with latest ARROW changes!');
        resetForm();
      } else setToast();
    },
  });

  const handleSubmit = () => {
    if (!!reinitializeNCs) {
      updateArrowAndNCs();
      return;
    }
    updateArrow();
  };

  return (
    <StandardWrapper>
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconButton aria-label="back to home" href="/">
            <KeyboardArrowLeft />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <h1>Pull Most Up-To-Date Data from ARROW Datamart</h1>
        </Grid>

        <Grid item>
          <Button
            style={{ margin: 10, width: 300 }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            onClick={handleSubmit}
          >
            Fetch Data
            {loading && (
              <CircularProgress disableShrink style={{ position: 'absolute', zIndex: 2, color: 'white' }} size={24} />
            )}
          </Button>
          <Grid item>
            <FormControlLabel
              label="Reinitialize notification conditions"
              control={
                <Switch
                  color="primary"
                  checked={reinitializeNCs}
                  onChange={() => (reinitializeNCs === false ? setNCSwitchDialog(true) : setReinitializeNCs(false))}
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Snackbar open={!!toast} autoHideDuration={6000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          {toast}
        </Alert>
      </Snackbar>
      <Dialog open={!!errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorDialog}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!!ncSwitchDialog} onClose={() => setNCSwitchDialog(false)}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fetching data with the Reinitialize Notification Conditions switch on will
            <br />- Drop every user's autogenerated notification condition
            <br />- Drop the related notification conditions sets
            <br />- Drop all of the protocols collection
            <br /> It will then fetch data from ARROW as normal and reinitialize all users' notification conditions to
            their default value of true. Custom/non-autogenerated notification conditions will be ignorned. This action
            is not reversible.
            <br />
            Click CANCEL to ignore or OK to enable.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setNCSwitchDialog(false);
              setReinitializeNCs(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setNCSwitchDialog(false);
              setReinitializeNCs(true);
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </StandardWrapper>
  );
};

export default UpdateArrow;

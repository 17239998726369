import React from 'react';
import { Grid, Paper } from '@material-ui/core';
const StandardWrapper = ({ children, xl }) => {
  return (
    <Grid container id="body-wrapper" justifyContent="center">
      <Grid item xs={12} md={10} lg={9} xl={!!xl ? 8 : 6}>
        <Paper
          style={{
            // display: useMargins ? "inline-block" : "block",
            paddingLeft: '5%',
            paddingRight: '5%',
            paddingBottom: 16,
            marginBottom: 25,
            marginTop: 10,
            paddingTop: 32,
            marginLeft: 0,
            marginRight: 0,
          }}
          elevation={2}
        >
          {children}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default StandardWrapper;

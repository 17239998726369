import React from 'react';
import { TextField, Hidden, Grid, Button } from '@material-ui/core';

const MessageForm = ({
  onSubmit = console.log,
  validate = () => true,
  label,
  buttonText = 'Send Message',
  rows = 4,
  defaultValue = '',
  placeholder = '',
}) => {
  const [value, setValue] = React.useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const styles = {
    messageInput: {
      width: '100%',
      marginTop: 10,
      marginBottom: 10,
    },
  };

  const canSubmit = () => {
    if (!value) return false;
    if (!validate()) return false;
    return true;
  };

  const handleSubmit = () => {
    onSubmit(value);
    setValue('');
  };

  return (
    <Grid container justifyContent="flex-end">
      <TextField
        id="message-text-input"
        label={label}
        value={value}
        multiline
        rows={rows}
        defaultValue={defaultValue}
        variant="outlined"
        placeholder={placeholder}
        onChange={handleChange}
        style={styles.messageInput}
      />

      <Hidden xsDown>
        <Grid item>
          <Button
            style={{ borderWidth: 2 }}
            variant="outlined"
            // color="primary"
            onClick={handleSubmit}
            disabled={!canSubmit()}
          >
            {buttonText}
          </Button>
        </Grid>
      </Hidden>

      <Hidden smUp>
        <Grid item xs={12}>
          <Button
            style={{ width: '100%', margin: '10px 0', height: 50, borderWidth: 2 }}
            variant="outlined"
            onClick={handleSubmit}
            disabled={!canSubmit()}
          >
            {buttonText}
          </Button>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default MessageForm;

import React, { useState } from 'react';
import { Container, Typography } from '@material-ui/core';
import FormDivider from './design/FormDivider';
import RequestAccessForm from './forms/RequestAccessForm';

const NotAuthorized = ({ user }) => {
    return (
        <Container>
            <h1>
                Not Authorized
            </h1>
            <Typography
                variant="body1"
                className="instruction-text"
            >
                You are not authorized to view this page. The following options are available:
            </Typography>
            
            <Typography
                variant="body1"
                align="left"
                className="instruction-list"
                >
                <br/>
                &bull; Click the back button in your browser to return to the previous page. <br/>
                &bull; Try refreshing this page with different credentials. <br/>
                &bull; Request access below. <br/>
            </Typography>
            <RequestAccessForm user={user}/>
            <br/><br/>

        </Container>
    );
}

export default NotAuthorized;
import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  LinearProgress,
  Checkbox,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  // Accordion,
  // AccordionSummary,
  // AccordionDetails,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import StandardWrapper from './design/StandardWrapper';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams } from 'react-router-dom';

const GET_NOTIFICATION_CONDITIONS = gql`
  query NotificationConditions($terms: String) {
    notificationConditions(terms: $terms) {
      _id
      emailAddress
      value

      event {
        _id
        name
        label
      }

      protocol {
        _id
        label
        protocolNumber
      }
    }
  }
`;

// Updates a single notification condition value based on NC's _id
// returns a custom object if that was the last NC subscribed in a protocol
const UPDATE_NOTIFICAITON_CONDITION = gql`
  mutation UpdateNotificationCondition($_id: ID!, $value: Boolean) {
    updateNotificationCondition(_id: $_id, value: $value) {
      ... on NotificationCondition {
        _id
        value
      }
      ... on RequiredNCNotReceivedError {
        lastActiveNC {
          user {
            firstName
            lastName
          }
          protocol {
            label
          }
        }
      }
    }
  }
`;

// // Updates multiple notification condition values within a given protocol based on action(s)
// const UPDATE_PROTOCOL_NOTIFICATION_CONDITIONS = gql`
//   mutation UpdateProtocolNotificationConditions($_id: [ID]!, $value: Boolean!) {
//     updateProtocolNotificationConditions(_id: $_id, value: $value) {
//       ... on NotificationCondition {
//         _id
//         value
//       }
//       ... on RequiredNCNotReceivedError {
//         ncs {
//           user {
//             firstName
//             lastName
//           }
//           protocol {
//             label
//           }
//         }
//       }
//     }
//   }
// `;

const CREATE_ERROR = gql`
  mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
    createError(PVI: $PVI, action: $action, error: $error, data: $data)
  }
`;

export default function UserNotifications({ user, user: { PVI, _id } }) {
  /* ----------------------------------------- */
  /* STATE                                     */
  /* ----------------------------------------- */

  // actions to be displayed and edited // Currently only 'submitSAR' is desired but can be used as a filter in the future
  const [includedEventNames] = useState(['submitSAR']);

  // Unsubscribe via URL parameter
  const { protocolId } = useParams();

  // Flags so unsubscribe process only happens once
  const [unsubscribeComplete, setUnsubscribeComplete] = useState(false);
  const [unsubscribeDialogOpen, setUnsubscribeDialogOpen] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  /* ----------------------------------------- */
  /* API CALLS                                 */
  /* ----------------------------------------- */
  const [createError] = useMutation(CREATE_ERROR);

  const { loading, error, data, refetch } = useQuery(GET_NOTIFICATION_CONDITIONS, {
    variables: {
      terms: JSON.stringify({
        user: _id,
        autoGenerated: true
      }),
    },
  });

  const ncData = data?.notificationConditions || [];

  const protocolList = [
    ...new Set(
      ncData?.map((nc) => {
        return nc.protocol;
      })
    ),
  ];

  // Update notification condition state
  const [updateNotificationCondition] = useMutation(UPDATE_NOTIFICAITON_CONDITION, {
    onCompleted: ({ updateNotificationCondition: ncResponse }) => {
      // if last user was unsubscribed from one or more required NCs, notify user.
      if (ncResponse.__typename === 'RequiredNCNotReceivedError') {
        const { user, protocol } = ncResponse.lastActiveNC;

        // set dialog message and display
        setDialogMessage(
          `${user.firstName} ${user.lastName} was the last user receiving Sick and Dead Animal Report notifications for protocol(s): ${protocol.label}. To ensure these required notifications are being received, all users on the protocol have been resubscribed. Contact the protocol's owner with any questions.`
        );
        setDialogOpen(true);
      }

      refetch();
    },
    // Error message
    onError: (e) => {
      createError({
        variables: {
          PVI: user.PVI,
          action: 'updateNotificationCondition',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
          }),
        },
      });
    },
  });

  // const [updateProtocolNotificationConditions] = useMutation(UPDATE_PROTOCOL_NOTIFICATION_CONDITIONS, {
  // onCompleted: ({ updateNotificationCondition: ncResponse }) => {
  //   console.log(ncResponse);
  //   if (ncResponse.typename === 'RequiredNCNotReceivedError') {
  //     setDialogMessage(ncResponse.message);
  //     setDialogOpen(true);
  //   }
  //   refetch();
  // },
  // onError: (e) => {
  //   createError({
  //     variables: {
  //       PVI: user.PVI,
  //       action: 'updateNotificationCondition',
  //       error: e ? JSON.stringify(e) : undefined,
  //       data: JSON.stringify({
  //         user,
  //       }),
  //     },
  //   });
  // },
  // });

  // Handle Unsubscribe via URL
  useEffect(async () => {
    // don't evaluate if data isn't loaded
    if (ncData.length === 0) return;

    // ignore if no protocolId in URL
    if (!protocolId) return;

    // TODO // Unsubscribe All
    // const unsubscribeProtocols = [
    //   ...new Set(
    //     ncData?.map((nc) => {
    //       return nc.protocol._id;
    //     })
    //   ),
    // ];
    // if (!unsubscribeComplete && protocolId === 'all' && !!protocolList.length) {
    //   await updateProtocolNotificationConditions({
    //     variables: {
    //       _id: ncData.map((nc) => nc._id),
    //       value: false,
    //     },
    //   });
    //   setUnsubscribeComplete(true);
    //   setUnsubscribeDialogOpen(true);
    // }

    // Unsubscribe specific protocol
    const unsubNC = ncData?.find((nc) => nc.protocol.protocolNumber === protocolId);

    if (!unsubscribeComplete && !!unsubNC) {
      const updatedNC = await updateNotificationCondition({
        variables: {
          _id: unsubNC._id,
          value: false,
        },
      });

      setUnsubscribeComplete(true);
      setUnsubscribeDialogOpen(true);
    } else if (unsubNC === undefined) {
      setDialogMessage(
        `This URL is meant to unsubscribe a user from protocol '${protocolId}'. A protocol with this number doesn't appear to be connected to this user. Please contact whomever issued the URL with any questions.`
      );
      setDialogOpen(true);
    }
  }, [protocolId, ncData]);

  /* ----------------------------------------- */
  /* HANDLERS                                  */
  /* ----------------------------------------- */

  // Handles individual checkbox click
  const handleUpdate = async (id, value) => {
    await updateNotificationCondition({ variables: { _id: id, value: !value } });
  };

  // // Handles protocol checkbox
  // const handleUpdateProtocol = async (e) => {
  //   e.stopPropagation();
  //   // Extract protocol number
  //   const protocolId = e.target.getAttribute('data-protocol-id');

  //   // Pushes actions related to protocol into array
  //   const events = notificationState.map((notification) =>
  //     notification.protocol._id === protocolId ? notification.event.name : null
  //   );

  //   // Gets checkmark value
  //   const value = e.target.checked;

  //   // Calls mutation
  //   if (!!protocolId) {
  //     await updateProtocolNotificationConditions({
  //       variables: { PVI: PVI, protocol: protocolId, event: events, value: value },
  //     });
  //   }
  // };

  /* ----------------------------------------- */
  /* RENDER                                    */
  /* ----------------------------------------- */

  // TODO: refactor website theme provider
  const theme = createTheme({
    palette: {
      primary: {
        main: '#c5050c',
        light: '#c5050c',
        dark: '#c5050c',
        contrastText: '#fff',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <StandardWrapper>
        {loading ? (
          <LinearProgress />
        ) : (
          <Grid container>
            <Grid item xs={12} sx={{ marginBottom: '2em' }}>
              <h1 className="form-header">
                Notification Settings for {user.firstName} {user.lastName}
              </h1>
            </Grid>
            <Grid item xs={12} sm={7} sx={{ marginInline: 'auto' }}>
              {/* Unsubscribe Dialog Box */}
              <Dialog open={unsubscribeDialogOpen} onClose={() => setUnsubscribeDialogOpen(false)}>
                <DialogTitle>You've been unsubscribed!</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {` You will no longer receive notifications from
                    ${protocolId === 'all' ? ` any protocols. ` : `protocol ${protocolId}. `}
                    Double check your settings on this page to make sure you're receiving the proper notifications. An administrator may
                    choose to reassign a notification to you in the future.`}
                  </DialogContentText>
                  <DialogActions>
                    <Button variant="contained" size="small" onClick={() => setUnsubscribeDialogOpen(false)}>
                      Close
                    </Button>
                  </DialogActions>
                </DialogContent>
              </Dialog>

              {/* Main Notification Body */}
              {!ncData ? (
                <CircularProgress />
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Active</TableCell>
                      <TableCell>Protocol</TableCell>
                      <TableCell>Notification Name</TableCell>
                      <TableCell>Email Address</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {protocolList?.map((protocol, index) => {
                      // // Checkbox state per dropdown
                      // // checked or unchecked
                      // const allTrue = notificationState
                      //   ?.filter((nc) => nc.protocol.label === protocol.label)
                      //   .every((nc) => nc.value === true)
                      //   ? true
                      //   : false;

                      // // Indeterminite
                      // const someTrue =
                      //   !allTrue &&
                      //   notificationState
                      //     ?.filter((nc) => nc.protocol.label === protocol.label)
                      //     .find((nc) => nc.value === true);
                      return (
                        //   <Accordion key={index}>
                        //     <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ display: 'flex', alignItems: 'center' }}>
                        //       <Checkbox
                        //         size="small"
                        //         checked={allTrue}
                        //         indeterminate={someTrue}
                        //         color="primary"
                        //         onClick={(e) => handleUpdateProtocol(e)}
                        //         inputProps={{
                        //           'data-protocol-id': protocol._id,
                        //         }}
                        //         sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                        //       />
                        //       <h4>Notifications for {protocol.label || 'Unnamed Protocol'}</h4>
                        //     </AccordionSummary>
                        //     <AccordionDetails>

                        ncData?.map(
                          (nc, index) =>
                            nc.protocol.label === protocol.label && nc.event.name !== 'submitSAR' && (
                              <TableRow key={index} sx={{ height: '3em' }}>
                                <TableCell>
                                  <Checkbox
                                    size="small"
                                    checked={!!nc.value}
                                    color="primary"
                                    onChange={() => handleUpdate(nc._id, nc.value)}
                                  />
                                </TableCell>
                                <TableCell>{nc.protocol.label}</TableCell>
                                <TableCell>{nc.event.label}</TableCell>
                                <TableCell>{nc.emailAddress}</TableCell>
                              </TableRow>
                            )
                        )
                      );
                    })}
                  </TableBody>
                </Table>
                //   </AccordionDetails>
                // </Accordion>
              )}
            </Grid>
          </Grid>
        )}
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>Warning</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialogMessage}</DialogContentText>
            <DialogActions>
              <Button variant="contained" size="small" onClick={() => setDialogOpen(false)}>
                Close
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </StandardWrapper>
    </ThemeProvider>
  );
}

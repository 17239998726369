import React, { useState } from 'react';

import { Box, Button, Dialog, DialogContent, Grid, Paper, ThemeProvider, createTheme } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import UpdateUserGroupsForm from './forms/UpdateUserGroupsForm';
import CreateUserForm from './forms/CreateUser';
import UpdateUserForm from './forms/UpdateUserForm';

import StandardWrapper from './design/StandardWrapper';

const menuItems = [
  {
    category: 'Animal Tools',
    items: [
      { name: 'Place Animal Order', link: '/animal-order' },
      { name: 'Create Transfer Request', link: '/internal-transfer' },
      { name: 'Create Import Request', link: '/import' },
      { name: 'Create Export Request', link: '/export' },
      { name: 'Tissue Requests', link: 'https://app.smartsheet.com/b/form/c519267dbb32457eab484b280352d9c5' },
      {
        subcategory: 'USDA Fasting Requests',
        items: [
          { name: 'New Fasting Request', link: '/request-fast' },
          { name: 'View Existing Requests', link: '/fast-requests' },
        ],
      },
      {
        subcategory: 'Vivarium Compliance Tool',
        items: [
          { name: 'View reports', link: '/vct-dashboard' },
          { name: 'Create new report', link: '/vct-dashboard/?form', authGroups: ['admin', 'brms', 'brmsSuperuser'] },
        ],
      },
    ],
  },
  {
    category: 'Facility Tools',
    items: [
      { name: 'Resource Scheduler', link: '/resource-scheduler' },
      { name: 'Facilities', link: '/facilities', authGroups: ['facilitySupervisor', 'brmsSuperuser', 'admin'] },
      {
        subcategory: 'BRMS Supply Ordering',
        items: [
          { name: 'Order New Supplies', link: '/order-inventory', authGroups: ['admin', 'brmsSuperuser', 'brms'] },
          { name: 'View Existing Orders', link: '/inventory-orders', authGroups: ['admin', 'brmsSuperuser', 'brms'] },
          { name: 'Manage Inventory', link: '/inventory-items', authGroups: ['admin', 'brmsSuperuser'] },
        ],
        authGroups: ['admin', 'brmsSuperuser', 'brms'],
      },
      { name: 'BRMS Emergency Alert', link: '/emergency-alert', authGroups: ['admin', 'brmsSuperuser'] },

      {
        subcategory: 'Information',
        items: [
          { name: 'Safety Data Sheets', link: '/sds' },
          {
            name: 'Special Considerations',
            link: '/special-considerations',
            authGroups: ['husbandry', 'brms', 'brmsSuperuser', 'admin', 'facilitySupervisor'],
          },

          // { name: 'Manage Notifications', link: '/user-notifications' },
          // { name: 'BRMS Surplus Items', link: '/surplus-items' },]}
        ],
      },
      {
        name: 'Tech Time Slips',
        link: 'https://app.smartsheet.com/b/form/573a36975d2b4d77b507482158a9e941?confirm=true',
        authGroups: ['brms', 'brmsSuperuser', 'admin'],
      },
      { name: 'BRMS Feedback Portal', link: 'https://app.smartsheet.com/b/form/7a5c9c4f42eb427c8c8fb727865f38d8' },
      { name: 'Create Help Desk Ticket', link: 'https://uwsmph.teamwork.com/support' },
    ],
  },
  {
    category: 'Administrative Tools',
    items: [
      { name: 'Resources', link: '/add-resource', authGroups: ['admin', 'brmsSuperuser'] },
      { name: 'SAR Query', link: '/legacy-sar', authGroups: ['admin', 'brmsSuperuser'] },
      { name: 'DAR Query', link: '/legacy-dar', authGroups: ['admin', 'brmsSuperuser'] },
      {
        name: 'Manual Notifications',
        link: '/custom-notifications',
        authGroups: ['admin'],
      },
      {
        name: 'Notification Admin',
        link: '/notification-admin',
        authGroups: ['admin', 'superuser', 'brmsSuperuser'],
      },
      {
        subcategory: 'Users',
        items: [
          { name: 'Create User', form: 'CreateUserForm', authGroups: ['admin'] },
          { name: 'Update User', form: 'UpdateUserForm', authGroups: ['admin'] },
          { name: 'Authorize User', form: 'UpdateUserGroupsForm', authGroups: ['admin'] },
        ],
      },
      { name: 'API Key', link: '/create-api-key', authGroups: ['admin'] },
    ],
    authGroups: ['admin', 'superuser', 'husbandrySuperuser', 'brmsSuperuser'],
  },
];

const theme = createTheme({
  palette: {
    primary: {
      main: '#c5050c',
      contrastText: '#fff',
    },
  },
});

const userHasAuth = (authGroups, userGroups) => {
  return !authGroups || authGroups.some((auth) => userGroups.includes(auth));
};

const Dashboard = ({ user }) => {
  const [activeForm, setActiveForm] = useState();

  const renderForm = () => {
    switch (activeForm) {
      case 'UpdateUserGroupsForm': {
        return <UpdateUserGroupsForm user={user} />;
      }
      case 'CreateUserForm': {
        return <CreateUserForm />;
      }
      case 'UpdateUserForm': {
        return <UpdateUserForm />;
      }
      default:
        return null;
    }
  };

  return (
    <StandardWrapper xl>
      <ThemeProvider theme={theme}>
        <Box paddingBottom={3}>
          <h1 className="form-header">Animal Health and Welfare Tools</h1>
        </Box>

        <Grid container spacing={1}>
          {menuItems.map(
            (e, i) =>
              userHasAuth(e.authGroups, user.groups) && (
                <Grid item sm={12} md={6} key={i} width="100%">
                  <Paper
                    elevation={6}
                    sx={{ height: '96%', padding: '2%', justifyContent: 'flex-start', bgcolor: '#DADFE1' }}
                  >
                    <h1>{e.category}</h1>

                    {e.items.map(
                      (e, i) =>
                        userHasAuth(e.authGroups, user.groups) &&
                        (e.name ? (
                          <Button
                            key={i}
                            variant="contained"
                            fullWidth
                            href={e.link}
                            sx={{ justifyContent: 'flex-start', marginBlock: '.25em' }}
                          >
                            {e.name}
                          </Button>
                        ) : (
                          <Box key={i}>
                            <Button
                              variant="contained"
                              fullWidth
                              sx={{ justifyContent: 'flex-start', marginBlock: '.25em', cursor: 'default' }}
                              disableFocusRipple
                              disableRipple
                              disableTouchRipple
                            >
                              <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                                {e.subcategory}
                                <ExpandMore />
                              </Box>
                            </Button>

                            <Box padding="0 0 0 2.5em">
                              {e.items.map(
                                (e, i) =>
                                  userHasAuth(e.authGroups, user.groups) && (
                                    <Button
                                      key={i}
                                      variant="contained"
                                      fullWidth
                                      href={e.link ? e.link : undefined}
                                      onClick={e.link ? undefined : () => setActiveForm(e.form)}
                                      sx={{ justifyContent: 'flex-start', marginBlock: '.25em' }}
                                    >
                                      {e.name}
                                    </Button>
                                  )
                              )}
                            </Box>
                          </Box>
                        ))
                    )}
                  </Paper>
                </Grid>
              )
          )}
        </Grid>
        <Dialog open={!!activeForm} onClose={() => setActiveForm()}>
          <DialogContent>{renderForm()}</DialogContent>
        </Dialog>
      </ThemeProvider>
    </StandardWrapper>
  );
};

export default Dashboard;

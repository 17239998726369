import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import {
  Table,
  LinearProgress,
  TextField,
  Grid,
  Snackbar,
  Hidden,
  Card,
  CardContent,
  CardActions,
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import StatusIcon from '@material-ui/icons/FiberManualRecord';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import StandardWrapper from './design/StandardWrapper';
import moment from 'moment';
import { capitalize, getName } from '../utils';
import { KeyboardArrowLeft } from '@material-ui/icons';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    width: '100%',
    // flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: false,
      })}
    >
      <Grid container justifyContent="flex-end">
        <Grid item xs={12}>
          <Typography variant="h5" id="tableTitle" component="div">
            Procedure Requests
          </Typography>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    // minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

//TODO
const GET_PROCEDURE_REQUESTS = gql`
  query ProcedureRequests($terms: String) {
    procedureRequests(terms: $terms) {
      _id
      protocolNumber
      PIName
      number
      building
      room
      species
      numMales
      numFemales
      numNoSex
      bsl
      hazardExposure
      biohazardAgent
      servicesRequested
      contactName
      contactPhone
      contactEmail
      animalIds
      permittedPVIs
      status
      dateRequested
      assignedTo {
        firstName
        lastName
        PVI
      }
      secondaryAssignees {
        PVI
      }
    }
  }
`;

const processRows = ({ data, filterInput, showAssignedToOthers, showResolved, showUnassigned, user }) => {
  if (!data || !data.procedureRequests) {
    return [];
  }
  const { procedureRequests } = data;
  if (!Array.isArray(procedureRequests)) {
    console.error('Inventory Carts on provided data set is not an array');
    return [];
  }
  const keys = ['_id', 'number', 'PIName', 'servicesRequestedString', 'dateRequested', 'status', 'assignedToName'];
  let res = procedureRequests.map((x) => {
    const obj = {
      ...x,
    };

    if (!obj._id) {
      throw new Error(`Data object improperly formatted Obj: ${JSON.stringify(x)}`);
    }
    obj.status = obj.status.charAt(0).toUpperCase() + obj.status.slice(1);
    obj.assignedToName = obj.assignedTo ? getName(obj.assignedTo) : 'Unassigned';
    obj.dateRequested = moment(obj.dateRequested).unix();
    obj.servicesRequested.sort();
    obj.servicesRequestedString = capitalize(obj.servicesRequested.join(', '));
    keys.forEach((key) => {
      if (!obj[key]) {
        obj[key] = '';
      }
    });
    return obj;
  });
  if (typeof filterInput === 'string') {
    res = res.filter((x) => {
      let query = filterInput.toLowerCase();
      for (let i = 0; i < keys.length; i++) {
        if (typeof x[keys[i]] === 'string' && x[keys[i]].toLowerCase().includes(query)) {
          return true;
        }
      }
      if (x.number && x.number.toString().includes(filterInput)) {
        return true;
      }

      return false;
    });
  }
  res = res.filter(({ assignedTo, secondaryAssignees, status }) => {
    if (secondaryAssignees.map((x) => x.PVI).includes(user.PVI)) {
      return true;
    }
    if (!showUnassigned && !assignedTo) {
      return false;
    }
    if (!showResolved && ['Completed', 'Cancelled'].includes(status)) {
      return false;
    }
    if (assignedTo) {
      const { PVI } = assignedTo;
      if (PVI === user.PVI) return true;
      if (!showAssignedToOthers) {
        return false;
      }
    }

    return true;
  });

  return res;
};

const completeColor = '#488948';
const cancelColor = '#dadfe1';
const inProgressColor = '#006a8a';

const getColor = (status) => {
  if (status === 'Completed') {
    return completeColor;
  }
  if (status === 'Cancelled') {
    return cancelColor;
  }
  return inProgressColor;
};

const fullViewHeadCells = [
  { id: 'number', numeric: true, disablePadding: false, label: 'Request No.' },
  { id: 'assignedToName', numeric: true, disablePadding: false, label: 'Project Lead' },
  { id: 'PIName', numeric: true, disablePadding: false, label: 'PI' },
  { id: 'servicesRequestedString', numeric: false, disablePadding: false, label: 'Services' },
  { id: 'dateRequested', numeric: false, disablePadding: false, label: 'Requested Service Date' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'statusIndicator', numeric: false, disablePadding: false, label: '' },
];

export default function ProcedureRequestsTable({ user }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('dateRequested');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterInput, setFilterInput] = React.useState('');
  const [toast, setToast] = useState(false);
  const [headCells, setHeadCells] = useState(fullViewHeadCells);
  const [showResolved, setShowResolved] = useState(false);
  const [showUnassigned, setShowUnassigned] = useState(true);
  const [showAssignedToOthers, setShowAssignedToOthers] = useState(true);

  const handleToggleResolved = (e) => {
    const val = e.target.checked;
    setShowResolved(val);
    if (val) {
      setOrder('desc');
      setOrderBy('number');
    } else {
      setOrder('asc');
      setOrderBy('dateRequested');
    }
  };

  const { loading, data } = useQuery(GET_PROCEDURE_REQUESTS, {
    variables: { terms: JSON.stringify({}) },
  });

  const rows = processRows({ data, filterInput, showResolved, showAssignedToOthers, showUnassigned, user });

  console.log('rows: ', rows);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  if (loading) {
    return (
      <StandardWrapper>
        <LinearProgress />
      </StandardWrapper>
    );
  }

  const showAssignmentOptions = ['rs', 'admin'].some((group) => user.groups.includes(group));

  return (
    <StandardWrapper>
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconButton
            aria-label="back"
            onClick={() => {
              window.location.href = '/procedures';
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Hidden smDown>
        <Grid container>
          <Grid item xs={12}>
            <EnhancedTableToolbar />
          </Grid>
          <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
            {showAssignmentOptions && (
              <Grid item>
                <FormGroup row>
                  <FormControlLabel
                    control={<Switch checked={showUnassigned} onChange={(e) => setShowUnassigned(e.target.checked)} />}
                    label="Unassigned"
                  />
                </FormGroup>
              </Grid>
            )}
            {showAssignmentOptions && (
              <Grid item>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showAssignedToOthers}
                        onChange={(e) => setShowAssignedToOthers(e.target.checked)}
                      />
                    }
                    label="Assigned To Others"
                  />
                </FormGroup>
              </Grid>
            )}
            <Grid item>
              <FormGroup row>
                <FormControlLabel
                  control={<Switch checked={showResolved} onChange={handleToggleResolved} />}
                  label="Resolved"
                />
              </FormGroup>
            </Grid>
            <Grid item>
              <TextField value={filterInput} onChange={(e) => setFilterInput(e.target.value)} placeholder="Filter" />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
                <EnhancedTableHead
                  headCells={headCells}
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row._id}
                          onClick={
                            row.number
                              ? () => {
                                  window.location.href = `/procedure-requests/${row.number}`;
                                }
                              : null
                          }
                          style={row.number ? { cursor: 'pointer' } : null}
                        >
                          <TableCell component="th" id={labelId} scope="row" align="center">
                            {row.number}
                          </TableCell>
                          <TableCell align="left">{row.assignedToName}</TableCell>
                          <TableCell align="left">{row.PIName}</TableCell>
                          <TableCell align="left" style={{ padding: '16px 8px' }}>
                            {row.servicesRequestedString}
                          </TableCell>
                          <TableCell align="left">{moment.unix(row.dateRequested).format('M/D/YYYY')}</TableCell>
                          <TableCell align="left">{row.status}</TableCell>
                          <TableCell align="left">
                            <StatusIcon fontSize="small" style={{ color: getColor(row.status) }} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid
          container
          direction="column"
          spacing={3}
          alignItems="center"
          justifyContent="center"
          style={{ width: '100%' }}
          // style={{ paddingLeft: 25, paddingRight: 25 }}
        >
          <Grid item xs={12}>
            <Typography variant="h5" id="tableTitle" component="div">
              Procedure Requests
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ width: '100%', maxWidth: 500 }}>
            <Grid container justifyContent="space-between" style={{ margin: '20px 0px' }}>
              {showAssignmentOptions && (
                <Grid item xs={12}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch checked={showUnassigned} onChange={(e) => setShowUnassigned(e.target.checked)} />
                      }
                      label="Unassigned"
                    />
                  </FormGroup>
                </Grid>
              )}
              {showAssignmentOptions && (
                <Grid item xs={12}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showAssignedToOthers}
                          onChange={(e) => setShowAssignedToOthers(e.target.checked)}
                        />
                      }
                      label="Assigned To Others"
                    />
                  </FormGroup>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormGroup row>
                  <FormControlLabel
                    control={<Switch checked={showResolved} onChange={handleToggleResolved} />}
                    label="Resolved"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ width: '100%' }}
                  variant="outlined"
                  value={filterInput}
                  onChange={(e) => setFilterInput(e.target.value)}
                  placeholder="Filter"
                />
              </Grid>
            </Grid>
          </Grid>

          {stableSort(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <Grid item xs={12} style={{ width: '100%', maxWidth: 500 }}>
                  <Card
                    className="fast-card"
                    style={{ width: '100%', borderColor: getColor(row.status), borderWidth: 3, borderRadius: 10 }}
                    variant="outlined"
                  >
                    <CardContent>
                      <Grid container direction="column" spacing={3}>
                        <Grid item align="left">
                          <strong>Procedure #: </strong>
                          {row.number}
                        </Grid>
                        <Grid item align="left">
                          <strong>Assigned To: </strong>
                          {row.assignedToName}
                        </Grid>
                        <Grid item align="left">
                          <strong>PI: </strong>
                          {row.PIName}
                        </Grid>
                        <Grid item align="left">
                          <strong>Services: </strong>
                          {row.servicesRequestedString}
                        </Grid>
                        <Grid item align="left">
                          <strong>Requested Service Date: </strong>
                          {moment.unix(row.dateRequested).format('M/D/YYYY')}
                        </Grid>
                        <Grid item align="left">
                          <strong>Status: </strong>
                          {row.status}
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Button
                        onClick={
                          row.number
                            ? () => {
                                window.location.href = `/procedure-requests/${row.number}`;
                              }
                            : null
                        }
                        size="large"
                        color="secondary"
                      >
                        Details
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          {rows.length === 0 && (
            <Typography style={{ textAlign: 'center', marginTop: 16 }}>No procedures with these criteria</Typography>
          )}
        </Grid>
        <br />
        <br />
      </Hidden>
      <Snackbar open={toast} autoHideDuration={6000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          {toast}
        </Alert>
      </Snackbar>
    </StandardWrapper>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Table, LinearProgress, TextField, Grid } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { buildingCodeToName } from '../utils';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import NotificationConditionForm from './forms/NotificationConditionForm';
import StandardWrapper from './design/StandardWrapper';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'emailAddress', numeric: false, disablePadding: true, label: 'Email Address' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Event' },
  { id: 'protocolNumber', numeric: false, disablePadding: false, label: 'Protocol' },
  { id: 'building', numeric: false, disablePadding: false, label: 'Building' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    width: '100%',
    // flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, onDelete, filterInput, onFilterInputChange, onAdd, allowDelete = true } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Grid container justifyContent="flex-end">
        <Grid item xs={12}>
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1" component="div">
              {numSelected} selected
            </Typography>
          ) : (
            <Typography variant="h5" id="tableTitle" component="div">
              Manual Notifications
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} sm={5} md={4}>
          {numSelected > 0 ? (
            <Tooltip title="Delete">
              <IconButton aria-label="delete" onClick={onDelete} disabled={!allowDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <div style={{ minWidth: 300 }}>
              {/* <Tooltip title="Filter list"> */}
              {/* <IconButton aria-label="filter list">
                <FilterListIcon />
              </IconButton> */}
              <TextField
                // variant="outlined"
                value={filterInput}
                onChange={(e) => onFilterInputChange(e.target.value)}
                placeholder="Filter"
              />
              {/* </Tooltip> */}
              <Tooltip title="Add Notification">
                <IconButton aria-label="add notification" onClick={onAdd}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </Grid>
      </Grid>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const GET_NOTIFICATION_CONDITIONS = gql`
  query NotificationConditions($terms: String) {
    notificationConditions(terms: $terms) {
      _id
      emailAddress
      value
      action
      protocolNumber
      
      building {
        _id
        name
      }
    }
  }
`;

const DELETE_NOTIFICAITON_CONDITION = gql`
  mutation DelteNotificationCondition($_id: String, $_ids: [String]) {
    deleteNotificationCondition(_id: $_id, _ids: $_ids)
  }
`;

const processRows = ({ data, filterInput }) => {
  if (!data || !data.notificationConditions) {
    return [];
  }
  const { notificationConditions } = data;
  if (!Array.isArray(notificationConditions)) {
    console.error('Notification conditions on provided data set is not an array');
    return [];
  }
  const keys = ['_id', 'emailAddress', 'value', 'action', 'protocolNumber', 'building'];
  let res = notificationConditions.map((x) => {
    const obj = {
      ...x,
    };
    if (!obj._id) {
      throw new Error(`Data object has no _id. Obj: ${JSON.stringify(x)}`);
    }
    keys.forEach((key) => {
      if (!obj[key]) {
        obj[key] = '';
      }
    });
    return obj;
  });
  if (typeof filterInput === 'string') {
    return res.filter((x) => {
      let query = filterInput.toLowerCase();
      for (let i = 0; i < keys.length; i++) {
        if (typeof x[keys[i]] === 'string' && x[keys[i]].toLowerCase().includes(query)) {
          return true;
        } else if (
          keys[i] === 'action' &&
          typeof x[keys[i]] === 'string' &&
          getActionTitle(x[keys[i]]).toLowerCase().includes(query)
        ) {
          return true;
        } else if (
          keys[i].toLowerCase().includes('building') &&
          typeof x[keys[i]] === 'string' &&
          getBuildingTitle(x[keys[i]]).toLowerCase().includes(query)
        ) {
          return true;
        }
      }
      return false;
    });
  }
  return res;
};

const getActionTitle = (action) => {
  switch (action) {
    case 'submitAnimalOrder':
      return 'Animal Order';
    case 'submitTransfer':
      return 'Transfer';
    case 'submitImport':
      return 'Import';
    case 'submitExport':
      return 'Export';
    case 'submitSAR':
      return 'SAR';
    case 'orderInventory':
      return 'Supply Order';
    default:
      return action;
  }
};

const CREATE_ERROR = gql`
  mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
    createError(PVI: $PVI, action: $action, error: $error, data: $data)
  }
`;

const getTotalCount = (data) => {
  if (!data || !Array.isArray(data.notificationConditions)) {
    return 0;
  }
  return data.notificationConditions.length;
};

const getBuildingTitle = (code) => {
  if (buildingCodeToName(code)) {
    return `${buildingCodeToName(code)} (${code})`;
  }
  return code;
};

export default function AdminNotificationsTable({ user }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('action');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [formOpen, setFormOpen] = React.useState(false);
  const [filterInput, setFilterInput] = React.useState('');
  console.log('selected: ', selected);

  const [createError] = useMutation(CREATE_ERROR);

  const { loading, error, data, refetch } = useQuery(GET_NOTIFICATION_CONDITIONS, {
    variables: { terms: JSON.stringify({ autoGenerated: false }) },
  });

  const rows = processRows({ data, filterInput });

  const [deleteNotificationCondition] = useMutation(DELETE_NOTIFICAITON_CONDITION, {
    onCompleted: () => {
      setSelected([]);
      refetch();
    },
    onError: (e) => {
      createError({
        variables: {
          PVI: user.PVI,
          action: 'deleteNotificationCondition',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            selected,
            rows,
          }),
        },
      });
    },
  });

  console.log('rows: ', rows);

  const handleRequestSort = (event, property) => {
    console.log('property: ', property);

    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleDelete = () => {
    deleteNotificationCondition({ variables: { _ids: selected } });
  };

  if (formOpen) {
    return (
      <NotificationConditionForm
        onClose={() => {
          setFormOpen(false);
        }}
        onSubmit={refetch}
        user={user}
      />
    );
  }

  return (
    <StandardWrapper>
      {loading ? (
        <LinearProgress />
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              onDelete={handleDelete}
              onAdd={() => {
                setFormOpen(true);
              }}
              filterInput={filterInput}
              onFilterInputChange={setFilterInput}
              allowDelete={selected.length > 0 && selected.length < getTotalCount(data)}
            />
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table className={classes.table} aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table">
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row._id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row._id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row._id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row.emailAddress}
                          </TableCell>
                          <TableCell align="left">{row.action ? getActionTitle(row.action) : 'Any'}</TableCell>
                          <TableCell align="left">{row.protocolNumber || 'Any'}</TableCell>
                          <TableCell align="left">{row.building ? getBuildingTitle(row.building) : 'Any'}</TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      )}
    </StandardWrapper>
  );
}

import React, { useState } from 'react';
import { Button, Grid, TextField, FormControlLabel, Typography, Checkbox } from '@material-ui/core';
import ProtocolSearch from '../inputs/ProtocolSearch';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import BuildingSearch from '../inputs/BuildingSearch';
import { buildingNameToCode } from '../../utils';

const LegacySARSearch = ({
  user,
  onSubmit,
  onClose,
  protocolNumber: _protocolNumber,
  building: _building,
  onOrAfter: _onOrAfter,
  onOrBefore: _onOrBefore,
  emailAddress: _emailAddress,
  title,
}) => {
  const [onOrBefore, setOnOrBefore] = useState(_onOrAfter);
  const [onOrAfter, setOnOrAfter] = useState(_onOrBefore);
  const [protocolNumber, setProtocolNumber] = useState(_protocolNumber);
  const [building, setBuilding] = useState(_building);
  const [emailAddress, setEmailAddress] = useState(_emailAddress);
  const [arrowProtocol, setArrowProtocol] = useState(true);
  const [useBuildingCode, setUseBuildingCode] = useState(true);

  // const resetState = () => {
  //   setOnOrBefore(null)
  //   setOnOrAfter(null)
  //   setProtocolNumber()
  //   setBuilding("")
  //   setEmailAddress("")
  //   setProtocolInput("")
  // }

  const handleProtocolCheck = (checked) => {
    setProtocolNumber('');
    setArrowProtocol(checked);
  };

  const handleBuildingCheck = (checked) => {
    setBuilding('');
    setUseBuildingCode(checked);
  };

  const handleDateChange = ({ name, dateString }) => {
    if (name === 'onOrAfter') {
      setOnOrAfter(dateString);
      if (onOrBefore && moment(dateString).unix() > moment(onOrBefore).unix()) {
        setOnOrBefore(dateString);
      }
    } else {
      setOnOrBefore(dateString);
      if (onOrAfter && moment(onOrAfter).unix() > moment(dateString).unix()) {
        setOnOrAfter(dateString);
      }
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={10}>
        {title ? <h1>{title}</h1> : <h1> SAR Search Terms </h1>}
      </Grid>
      <Grid item xs={9}>
        {arrowProtocol ? (
          <ProtocolSearch
            name="protocol"
            required={false}
            onSelectProtocol={(x) => {
              console.log('x: ', x);

              if (x) {
                setProtocolNumber(x.protocolNumber);
              } else {
                setProtocolNumber(x);
              }
            }}
            val={protocolNumber}
            allowFreeFill
          />
        ) : (
          <TextField
            variant="outlined"
            style={{ width: 300, marginTop: 10, marginBottom: 10 }}
            label="Protocol"
            value={protocolNumber}
            onChange={(e) => {
              setProtocolNumber(e.target.value);
            }}
          />
        )}
      </Grid>
      <Grid item xs={9}>
        <FormControlLabel
          className="checkbox"
          style={{ width: 300, marginLeft: 10, marginBottom: 10 }}
          control={
            <Checkbox
              checked={arrowProtocol}
              onChange={(e) => {
                handleProtocolCheck(e.target.checked);
              }}
            ></Checkbox>
          }
          label="ARROW Protocol"
        ></FormControlLabel>
      </Grid>
      {/* <Grid item xs={9}>
        <TextField
          variant="outlined"
          style={{ width: 300, margin: 10 }}
          label="Email Address"
          type="email"
          value={emailAddress}
          onChange={e => { setEmailAddress(e.target.value) }}
        />
      </Grid> */}
      <Grid item xs={9}>
        <BuildingSearch
          val={building}
          sendInput={(x, value) => {
            console.log('value: ', value);

            // if (value && value.includes('(') && value.split('(').length > 1) {
            //   let str = value.split('(')[1]
            //   console.log("str: ", str)

            //   if (str.includes(')') && str.split(')').length > 0) {
            //     str = str.split(')')[0]
            //     console.log("str: ", str.split(')')[0])
            //     setBuilding(str)
            //   }
            // } else {
            //   setBuilding(value)
            // }
            if (useBuildingCode) {
              const code = buildingNameToCode(value);
              if (code) {
                setBuilding(code);
              } else {
                setBuilding(value);
              }
            } else {
              setBuilding(value);
            }
          }}
          freeSolo={!useBuildingCode}
          required={false}
        />
      </Grid>
      <Grid item xs={9}>
        <FormControlLabel
          style={{ width: 300, marginLeft: 10, marginBottom: 10 }}
          className="checkbox"
          control={
            <Checkbox
              checked={useBuildingCode}
              onChange={(e) => {
                handleBuildingCheck(e.target.checked);
              }}
            ></Checkbox>
          }
          label="Use Building Code"
        ></FormControlLabel>
      </Grid>
      <Grid item xs={9}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            value={onOrAfter || null}
            inputVariant="outlined"
            style={{ width: 300, margin: 10 }}
            className="to-clear"
            label="Beginning Date"
            format="MM/dd/yyyy"
            placeholder="Submitted On or After"
            InputProps={{
              inputProps: {
                id: 'sar-query-after-input',
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              if (!e) return;
              else handleDateChange({ name: 'onOrAfter', dateString: moment(e).format() });
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={9}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            value={onOrBefore || null}
            inputVariant="outlined"
            style={{ width: 300, margin: 10 }}
            className="to-clear"
            label="Submitted On or Before"
            format="MM/dd/yyyy"
            label="End Date"
            placeholder="Submitted On or Before"
            InputProps={{
              inputProps: {
                id: 'sar-query-before-input',
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              if (!e) return;
              else handleDateChange({ name: 'onOrBefore', dateString: moment(e).format() });
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={9}>
        {onClose ? (
          <Button
            style={{ width: 125, marginRight: 50 }}
            variant="contained"
            // color="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
        ) : (
          <div style={{ width: 150 }} />
        )}
        <Button
          style={{ width: 125 }}
          variant="contained"
          color="primary"
          type="submit"
          onClick={() => onSubmit({ protocolNumber, building, onOrAfter, onOrBefore, emailAddress })}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default LegacySARSearch;

import React from 'react';
import { TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';

const PhoneInput = ({ name, label, sendInput, isRequired, val}) => {
    const iprops = {
        inputProps: {
            // pattern: ".{13}"
        }
    }
    return (
        <TextField
            required={isRequired}
            style={{ width: 300, margin: 10 }}
            type="tel"
            InputProps={iprops}
            variant="outlined"
            name={name}
            value={val}
            onChange={e => sendInput(e.target.name, e.target.value)}
            label={label}
        />
    );
}

export default PhoneInput;
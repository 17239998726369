import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import { buildingCodeToName } from '../../utils'
import _ from 'lodash'

const GET_NOTIFICATION_CONDITIONS = gql`
  query NotificationConditions($terms: String, $formatActionToEvent: Boolean) {
    notificationConditions (terms: $terms, formatActionToEvent: $formatActionToEvent){
      _id
      emailAddress
      value
      event {
        name
      }
      building {
        name
        fpmCode
      }
    }
  }
`;

const BuildingSearch = ({ key, name, sendInput, val, required = true, label, freeSolo = false }) => {
    const handleSelect = (value) => sendInput(name, value);
    const { loading, error, data, refetch } = useQuery(GET_NOTIFICATION_CONDITIONS, {
        variables: { formatActionToEvent: true, terms: JSON.stringify({ autoGenerated: false, action: "fastRequest", building: { $exists: true }, protocol: { $exists: false }, value: true }) }
    });
    const buildings = data && Array.isArray(data.notificationConditions) ? data.notificationConditions.map(x => x.building) : []
    return (
        <Autocomplete
            className="to-clear"
            key={key}
            freeSolo={freeSolo}
            options={loading || !Array.isArray(data.notificationConditions) ? [] : _.uniq(buildings.map(x => x.name).sort())}
            style={{ width: 300, display: "inline-block", margin: 10 }}
            value={val}
            onChange={(e, value) => handleSelect(value)}
            renderInput={params => {
                return (
                    <TextField
                        {...params}
                        required={required}
                        className="to-clear"
                        placeholder="Enter building"
                        label={label || "Building"}
                        variant="outlined"
                        noOptionsText={val ? "No matches" : "Type to search"}
                        value={val}
                        onChange={freeSolo ? (e, value) => handleSelect(e.target.value) : console.log}
                    />
                )
            }}
        />
    );
}


export default BuildingSearch
import React, { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  ListItemText,
  List,
  LinearProgress,
  CircularProgress,
  FormControl,
  Radio,
  RadioGroup,
  FormLabel,
  Typography,
} from '@material-ui/core';
import ProtocolSearch from '../inputs/ProtocolSearch';
import BuildingSearch from '../inputs/BuildingSearch';
import SpeciesSearch from '../inputs/SpeciesSearch';
import { useMutation, useQuery } from '@apollo/client';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import PhoneInput from '../inputs/PhoneInput';
import EmailList from '../inputs/EmailList';
import FormDivider from '../design/FormDivider';
import TableSwitch from '../inputs/TableSwitch';
import TransferContainerSection from '../inputs/TransferContainerSection';
import SubmitterGroup from '../inputs/SubmitterGroup';
import SelectInput from '../inputs/SelectInput';
import { isUSDAAnimal } from '../inputs/TableSwitch';
import ConditionalClearInput from '../inputs/ConditionalClearInput';
import { dateIsValid, dateFormat, firstDateIsEarlier, sleep, pigMatch, buildingNameToCode } from '../../utils';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import StandardWrapper from '../design/StandardWrapper';
import { GET_TOASTS } from '../../global/graphql';

const TRANSFER_MUTATION = gql`
  mutation SubmitTransfer(
    $transportRequired: Boolean!
    $dateRequired: String!
    $returnDate: String
    $sendingProtocolNumber: String!
    $sendingPI: String
    $receivingProtocolNumber: String!
    $receivingPI: String
    $sendingBuilding: String!
    $receivingBuilding: String!
    $sendingRoom: String!
    $receivingRoom: String!
    $sendingContactName: String!
    $sendingContactEmail: String!
    $sendingContactPhone: String!
    $receivingContactName: String!
    $receivingContactEmail: String!
    $receivingContactPhone: String!
    $receivingBillingString: String
    $terminal: Boolean!
    $bsl: Int!
    $biohazardAgent: String
    $immunodeficient: Boolean!
    $addlRequirements: String
    $feed: String!
    $housing: String!
    $strain: String
    $numMales: Int
    $numFemales: Int
    $numCages: Int
    $species: String!
    $containers: String
    $animals: String
    $addlEmails: String
    $submitterEmail: String!
    $submitterPhone: String
    $chemicalHazard: String
    $naive: Boolean
  ) {
    submitTransfer(
      transportRequired: $transportRequired
      dateRequired: $dateRequired
      returnDate: $returnDate
      sendingProtocolNumber: $sendingProtocolNumber
      sendingPI: $sendingPI
      receivingProtocolNumber: $receivingProtocolNumber
      receivingPI: $receivingPI
      sendingBuilding: $sendingBuilding
      receivingBuilding: $receivingBuilding
      sendingRoom: $sendingRoom
      receivingRoom: $receivingRoom
      sendingContactName: $sendingContactName
      sendingContactEmail: $sendingContactEmail
      sendingContactPhone: $sendingContactPhone
      receivingContactName: $receivingContactName
      receivingContactEmail: $receivingContactEmail
      receivingContactPhone: $receivingContactPhone
      receivingBillingString: $receivingBillingString
      terminal: $terminal
      bsl: $bsl
      biohazardAgent: $biohazardAgent
      immunodeficient: $immunodeficient
      addlRequirements: $addlRequirements
      feed: $feed
      housing: $housing
      strain: $strain
      numMales: $numMales
      numFemales: $numFemales
      numCages: $numCages
      species: $species
      containers: $containers
      animals: $animals
      addlEmails: $addlEmails
      submitterEmail: $submitterEmail
      submitterPhone: $submitterPhone
      chemicalHazard: $chemicalHazard
      naive: $naive
    ) {
      success
      message
    }
  }
`;

const InternalTransferForm = ({ user }) => {
  const [toast, setToast] = useState();
  const [errorDialog, setErrorDialog] = useState(false);
  const [formReset, setFormReset] = useState(false);
  const [sendingReset, setSendingReset] = useState(false);
  const [receivingReset, setReceivingReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selfTransportDialog, setSelfTransportDialog] = useState(false);
  const [dateCheckDialog, setDateCheckDialog] = useState(false);
  const [dateWeekendDialog, setDateWeekendDialog] = useState(false);
  const [dateOrderDialog, setDateOrderDialog] = useState(false);
  const [userInfo] = useState(user);
  const [namedContacts, setNamedContacts] = useState([
    {
      name: userInfo.firstName + ' ' + userInfo.lastName,
      email: userInfo.emailAddress,
      origin: 'submitter',
    },
  ]);
  const [vars, setVars] = useState({
    immunodeficient: false,
    terminal: false,
    transportRequired: true,
    naive: false,
    submitterEmail: userInfo.emailAddress,
    dateRequired: null,
  });
  const [protocol, setProtocol] = useState();
  const [arrowProtocol, setArrowProtocol] = useState(true);
  const [recProtocol, setRecProtocol] = useState();
  const [recArrowProtocol, setRecArrowProtocol] = useState(true);
  const [sendingSave, setSendingSave] = useState();
  const [receivingSave, setReceivingSave] = useState();
  const [validationDialogMessage, setValidationDialogMessage] = useState();
  const [pigWarning, setPigWarning] = useState(false);
  const [pigWarningAcknowledged, setPigWarningAcknowledged] = useState(false);
  const [USDA, setUSDA] = useState(isUSDAAnimal(vars.species ? vars.species : '').isUSDA);
  const [infoToasts, setInfoToasts] = useState([false]);
  const [isWeekendTransfer, setIsWeekendTransfer] = useState(false);

  // Info toast logic
  const { data: toastData, loading: toastsLoading } = useQuery(GET_TOASTS, {
    variables: { form: "transfer" },
  });

  useEffect(() => {
    if (!toastsLoading && Array.isArray(toastData?.toasts)) {
      setInfoToasts(toastData.toasts.map(x => ({ ...x, active: true })))
    }
  }, [toastsLoading, toastData?.toasts])

  const disableInfoToast = _id => {
    setInfoToasts(infoToasts.map(x => {
      if (_id === x._id) {
        return { ...x, active: false }
      }
      return { ...x }
    }))
  }

  // End info toast logic

  const handleSelectProtocol = ({ protocol: _protocol, rec = false }) => {
    console.log('protocol: ', _protocol);
    if (rec) {
      setRecProtocol(_protocol);
      if (_protocol && _protocol.label) {
        handleInput(
          'receivingPI',
          `${_protocol.label.split(':')[1].split(',')[1].trim()} ${_protocol.label.split(':')[1].split(',')[0].trim()}`
        );
      } else {
        handleInput('receivingPI', '');
      }
    } else {
      setProtocol(_protocol);
      if (_protocol && _protocol.label) {
        handleInput(
          'sendingPI',
          `${_protocol.label.split(':')[1].split(',')[1].trim()} ${_protocol.label.split(':')[1].split(',')[0].trim()}`
        );
      } else {
        handleInput('sendingPI', '');
      }
    }
  };

  const resetForm = () => {
    const _vars = {
      submitterEmail: vars.submitterEmail,
      submitterPhone: '',
      transportRequired: true,
      sendingProtocolNumber: '',
      receivingProtocolNumber: '',
      receivingPI: '',
      sendingPI: '',
      sendingBuilding: '',
      receivingBuilding: '',
      sendingRoom: '',
      receivingRoom: '',
      sendingContactName: '',
      sendingContactEmail: '',
      sendingContactPhone: '',
      receivingContactName: '',
      receivingContactEmail: '',
      receivingContactPhone: '',
      receivingBillingString: '',
      terminal: false,
      bsl: '',
      biohazardAgent: '',
      immunodeficient: false,
      addlRequirements: '',
      feed: '',
      housing: '',
      strain: '',
      numMales: null,
      numFemales: null,
      numCages: null,
      species: '',
      containers: '',
      animals: '',
      addlEmails: '',
      naive: false,
    };

    console.log('vars: ', vars);

    if (!sendingSave) {
      sendingReset ? setSendingReset(false) : setSendingReset(true);
    } else {
      _vars.sendingProtocolNumber = vars.sendingProtocolNumber;
      _vars.sendingBuilding = vars.sendingBuilding;
      _vars.sendingRoom = vars.sendingRoom;
      _vars.sendingContactName = vars.sendingContactName;
      _vars.sendingContactEmail = vars.sendingContactEmail;
      _vars.sendingContactPhone = vars.sendingContactPhone;
      _vars.transportRequired = vars.transportRequired;
      _vars.dateRequired = vars.dateRequired;
      _vars.timeRequired = vars.timeRequired;
      _vars.returnDate = vars.returnDate;
      _vars.returnTime = vars.returnTime;
    }
    if (!receivingSave) {
      receivingReset ? setReceivingReset(false) : setReceivingReset(true);
    } else {
      _vars.receivingProtocolNumber = vars.receivingProtocolNumber;
      _vars.receivingBuilding = vars.receivingBuilding;
      _vars.receivingRoom = vars.receivingRoom;
      _vars.receivingContactName = vars.receivingContactName;
      _vars.receivingContactEmail = vars.receivingContactEmail;
      _vars.receivingContactPhone = vars.receivingContactPhone;
      _vars.receivingBillingString = vars.receivingBillingString;
    }
    formReset ? setFormReset(false) : setFormReset(true);
    console.log('resetting vars: ');
    console.log({
      immunodeficient: false,
      terminal: false,
      transportRequired: true,
      submitterEmail: userInfo.emailAddress,
      ..._vars,
    });

    setVars({
      immunodeficient: false,
      terminal: false,
      transportRequired: true,
      submitterEmail: userInfo.emailAddress,
      naive: false,
      ..._vars,
    });
    setIsWeekendTransfer(false);
  };

  const CREATE_ERROR = gql`
    mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
      createError(PVI: $PVI, action: $action, error: $error, data: $data)
    }
  `;
  const [createError] = useMutation(CREATE_ERROR);

  const [addTransfer] = useMutation(TRANSFER_MUTATION, {
    onError(err) {
      setLoading(false);
      console.error(err);
      setErrorDialog(true);
      setToast(false);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'submitTransfer',
          error: err ? JSON.stringify(err) : undefined,
          data: JSON.stringify({
            user,
            vars,
          }),
        },
      });
    },
    onCompleted() {
      setLoading(false);
      if (!errorDialog) {
        setToast(true);
        resetForm();
      } else setToast(false);
    },
  });

  const addNamedContact = (name, email, origin) => {
    let flag = false;

    setNamedContacts((prevState) => {
      for (let i = 0; i < prevState.length; i++) {
        if (prevState[i].origin === origin) {
          prevState[i].name = name;
          prevState[i].email = email;
          flag = true;
          break;
        }
      }

      if (!flag) {
        prevState.push({
          name: name,
          email: email,
          origin: origin,
        });
      }

      return prevState;
    });
  };
  const validateTable = () => {
    if (USDA.isUSDA) {
      if (!vars.animals || !Array.isArray(JSON.parse(vars.animals)) || JSON.parse(vars.animals).length < 1) {
        setValidationDialogMessage('You must add an animal in the table');
        return false;
      }
    }
    if (!vars.dateRequired) {
      setValidationDialogMessage('Please select a different transfer date');
      return false;
    }
    if (!vars.housing) {
      setValidationDialogMessage('Please select housing requirements');
      return false;
    }
    if (!vars.feed) {
      setValidationDialogMessage('Please select a feed type');
      return false;
    }
    if (!vars.bsl) {
      setValidationDialogMessage('Please select a biosafety level');
      return false;
    }
    return true;
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToast(false);
    setErrorDialog(false);
    setSelfTransportDialog(false);
    setDateCheckDialog(false);
    setDateWeekendDialog(false);
    setDateOrderDialog(false);
    setValidationDialogMessage(false);
    setPigWarning(false);
  };

  const handleInput = (name, item) => {
    setVars((prevState) => {
      let obj = { ...prevState };
      item !== null ? (obj[name] = item) : (obj[name] = '');
      return obj;
    });
  };

  const deleteContainerFields = (vars) => {
    delete vars.numMales;
    delete vars.numFemales;
    delete vars.numCages;

    return vars;
  };

  return (
    <StandardWrapper>
      <h1 class="form-header">Internal Transfers</h1>
      <form
        id="internal-transfer-form"
        onSubmit={(e) => {
          e.preventDefault();
          if (!validateTable()) {
            return;
          }
          setLoading(true);
          sleep(500);
          console.log('--> ' + vars.species);
          console.log(isUSDAAnimal(vars.species));
          console.log('USDA: ', USDA);
          USDA.isUSDA ? setVars(deleteContainerFields(vars)) : delete vars.animals;

          const variables = { ...vars };
          if (vars.timeRequired) {
            const timeMoment = moment(vars.timeRequired).tz('America/Chicago');
            const hour = timeMoment.hour();
            const minute = timeMoment.minute();
            variables.dateRequired = moment(vars.dateRequired)
              .tz('America/Chicago')
              .hour(hour)
              .minute(minute)
              .second(0)
              .format();
          }
          if (vars.returnTime) {
            const timeMoment = moment(vars.returnTime).tz('America/Chicago');
            const hour = timeMoment.hour();
            const minute = timeMoment.minute();
            variables.returnDate = moment(vars.returnDate)
              .tz('America/Chicago')
              .hour(hour)
              .minute(minute)
              .second(0)
              .format();
          }
          if (buildingNameToCode(variables.receivingBuilding)) {
            variables.receivingBuilding = buildingNameToCode(variables.receivingBuilding);
          }
          if (buildingNameToCode(variables.sendingBuilding)) {
            variables.sendingBuilding = buildingNameToCode(variables.sendingBuilding);
          }
          console.log('vars: ', vars);
          console.log('variables.dateRequired: ', variables.dateRequired);
          console.log('variables.returnDate: ', variables.returnDate);

          addTransfer({ variables });
        }}
        onKeyPress={(event) => {
          if (event.which === 13 /* Enter */) {
            event.preventDefault();
          }
        }}
      >
        <div class="container-div">
          <FormDivider text="Instructions" />
          <List className="instruction-list">
            <ListItemText primaryTypographyProps={{ style: { fontWeight: 'bold', color: 'black' } }}>
              &bull; If you are requesting BRMS or SVM-ARM transportation assistance, complete this form at lease 1 week
              in advance.
            </ListItemText>{' '}
            <br />
            <ListItemText primaryTypographyProps={{ style: { fontWeight: 'bold', color: 'black' } }}>
              &bull; When <em>not</em> requesting transportation assistance, complete this form at least 3 business days
              from today's date.
            </ListItemText>{' '}
            <br />
            <ListItemText primaryTypographyProps={{ style: { fontWeight: 'bold', color: 'black' } }}>
              &bull; You will be contacted by email once the transfer has been approved. If applicable, transportation
              assistance will be confirmed <em>after</em> approval is provided.
            </ListItemText>{' '}
            <br />
            <ListItemText primaryTypographyProps={{ style: { fontWeight: 'bold', color: 'black' } }}>
              &bull; Please mark cages with purple-colored Transport Cards by <em>7:00 am on the day of transport.</em>
            </ListItemText>{' '}
            <br />
            <ListItemText primaryTypographyProps={{ style: { fontWeight: 'bold', color: 'black' } }}>
              &bull; Include any transfer date/time flexibility, special handling and/or special husbandry requests in
              the Special Requirements box below.
            </ListItemText>{' '}
            <br />
            <ListItemText primaryTypographyProps={{ style: { fontWeight: 'bold', color: 'black' } }}>
              &bull; Ensure cage cards are updated per{' '}
              <a
                style={{ fontWeight: 'bold', color: 'black' }}
                href="https://policy.wisc.edu/library/UW-4104"
                target="_blank"
                rel="noreferrer"
              >
                UW Policy 4104
              </a>
            </ListItemText>
          </List>
          <FormDivider text="Submitter Information" />
          <SubmitterGroup
            userInfo={userInfo}
            sendInput={handleInput}
            addContact={addNamedContact}
            passedKey={formReset + 'Submitter Group'}
            val={vars}
          />
          <div style={{ display: 'flex-col', paddingLeft: 20, textAlign: 'left', flexDirection: 'col' }}>
            <FormControlLabel
              className="checkbox"
              control={
                <Checkbox
                  key={formReset}
                  checked={!isWeekendTransfer && vars.transportRequired}
                  disabled={isWeekendTransfer}
                  onChange={(e) => {
                    setSelfTransportDialog(!e.target.checked);
                    handleInput('transportRequired', e.target.checked);

                    if (vars.dateRequired) {
                      let date = new Date(Date.parse(vars.dateRequired));
                    }
                  }}
                ></Checkbox>
              }
              label="Transport Assistance Requested (unavailable for weekend or holiday transfers)"
            ></FormControlLabel>
          </div>
          <FormDivider text="Sending Information" />

          {arrowProtocol ? (
            <Grid container alignItems="center">
              <Grid item xs>
                <ProtocolSearch
                  required
                  key={sendingReset}
                  name="sendingProtocolNumber"
                  onSelectProtocol={(protocol) => handleSelectProtocol({ protocol, rec: false })}
                  val={vars.sendingProtocolNumber}
                  sendInput={handleInput}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  variant="outlined"
                  key={vars.sendingPI + 'sendingPI'}
                  style={{ width: 300, margin: 10 }}
                  label="PI"
                  value={vars.sendingPI}
                  disabled
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container alignItems="center">
              <Grid item xs>
                <TextField
                  required
                  variant="outlined"
                  style={{ width: 300, margin: 10 }}
                  label="Protocol Number"
                  placeholder="Enter Protocol Number"
                  value={vars.sendingProtocolNumber}
                  onChange={(e) => handleInput('sendingProtocolNumber', e.target.value)}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  required
                  variant="outlined"
                  style={{ width: 300, margin: 10 }}
                  placeholder="Enter PI Name"
                  label="PI"
                  value={vars.sendingPI}
                  onChange={(e) => handleInput('sendingPI', e.target.value)}
                />
              </Grid>
            </Grid>
          )}

          <Grid container>
            <Grid item xs>
              <FormControlLabel
                className="checkbox"
                style={{ width: 300, margin: 10 }}
                control={
                  <Checkbox
                    key={formReset}
                    checked={arrowProtocol}
                    onChange={(e) => {
                      setArrowProtocol(e.target.checked);
                      setProtocol();
                      handleInput('sendingProtocolNumber', '');
                      handleInput('sendingPI', '');
                    }}
                  ></Checkbox>
                }
                label="ARROW Protocol"
              ></FormControlLabel>
            </Grid>
            <Grid item xs>
              <BuildingSearch
                required
                key={sendingReset}
                freeSolo={!arrowProtocol}
                name="sendingBuilding"
                protocol={protocol}
                val={vars.sendingBuilding}
                sendInput={handleInput}
              />
            </Grid>
            <Grid />
          </Grid>

          <Grid container>
            <Grid item xs>
              <TextField
                required
                key={sendingReset}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Animal Housing Room"
                placeholder="Enter room #"
                value={vars.sendingRoom}
                onChange={(e) => handleInput('sendingRoom', e.target.value)}
              />
            </Grid>
            <Grid item xs>
              <TextField
                required
                key={sendingReset}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Sending Contact Name"
                placeholder="Enter name"
                value={vars.sendingContactName}
                onChange={(e) => {
                  if (vars.sendingContactEmail) addNamedContact(e.target.value, vars.sendingContactEmail, 'sending');
                  handleInput('sendingContactName', e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <TextField
                required
                key={sendingReset}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Sending Contact Email"
                placeholder="Enter email"
                type="email"
                value={vars.sendingContactEmail}
                onChange={(e) => {
                  if (vars.sendingContactName) addNamedContact(vars.sendingContactName, e.target.value, 'sending');
                  handleInput('sendingContactEmail', e.target.value);
                }}
              />
            </Grid>
            <Grid item xs>
              <PhoneInput
                isRequired={true}
                name="sendingContactPhone"
                label="Sending Contact Phone"
                key={sendingReset}
                val={vars.sendingContactPhone}
                sendInput={handleInput}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  value={vars.dateRequired || null}
                  inputVariant="outlined"
                  style={{ width: 300, margin: 10 }}
                  className="to-clear"
                  label="Requested Transfer Date"
                  key={dateWeekendDialog + sendingReset}
                  format="MM/dd/yyyy"
                  InputProps={{
                    inputProps: {
                      required: true,
                      id: 'transfer-date-input',
                    },
                  }}
                  InputLabelProps={{
                    required: true,
                    shrink: true,
                  }}
                  onChange={(e) => {
                    let date = new Date(Date.parse(e));
                    const isTyped = date > Date.now();
                    const isWeekend = date.getDay() === 0 || date.getDay() === 6;

                    if (isTyped) {
                      if (isWeekend) {
                        setDateWeekendDialog(true);
                        setIsWeekendTransfer(true);
                        handleInput('transportationRequired', false);
                      } else {
                        setIsWeekendTransfer(false);
                      }
                      if (!isWeekend && vars.returnDate && firstDateIsEarlier(vars.returnDate, dateFormat(e)))
                        setDateOrderDialog(true);
                      else {
                        if (!dateIsValid(date, !isWeekend && vars.transportRequired)) {
                          setDateCheckDialog(true);
                          handleInput('dateRequired', null);
                        } else {
                          handleInput('dateRequired', dateFormat(e));
                        }
                      }
                    }
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                {vars.dateRequired ? (
                  <KeyboardTimePicker
                    label="Requested Transfer Time"
                    // placeholder="08:00 AM"
                    mask="__:__ _M"
                    value={vars.timeRequired || null}
                    onChange={(e) => handleInput('timeRequired', e)}
                    inputVariant="outlined"
                    style={{ width: 300, margin: 10 }}
                  // className="to-clear"
                  />
                ) : (
                  <div className="row-spacer" />
                )}
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid container>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  value={vars.returnDate || null}
                  style={{ width: 300, margin: 10 }}
                  className="to-clear"
                  label="Requested Return Date"
                  key={dateWeekendDialog + sendingReset}
                  format="MM/dd/yyyy"
                  InputProps={{
                    inputProps: {
                      required: false,
                      id: 'transfer-return-input',
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    let date = new Date(Date.parse(e));
                    let base = new Date(Date.parse('01/01/2000'));
                    const isTyped = date > base;
                    const isWeekend = date.getDay() === 0 || date.getDay() === 6;

                    if (isTyped) {
                      if (isWeekend) setDateWeekendDialog(true);
                      else if (vars.dateRequired && firstDateIsEarlier(dateFormat(e), vars.dateRequired))
                        setDateOrderDialog(true);
                      else {
                        // Allowing past transfers
                        if (!dateIsValid(date, vars.transportRequired)) setDateCheckDialog(true);
                        handleInput('returnDate', dateFormat(e));
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item xs>
                {vars.returnDate ? (
                  <KeyboardTimePicker
                    label="Requested Return Time"
                    mask="__:__ _M"
                    value={vars.returnTime || null}
                    onChange={(e) => handleInput('returnTime', e)}
                    inputVariant="outlined"
                    style={{ width: 300, margin: 10 }}
                  />
                ) : (
                  <div className="row-spacer" />
                )}
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <div style={{ display: 'flex-col', padding: 4, textAlign: 'left', flexDirection: 'col' }}>
            <ConditionalClearInput section="sending" effect={setSendingSave} />
          </div>
          <FormDivider text="Receiving Information" />
          {/* <Grid container>
                        <Grid item xs>
                            <ProtocolSearch
                                required
                                key={receivingReset}
                                name="receivingProtocolNumber"
                                onSelectProtocol={setRecProtocol}
                                val={vars.receivingProtocolNumber}
                                sendInput={handleInput}
                            />
                        </Grid>
                        <Grid item xs>
                            <BuildingSearch
                                required
                                key={receivingReset}
                                name="receivingBuilding"
                                protocol={recProtocol}
                                val={vars.receivingBuilding}
                                sendInput={handleInput}
                            />
                        </Grid>
                    </Grid> */}

          {recArrowProtocol ? (
            <Grid container alignItems="center">
              <Grid item xs>
                <ProtocolSearch
                  required
                  key={receivingReset}
                  name="receivingProtocolNumber"
                  onSelectProtocol={(protocol) => handleSelectProtocol({ protocol, rec: true })}
                  val={vars.receivingProtocolNumber}
                  sendInput={handleInput}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  variant="outlined"
                  key={vars.receivingPI + 'receivingPI'}
                  style={{ width: 300, margin: 10 }}
                  label="PI"
                  value={vars.receivingPI}
                  disabled
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container alignItems="center">
              <Grid item xs>
                <TextField
                  required
                  variant="outlined"
                  style={{ width: 300, margin: 10 }}
                  label="Protocol Number"
                  placeholder="Enter Protocol Number"
                  value={vars.receivingProtocolNumber}
                  onChange={(e) => handleInput('receivingProtocolNumber', e.target.value)}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  required
                  variant="outlined"
                  style={{ width: 300, margin: 10 }}
                  placeholder="Enter PI Name"
                  label="PI"
                  value={vars.receivingPI}
                  onChange={(e) => handleInput('receivingPI', e.target.value)}
                />
              </Grid>
            </Grid>
          )}
          <Grid container>
            <Grid item xs>
              <FormControlLabel
                className="checkbox"
                style={{ width: 300, margin: 10 }}
                control={
                  <Checkbox
                    key={formReset}
                    checked={recArrowProtocol}
                    onChange={(e) => {
                      setRecArrowProtocol(e.target.checked);
                      setRecProtocol();
                      handleInput('receivingProtocolNumber', '');
                      handleInput('receivingPI', '');
                    }}
                  ></Checkbox>
                }
                label="ARROW Protocol"
              ></FormControlLabel>
            </Grid>
            <Grid item xs>
              <BuildingSearch
                required
                key={receivingReset}
                freeSolo={!recArrowProtocol}
                name="receivingBuilding"
                protocol={recProtocol}
                val={vars.receivingBuilding}
                sendInput={handleInput}
              />
            </Grid>
            <Grid />
          </Grid>

          <Grid container>
            <Grid item xs>
              <TextField
                required
                key={receivingReset}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Animal Housing Room"
                placeholder="Enter room #"
                value={vars.receivingRoom}
                onChange={(e) => handleInput('receivingRoom', e.target.value)}
              />
            </Grid>
            <Grid item xs>
              <TextField
                required
                key={receivingReset}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Receiving Contact Name"
                placeholder="Enter name"
                value={vars.receivingContactName}
                onChange={(e) => {
                  if (vars.receivingContactEmail)
                    addNamedContact(e.target.value, vars.receivingContactEmail, 'receiving');
                  handleInput('receivingContactName', e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <TextField
                required
                key={receivingReset}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Receiving Contact Email"
                placeholder="Enter email"
                type="email"
                value={vars.receivingContactEmail}
                onChange={(e) => {
                  if (vars.receivingContactName)
                    addNamedContact(vars.receivingContactName, e.target.value, 'receiving');
                  handleInput('receivingContactEmail', e.target.value);
                }}
              />
            </Grid>
            <Grid item xs>
              <PhoneInput
                isRequired={true}
                name="receivingContactPhone"
                label="Receiving Contact Phone"
                key={receivingReset}
                val={vars.receivingContactPhone}
                sendInput={handleInput}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <TextField
                key={receivingReset}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Receiving Billing String"
                placeholder="Enter billing string"
                value={vars.receivingBillingString}
                onChange={(e) => handleInput('receivingBillingString', e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs>
              <div className="row-spacer" />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <div style={{ display: 'flex-col', paddingLeft: 25, textAlign: 'left', flexDirection: 'col' }}>
                <ConditionalClearInput section="receiving" effect={setReceivingSave} />
              </div>
            </Grid>
          </Grid>
          <FormDivider text="Animal Information" />

          <Grid container>
            <Grid item xs>
              <div
                style={{ display: 'flex-col', paddingLeft: 25, textAlign: 'left', flexDirection: 'col', width: 295 }}
              >
                <div>
                  <FormControlLabel
                    className="checkbox"
                    control={
                      <Checkbox
                        key={formReset}
                        checked={vars.terminal}
                        onChange={(e) => handleInput('terminal', e.target.checked)}
                      ></Checkbox>
                    }
                    label="Animals will undergo terminal procedure at receiving protocol/location"
                  ></FormControlLabel>
                </div>
                <div>
                  <FormControlLabel
                    className="checkbox"
                    control={
                      <Checkbox
                        key={formReset}
                        checked={vars.immunodeficient}
                        onChange={(e) => handleInput('immunodeficient', e.target.checked)}
                      ></Checkbox>
                    }
                    label="Immunodeficient"
                  ></FormControlLabel>
                </div>
              </div>
            </Grid>
            <Grid item xs>
              <div
                style={{ display: 'flex-col', paddingLeft: 25, textAlign: 'left', flexDirection: 'col', width: 295 }}
              >
                <FormControl>
                  <FormLabel>Have these animals been used in a previous study?</FormLabel>
                  <RadioGroup
                    onChange={(e) => handleInput('naive', e.target.value === 'Yes' ? false : true)}
                    defaultValue={'Yes'}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel value={'Yes'} control={<Radio />} label="Yes" />
                    <FormControlLabel value={'No'} control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            {console.log('protocol: ', protocol)}
            <Grid item xs>
              <SpeciesSearch
                isRequired
                key={formReset}
                protocol={recProtocol}
                sendInput={(path, value) => {
                  if (!pigWarningAcknowledged && pigMatch(value)) {
                    setPigWarning(true);
                  } else if (pigWarningAcknowledged && !pigMatch(value)) {
                    setPigWarningAcknowledged(false);
                  }

                  handleInput(path, value);
                }}
                val={vars.species}
              />
            </Grid>
            <Grid item xs />
          </Grid>
          <Grid container>
            <Grid item xs>
              <SelectInput
                required
                key={formReset}
                label="Housing Requirements"
                val={vars.housing || null}
                onChange={(e) => handleInput('housing', e.target.value)}
                menu={[
                  { value: 'aseptic', text: 'Aseptic' },
                  { value: 'conventional', text: 'Conventional' },
                ]}
              />
            </Grid>
            <Grid item xs>
              <SelectInput
                required
                key={formReset}
                label="Feed Type"
                val={vars.feed || null}
                onChange={(e) => handleInput('feed', e.target.value)}
                menu={[
                  { value: 'maintenance', text: 'Maintenance' },
                  { value: 'breeder', text: 'Breeder' },
                  { value: 'Special', text: 'Special' },
                ]}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <SelectInput
                menu={[
                  { value: 1, text: '1' },
                  { value: 2, text: '2' },
                  { value: 3, text: '3' },
                ]}
                label="Biosafety Hazard"
                key={formReset}
                val={vars.bsl}
                required
                onChange={(e) => handleInput('bsl', parseInt(e.target.value))}
              />
            </Grid>
            <Grid item xs>
              <SelectInput
                menu={[
                  { value: 'None', text: 'None' },
                  { value: 'Carcinogen', text: 'Carcinogen' },
                  { value: 'Reproductive Hazard/Teratogen', text: 'Reproductive Hazard/Teratogen' },
                  { value: 'Toxicant/Toxic Agent', text: 'Toxicant/Toxic Agent' },
                ]}
                label="Chemical Hazard"
                key={formReset}
                val={vars.chemicalHazard}
                required
                onChange={(e) => handleInput('chemicalHazard', e.target.value)}
              />
            </Grid>
          </Grid>
          {vars.bsl >= 2 || (vars.chemicalHazard && vars.chemicalHazard !== 'None') ? (
            <Grid container>
              <Grid item>
                <TextField
                  required
                  key={formReset}
                  variant="outlined"
                  label="Hazardous Agents"
                  placeholder="Describe the hazardous agent(s)"
                  style={{ width: 300, margin: 20 }}
                  multiline
                  rows={3}
                  rowsMax={6}
                  value={vars.biohazardAgent}
                  onChange={(e) => handleInput('biohazardAgent', e.target.value)}
                ></TextField>
              </Grid>
            </Grid>
          ) : (
            <div className="row-spacer" />
          )}
          {/* { 
                        USDA.found ?
                            (<></>) :
                            (<>
                                <Grid container>
                                    <Grid item xs>
                                        <div style={{ display: 'flex-col', paddingLeft: 25, textAlign: 'left', flexDirection: 'col' }}>
                                            <FormControl>
                                                <FormLabel> Are these individually identifiable USDA animals? </FormLabel>
                                                <RadioGroup
                                                    row
                                                    onChange={e => {
                                                        setUSDA({ found: false, isUSDA: e.target.value === 'true' });
                                                        console.log(USDA);
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        className="checkbox"
                                                        control={
                                                            <Radio
                                                                defaultChecked
                                                                checked={USDA.isUSDA}
                                                            />
                                                        }
                                                        label="Yes"
                                                        value="true"
                                                    ></FormControlLabel>
                                                    <FormControlLabel
                                                        className="checkbox"
                                                        control={
                                                            <Radio
                                                                checked={!USDA.isUSDA}
                                                            />
                                                        }
                                                        label="No"
                                                        value="false"
                                                    ></FormControlLabel>
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                </Grid>
                            </>
                            )
                    } */}
          <TableSwitch
            sendInput={handleInput}
            species={vars.species ? vars.species : ''}
            val={vars.animals}
            key={formReset + 'table-switch'}
            allRequired={false}
            USDAStatus={USDA}
            setUSDAStatus={setUSDA}
          >
            <FormDivider text="Container Information" />
            <TransferContainerSection
              sendInput={handleInput}
              val={vars.containers}
              key={formReset + 'transfer-container'}
              strainRequired={false}
            />
          </TableSwitch>
          <FormDivider text="Other Contacts" />
          <EmailList key={formReset} sendInput={handleInput} existingContacts={namedContacts} />
          <br />
          <Grid container direction="column" spacing={3} style={{ paddingLeft: 25, paddingRight: 25 }}>
            <Grid item xs>
              <TextField
                variant="outlined"
                key={formReset}
                label="Special Requirements / Other Information"
                placeholder="Enter additional comments/instructions"
                fullWidth
                multiline
                rows={3}
                rowsMax={6}
                value={vars.addlRequirements}
                onChange={(e) => handleInput('addlRequirements', e.target.value)}
              ></TextField>
            </Grid>
          </Grid>
          <br></br>
          <Grid container style={{ paddingLeft: 25, paddingRight: 25 }}>
            <Grid item xs>
              <div>
                <Button
                  style={{ width: 120, float: 'right' }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  Submit
                  {loading && (
                    <CircularProgress
                      disableShrink
                      style={{ position: 'absolute', zIndex: 2, color: 'white' }}
                      size={24}
                    />
                  )}
                </Button>
              </div>
            </Grid>
          </Grid>
          <br />
        </div>
      </form>

      <Snackbar open={toast} autoHideDuration={6000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          Internal transfer has been submitted!
        </Alert>
      </Snackbar>

      <Dialog open={errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>The internal transfer failed to submit.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dateCheckDialog} onClose={handleToastClose}>
        <DialogTitle>{'Information'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The requested transfer date should be at least 3 business days from today.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dateWeekendDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please note that transportation assistance is not available on weekends.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dateOrderDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>The return date must be after the transfer date.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={selfTransportDialog} onClose={handleToastClose}>
        <DialogTitle>{'Notification'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Any self-transportation of animals must be in compliance with transportation policy as outlined
            <a
              href="https://www.rarc.wisc.edu/iacuc/acapac/2011-043-v_campus_transportation_of_laboratory_animals.html"
              style={{ textDecoration: 'none' }}
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              here
            </a>
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={validationDialogMessage} onClose={handleToastClose}>
        <DialogTitle>{'Form not Completed'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{validationDialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={pigWarning} onClose={handleToastClose}>
        <DialogTitle>{'Warning'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A disposal fee of $150 per animal will be charged if BRMS is responsible for carcass disposal of an animal
            coming from outside the SMPH.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPigWarningAcknowledged(true);
              handleToastClose();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {infoToasts.map(toast => (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={toast.active}
          onClose={() => disableInfoToast(toast._id)}
          key={toast._id}
        >
          <Alert severity="info">
            {toast.message}
          </Alert>
        </Snackbar>
      ))}
    </StandardWrapper>
  );
};

export default InternalTransferForm;

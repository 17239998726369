import React, { useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  FormControlLabel,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Checkbox,
  MenuItem,
  Hidden,
  List,
  Chip,
  useMediaQuery,
  RadioGroup,
  FormLabel,
  FormControl,
  Radio,
  Typography,
} from '@material-ui/core';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import StandardWrapper from '../design/StandardWrapper';
import ProtocolSearch from '../inputs/ProtocolSearch';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import { buildingCodeToName, buildingNameToCode } from '../../utils';
import { KeyboardArrowLeft } from '@material-ui/icons';
import BuildingSearch from '../inputs/BuildingSearch';
import SpeciesSearch from '../inputs/SpeciesSearch';
import SelectInput from '../inputs/SelectInput';
import HazardousSubstanceGroup from '../inputs/HazardousSubstanceGroup';
import ConditionalClearInput from '../inputs/ConditionalClearInput';

const ProcedureRequestForm = ({ user, procedureRequest, onSubmit }) => {
  const {
    _id,
    number,
    protocolNumber: _protocolNumber,
    PIName: _PIName,
    contactName: _contactName,
    contactEmail: _contactEmail,
    contactPhone: _contactPhone,
    billingString: _billingString,
    building: _building,
    room: _room,
    species: _species,
    numMales: _numMales,
    numFemales: _numFemales,
    numNoSex: _numNoSex,
    bsl: _bsl,
    hazardExposure: _hazardExposure,
    biohazardAgent: _biohazardAgent,
    servicesRequested: _servicesRequested,
    animalIds: _animalIds,
    additionalDetails: _additionalDetails,
    dateRequested: _dateRequested,
  } = procedureRequest;
  const [userInfo] = useState(user);

  console.log('_species: ', _species);

  // Mutation variables
  const [protocolNumber, setProtocolNumber] = useState(_protocolNumber);
  const [contactName, setContactName] = useState(_contactName);
  const [contactEmail, setContactEmail] = useState(_contactEmail);
  const [contactPhone, setContactPhone] = useState(_contactPhone);
  const [billingString, setBillingString] = useState(_billingString);
  const [building, setBuilding] = useState(buildingCodeToName(_building) || _building);
  const [room, setRoom] = useState(_room || '');
  const [species, setSpecies] = useState(_species);
  const [numMales, setNumMales] = useState(_numMales);
  const [numFemales, setNumFemales] = useState(_numFemales);
  const [numNoSex, setNumNoSex] = useState(_numNoSex);
  const [bsl, setBsl] = useState(_bsl);
  const [hazardExposure, setHazardExposure] = useState(_hazardExposure);
  const [biohazardAgent, setBiohazardAgent] = useState(_biohazardAgent || '');
  const [servicesRequested, setServicesRequested] = useState(_servicesRequested);
  const [animalIds, setAnimalIds] = useState(_animalIds || '');
  const [additionalDetails, setAdditionalDetails] = useState(_additionalDetails || '');
  const [PIName, setPIName] = useState(_PIName);
  const [dateRequested, setDateRequested] = useState(moment(_dateRequested).toDate());
  const [timeRequested, setTimeRequested] = useState(moment(_dateRequested).toDate());
  // const [text, setText] = useState()

  // UI and misc. form state variables
  const [errorDialog, setErrorDialog] = useState();
  const [dateWeekendDialog, setDateWeekendDialog] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState();
  const [numAnimalDialog, setNumAnimalDialog] = useState();
  const [key, setKey] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [protocol, setProtocol] = useState();
  const [arrowProtocol, setArrowProtocol] = useState(true);
  const [save, setSave] = useState(false);
  const [defaultLoc, setDefaultLoc] = useState(_building === '0451' && !_room);
  const smallScreen = useMediaQuery('(max-width:600px)');
  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
    setErrorDialog(false);
    setNumAnimalDialog(false);
    setDateWeekendDialog(false);
    setDateError(false);
  };

  const CREATE_ERROR = gql`
    mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
      createError(PVI: $PVI, action: $action, error: $error, data: $data)
    }
  `;
  const [createError] = useMutation(CREATE_ERROR);

  const UPDATE_PROCEDURE_REQUEST = gql`
    mutation updateProcedureRequest(
      $_id: ID!
      $protocolNumber: String!
      $PIName: String!
      $contactName: String!
      $contactEmail: String!
      $contactPhone: String!
      $billingString: String!
      $building: String!
      $room: String
      $species: String!
      $numMales: Int!
      $numFemales: Int!
      $numNoSex: Int!
      $bsl: Int!
      $hazardExposure: Boolean!
      $biohazardAgent: String
      $servicesRequested: [String!]!
      $animalIds: String
      $additionalDetails: String
      $dateRequested: String!
    ) {
      updateProcedureRequest(
        _id: $_id
        protocolNumber: $protocolNumber
        PIName: $PIName
        contactName: $contactName
        contactEmail: $contactEmail
        contactPhone: $contactPhone
        billingString: $billingString
        building: $building
        room: $room
        species: $species
        numMales: $numMales
        numFemales: $numFemales
        numNoSex: $numNoSex
        bsl: $bsl
        hazardExposure: $hazardExposure
        biohazardAgent: $biohazardAgent
        servicesRequested: $servicesRequested
        animalIds: $animalIds
        additionalDetails: $additionalDetails
        dateRequested: $dateRequested
      ) {
        _id
      }
    }
  `;

  const getDateRequested = () => {
    const dateMoment = moment(dateRequested);
    const timeMoment = moment(timeRequested);
    dateMoment.hour(timeMoment.hour());
    dateMoment.minute(timeMoment.minute());
    dateMoment.second(timeMoment.second());

    return dateMoment.toDate();
  };

  const getVariables = () => {
    return {
      _id,
      protocolNumber,
      PIName,
      contactName,
      contactEmail,
      contactPhone,
      billingString,
      building: defaultLoc ? 'SMI Bardeen' : buildingNameToCode(building) || building,
      room: defaultLoc ? '' : room,
      species,
      numMales: numMales || 0,
      numFemales: numFemales || 0,
      numNoSex: numNoSex || 0,
      bsl,
      hazardExposure,
      biohazardAgent: hazardExposure || bsl > 1 ? biohazardAgent : '',
      servicesRequested,
      animalIds,
      additionalDetails,
      dateRequested: getDateRequested(),
    };
  };
  const handleFieldChange = (event) => {
    event.persist();
    let val = event.target.value;
    setServicesRequested(val);
  };

  const [updateProcedureRequest] = useMutation(UPDATE_PROCEDURE_REQUEST, {
    onError(e) {
      setLoading(false);
      setErrorDialog(true);
      setToast(false);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'updateProcedureRequest',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            vars: getVariables(),
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setLoading(false);
        setToast(true);
        onSubmit();
      } else setToast(false);
    },
  });

  const handleSubmit = () => {
    const variables = getVariables();
    console.log('variables: ', variables);
    updateProcedureRequest({ variables });
  };

  const validate = () => {
    let date = moment(dateRequested);
    if (!date.isValid()) {
      setValidationError('Invalid date requested.');
      return false;
    }

    if (date.unix() < moment().unix()) {
      setValidationError('Date cannot be in the past.');
      return false;
    }

    if (!bsl) {
      setValidationError('Please select a biosafety level.');
      return false;
    }

    if (!Array.isArray(servicesRequested) || servicesRequested.length < 1) {
      setValidationError('Please select at least one service.');
      return false;
    }

    return true;
  };
  return (
    <StandardWrapper>
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconButton
            aria-label="back"
            onClick={() => {
              window.location.href = `/procedure-requests/${number}`;
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (validate()) {
              if (numMales + numFemales + numNoSex <= 0) {
                setNumAnimalDialog(true);
                return;
              }
              setLoading(true);
              handleSubmit();
            }
          }}
        >
          <Grid item xs={12}>
            <h1>Update Procedure Request #{number}</h1>
          </Grid>

          <Grid container>
            <Grid item xs>
              <TextField
                variant="outlined"
                key={protocolNumber}
                style={{ width: 300, margin: 10 }}
                label="Protocol"
                value={protocolNumber}
                disabled
              />
            </Grid>
            <Grid item xs>
              <TextField
                variant="outlined"
                key={PIName}
                style={{ width: 300, margin: 10 }}
                label="PI"
                value={PIName}
                disabled
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs></Grid>
            <Grid item xs>
              <TextField
                required
                key={key}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Billing String"
                placeholder="Enter billing string"
                value={billingString}
                onChange={(e) => setBillingString(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <TextField
                required
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Contact Name"
                placeholder="Enter name"
                value={contactName ? contactName : (userInfo.firstName || '') + ' ' + (userInfo.lastName || '')}
                key={key}
                onChange={(e) => {
                  setContactName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs>
              <TextField
                required
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Contact Email"
                placeholder="Enter email"
                value={contactEmail ? contactEmail : userInfo.emailAddress}
                key={key}
                onChange={(e) => {
                  setContactEmail(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <TextField
                required
                key={key}
                style={{ width: 300, margin: 10 }}
                type="tel"
                variant="outlined"
                value={contactPhone}
                onChange={(e) => setContactPhone(e.target.value)}
                label="Contact Phone"
              />
            </Grid>
            <Grid item xs />
          </Grid>
          <Grid container>
            <Grid item xs>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  value={dateRequested || null}
                  inputVariant="outlined"
                  disablePast
                  style={{ width: 300, margin: 10 }}
                  className="to-clear"
                  label="Requested Date"
                  key={dateWeekendDialog + key}
                  format="MM/dd/yyyy"
                  InputProps={{
                    inputProps: {
                      required: true,
                      id: 'procedure-date-input',
                    },
                  }}
                  InputLabelProps={{
                    required: true,
                    shrink: true,
                  }}
                  onChange={(e) => {
                    let date = new Date(Date.parse(e));
                    let now = new Date();
                    setDateRequested(moment(e));
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  label="Requested Service Time"
                  placeholder="Requested Service Time"
                  mask="__:__ _M"
                  value={timeRequested || null}
                  onChange={setTimeRequested}
                  inputVariant="outlined"
                  style={{ width: 300, margin: 10 }}
                  InputLabelProps={{
                    required: true,
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: {
                      required: true,
                    },
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs>
              <FormControlLabel
                className="checkbox"
                style={{ width: 300, margin: 10 }}
                control={<Checkbox checked={defaultLoc} onChange={(e) => setDefaultLoc(e.target.checked)} />}
                label="Perform procedure in Research Services Core"
              />
            </Grid>
            <Grid item xs={6} />
          </Grid>
          {!defaultLoc && (
            <Grid container>
              <Grid item xs>
                <BuildingSearch
                  required={false}
                  key={key}
                  freeSolo={!arrowProtocol}
                  val={building}
                  protocol={protocol}
                  required={false}
                  sendInput={(x, value) => {
                    setBuilding(value);
                  }}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  required={building && building !== 'SMI Bardeen'}
                  key={key}
                  variant="outlined"
                  style={{ width: 300, margin: 10 }}
                  label="Room"
                  placeholder="Enter room #"
                  value={room}
                  onChange={(e) => setRoom(e.target.value)}
                />
              </Grid>
            </Grid>
          )}
          <Grid container>
            <Grid item xs>
              <SpeciesSearch
                isRequired
                noType={arrowProtocol}
                key={key}
                protocol={protocol}
                defaultValue={species}
                sendInput={(_, value) => {
                  setSpecies(value);
                }}
                val={species}
              />
            </Grid>
            <Grid item xs>
              <TextField
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                key={key}
                label="Animal IDs"
                value={animalIds}
                onChange={(e) => {
                  setAnimalIds(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <TextField
                required={(numMales || 0) + (numFemales || 0) + (numNoSex || 0) < 1}
                type="number"
                onWheel={(e) => e.target.blur()}
                InputProps={{ inputProps: { min: 0 } }}
                key={key}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Num. Males"
                placeholder="Enter # of males"
                value={numMales}
                onChange={(e) => setNumMales(parseInt(e.target.value))}
              />
            </Grid>
            <Grid item xs>
              <TextField
                required={(numMales || 0) + (numFemales || 0) + (numNoSex || 0) < 1}
                type="number"
                onWheel={(e) => e.target.blur()}
                InputProps={{ inputProps: { min: 0 } }}
                key={key}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Num. Females"
                placeholder="Enter # of females"
                value={numFemales}
                onChange={(e) => setNumFemales(parseInt(e.target.value))}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <TextField
                required={(numMales || 0) + (numFemales || 0) + (numNoSex || 0) < 1}
                type="number"
                onWheel={(e) => e.target.blur()}
                InputProps={{ inputProps: { min: 0 } }}
                key={key}
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Num. No Sex Preference"
                placeholder="# of animals of arbitrary sex"
                value={numNoSex}
                onChange={(e) => setNumNoSex(parseInt(e.target.value))}
              />
            </Grid>
            <Grid item xs>
              <SelectInput
                menu={[
                  { value: 1, text: '1' },
                  { value: 2, text: '2' },
                ]}
                label="Biosafety Level"
                key={key}
                val={bsl}
                required
                onChange={(e) => setBsl(parseInt(e.target.value))}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            alignItems={smallScreen ? '' : 'flex-start'}
            style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 15 }}
          >
            <HazardousSubstanceGroup
              val={{
                hazardExposure: hazardExposure,
                biohazardAgent: biohazardAgent,
              }}
              key={key}
              sendInput={(name, val) => {
                name === 'hazardExposure' ? setHazardExposure(val) : setBiohazardAgent(val);
              }}
              active={hazardExposure || bsl === 2}
              requireDescription
            />
          </Grid>
          <Grid container>
            <Grid item xs>
              <TextField
                select
                key={key}
                name="services"
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                label="Services Requested"
                SelectProps={{
                  multiple: true,
                  value: servicesRequested,
                  onChange: handleFieldChange,
                }}
              >
                <MenuItem value="Administering Special Diets / Water">Administering Special Diets / Water</MenuItem>
                <MenuItem value="Blood Collection">Blood Collection</MenuItem>
                <MenuItem value="Tissue Collection">Tissue Collection</MenuItem>
                <MenuItem value="Perfusion">Perfusion</MenuItem>
                <MenuItem value="Intraperitoneal Injections"> Intraperitoneal Injections</MenuItem>
                <MenuItem value="Subcutaneous Injections">Subcutaneous Injections</MenuItem>
                <MenuItem value="Tamoxifen Administration">Tamoxifen Administration</MenuItem>
                <MenuItem value="Retro-orbital Injections">Retro-orbital Injections</MenuItem>
                <MenuItem value="Tail Vein Injections">Tail Vein Injections</MenuItem>
                <MenuItem value="Intramuscular Injections">Intramuscular Injections</MenuItem>
                <MenuItem value="Timed Mating/Embryo Harvest">Timed Mating/Embryo Harvest</MenuItem>
                <MenuItem value="Tumor/Cell Grafts">Tumor/Cell Grafts</MenuItem>
                <MenuItem value="Glucose Tolerance Testing">Glucose Tolerance Testing</MenuItem>
                <MenuItem value="Oral Gavage">Oral Gavage</MenuItem>
                <MenuItem value="Renal Capsule Transplant">Renal Capsule Transplant</MenuItem>
                <MenuItem value="Other">Other (specify in add'l description)</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6} />
          </Grid>
          <List className="service-list">
            {servicesRequested.map((service) => {
              return (
                <Chip
                  color="secondary"
                  onDelete={({ id = service }) => {
                    let temp = servicesRequested.filter((entry) => entry !== id);
                    setServicesRequested(temp);
                  }}
                  // avatar={<Avatar>{service.charAt(0).toUpperCase()}</Avatar>}
                  label={service}
                  style={{
                    margin: 5,
                  }}
                ></Chip>
              );
            })}
          </List>
          <Hidden xsDown>
            <Grid container>
              <Grid item xs>
                <TextField
                  required={servicesRequested.includes('Other')}
                  variant="outlined"
                  key={key}
                  label="Additional Details"
                  multiline
                  fullWidth
                  rows={3}
                  rowsMax={6}
                  value={additionalDetails}
                  style={{ margin: 10, width: 620 }}
                  onChange={(e) => setAdditionalDetails(e.target.value)}
                ></TextField>
              </Grid>
            </Grid>

            {/* <Grid container>
                            <Grid item xs>
                                <TextField
                                    variant="outlined"
                                    key={key}
                                    label="Message"
                                    multiline
                                    fullWidth
                                    rows={3}
                                    rowsMax={6}
                                    value={text}
                                    style={{ margin: 10, width: 620 }}
                                    onChange={e => setText(e.target.value)}
                                ></TextField>
                            </Grid>
                        </Grid> */}
            <Grid container>
              <Typography style={{ width: 620, margin: 10 }} variant="caption">
                Please be advised that we cannot guarantee dates/times of your request until reviewed and approved. All
                procedures that are requested must be described in an approved animal protocol. Procedures are completed
                on a first come first serve basis. We require at minimum 48 hours advance notice to start or cancel an
                experiment. If cancellation occurs within less than 48 hours you may be charged.
              </Typography>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  style={{ width: 125, margin: 10 }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  Submit
                  {loading && (
                    <CircularProgress
                      disableShrink
                      style={{ position: 'absolute', zIndex: 2, color: 'white' }}
                      size={24}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item>
              <TextField
                required={servicesRequested.includes('Other')}
                variant="outlined"
                key={key}
                label="Additional Details"
                multiline
                fullWidth
                rows={3}
                rowsMax={6}
                value={additionalDetails}
                style={{ margin: 10, width: 300 }}
                onChange={(e) => setAdditionalDetails(e.target.value)}
              ></TextField>
            </Grid>

            <Grid item>
              <ConditionalClearInput section="all" label="Save form after submission" effect={setSave} />
            </Grid>
            <Grid item xs>
              <Typography style={{ width: 620, margin: 10 }} variant="caption">
                Please be advised that we cannot guarantee dates/times of your request until reviewed and approved. All
                procedures that are requested must be described in an approved animal protocol. Procedures are completed
                on a first come first serve basis. We require at minimum 48 hours advance notice to start or cancel an
                experiment. If cancellation occurs within less than 48 hours you may be charged.
              </Typography>
            </Grid>
            <Grid item>
              <Button
                style={{ margin: 10, width: 300 }}
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                Submit
                {loading && (
                  <CircularProgress
                    disableShrink
                    style={{ position: 'absolute', zIndex: 2, color: 'white' }}
                    size={24}
                  />
                )}
              </Button>
            </Grid>
          </Hidden>
        </form>
      </Grid>
      <Snackbar open={!!toast} autoHideDuration={6000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          Procedure Request Updated!
        </Alert>
      </Snackbar>
      <Dialog open={!!errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Failed to update procedure request. The BRMS IT office has been alerted of this issue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!validationError}
        onClose={() => {
          setValidationError();
        }}
      >
        <DialogTitle>{'Invalid Input'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{validationError}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setValidationError();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dateWeekendDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Requested date cannot be on a weekend.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={numAnimalDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Total number of animals must exceed zero.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dateError} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You may not submit a report that is less than 48 hours from the requested date.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {/*<Dialog
                open={!!bypassOpen}
            >
                <DialogTitle>{"Late Submission Warning"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You are attempting to submit a procedure request after the deadline. The deadline for procedure requests is 12:30 pm America/Chicago the day before the fast is to begin.
                        As a {user.groups.includes('admin') ? "website administrator" : "BRMS superuser"} you are authorized to bypass this restriction. By doing so, you agree
                        to take responsibitity for ensuring that the animal is fasted.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setBypassOpen(false) }} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={bypass} color="primary">
                        Agree and Bypass
                    </Button>
                </DialogActions>
            </Dialog>*/}
    </StandardWrapper>
  );
};

export default ProcedureRequestForm;

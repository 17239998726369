import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const ToolTile = ({ link, title, onClick = console.log, isLink = true }) => {

    return (
        <>
            <div className="card-wrapper">
                <div className="card">
                    {
                        isLink ? (
                            <Link to={link} className="card-desc" component={RouterLink}>
                                {title}
                            </Link>
                        ) : (
                                <div className="card-desc" onClick={onClick}>{title}</div>
                            )
                    }
                </div>
            </div>
        </>
    );
}

export default ToolTile;
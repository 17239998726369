import React, { useState } from 'react';
import {
  FormControlLabel,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  Menu,
  InputLabel,
  Grid,
  FormControl,
} from '@material-ui/core';
import { Typography } from '@mui/material';

const vendorList = [
  'BRMS Breeding Core',
  'Charles River',
  'Envigo',
  'Covance',
  'Jackson',
  'Liberty',
  'SRTC Arlington',
  'Taconic',
  'Twin Valley',
  'NIA',
  'Xenopus1',
];

const PreferredVendorGroup = ({ key, sendInput, val, smallScreen }) => {
  const [isOther, setIsOther] = useState();
  let inputProps = {
    style: {
      display: 'none',
    },
  };
  return (
    <>
      <br></br>
      <Grid item xs>
        <FormControl style={{ width: 300, margin: 10 }} variant="outlined">
          <InputLabel>Preferred Vendor</InputLabel>
          <Select
            id="vendor-select"
            className="to-clear"
            key={key}
            defaultValue={
              val.vendor
                ? vendorList.includes(val.vendor)
                  ? val.vendor
                  : val.vendor === 'other'
                  ? 'other'
                  : 'none'
                : 'none'
            }
            label="Preferred Vendor"
            onChange={(e) => {
              setIsOther(e.target.value);
              if (e.target.value !== 'other') {
                sendInput('vendor', e.target.value);
              }
            }}
          >
            {vendorList ? vendorList.map((v) => <MenuItem value={v}>{v}</MenuItem>) : <></>}
            <MenuItem value="none">
              <Typography align="left">No preference</Typography>
            </MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {isOther === 'other' ? (
        <Grid item xs>
          <TextField
            style={{ width: 300, margin: 10 }}
            required={isOther === 'other'}
            variant="outlined"
            className="to-clear"
            label={isOther === 'other' ? 'Other Vendor Name' : ''}
            placeholder="Enter name"
            value={val.vendor ? (vendorList.includes(val.vendor) || val.vendor === 'none' ? null : val.vendor) : null}
            onChange={(e) => sendInput('vendor', e.target.value)}
          ></TextField>
        </Grid>
      ) : smallScreen ? (
        <></>
      ) : (
        <Grid item xs />
      )}
    </>
  );
};

export default PreferredVendorGroup;

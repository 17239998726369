import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import {
  Table,
  LinearProgress,
  TextField,
  Grid,
  Snackbar,
  Hidden,
  Card,
  CardContent,
  CardActions,
  Button,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Radio from '@material-ui/core/Radio';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import RadioGroup from '@material-ui/core/RadioGroup';
import Badge from '@material-ui/core/Badge';
import StatusIcon from '@material-ui/icons/FiberManualRecord';
import OverdueStatusIcon from '@material-ui/icons/PriorityHigh';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import StandardWrapper from './design/StandardWrapper';
import InventoryCart from './InventoryCart';
import moment from 'moment';
import { buildingCodeToName, capitalize } from '../utils';
import { KeyboardArrowLeft } from '@material-ui/icons';

const useInterval = (callback, delay) => {
  const savedCallback = React.useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    width: '100%',
    // flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { filterInput, onFilterInputChange, view } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: false,
      })}
    >
      <Grid container justifyContent="flex-end">
        <Grid item xs={12}>
          <Typography variant="h4" id="tableTitle" component="div">
            Fast Requests
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5} md={4}>
          {view !== 'day' && (
            <div style={{ minWidth: 300 }}>
              <TextField
                value={filterInput}
                onChange={(e) => onFilterInputChange(e.target.value)}
                placeholder="Filter"
              />
            </div>
          )}
        </Grid>
      </Grid>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    // minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const GET_FAST_REQUESTS = gql`
  query FastRequests($terms: String) {
    fastRequests(terms: $terms) {
      _id
      protocolNumber
      PIName
      number
      building
      animalId
      fastStart
      permittedPVIs
      status
      terminal
      procedureDate
      fastEnd
    }
  }
`;

const formatStatus = (status) => {
  if (status === 'awaitingReturn') {
    return 'Awaiting Return';
  }
  if (status === 'returnOverdue') {
    return 'Return Overdue';
  }
  return capitalize(status);
};

// { id: 'number', numeric: true, disablePadding: false, label: 'Order No.' },
// { id: 'building', numeric: false, disablePadding: false, label: 'Building' },
// { id: 'fastStartUnix', numeric: false, disablePadding: false, label: 'Date Submitted' },
// { id: 'fastEndString', numeric: false, disablePadding: false, label: 'Submitted By' },
// { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
const processRows = ({ data, filterInput, view }) => {
  if (!data || !data.fastRequests) {
    return [];
  }
  const { fastRequests } = data;
  if (!Array.isArray(fastRequests)) {
    console.error('Inventory Carts on provided data set is not an array');
    return [];
  }
  const keys = ['_id', 'number', 'PIName', 'building', 'fastStartUnix', 'animalId', 'status'];
  let res = fastRequests.map((x) => {
    const obj = {
      ...x,
    };

    if (!obj._id) {
      throw new Error(`Data object improperly formatted Obj: ${JSON.stringify(x)}`);
    }
    obj.status = formatStatus(obj.status);
    obj.building = buildingCodeToName(obj.building) || obj.building;
    obj.fastStartUnix = moment(obj.fastStart).format('MM/DD/YYYY h:mm a');
    obj.fastEndString = moment(obj.fastEnd).format('MM/DD/YYYY h:mm a');
    keys.forEach((key) => {
      if (!obj[key]) {
        obj[key] = '';
      }
    });
    return obj;
  });
  if (typeof filterInput === 'string') {
    res = res.filter((x) => {
      let query = filterInput.toLowerCase();
      for (let i = 0; i < keys.length; i++) {
        if (typeof x[keys[i]] === 'string' && x[keys[i]].toLowerCase().includes(query)) {
          return true;
        }
      }
      if (x.number && x.number.toString().includes(filterInput)) {
        return true;
      }
      return false;
    });
  }
  if (view === 'day') {
    res = res.filter((x) => {
      const tomorrowMoment = moment().tz('America/Chicago').add(1, 'day').hour(0).minute(0).second(0).millisecond(0);
      console.log('------------------------------------------------');
      console.log('x: ', x);

      // return true if overdue
      if (x.status === 'Overdue') {
        console.log('overdue');
        return true;
      }
      // return false if cancelled or returned already
      if (['Returned', 'Cancelled', 'Completed'].includes(x.status)) {
        console.log('cancelled, completed, or returned');
        return false;
      }
      // return true if fast start is today
      if (moment(x.fastStart).unix() < tomorrowMoment.unix()) {
        console.log('starts today');
        return true;
      }
      // return true if procedure date is today
      if (moment(x.procedureDate).unix() < tomorrowMoment.unix()) {
        console.log('procedure is today');
        return true;
      }
      // return true if return date is today
      if (!x.terminal && moment(x.fastEnd).unix() < tomorrowMoment.unix()) {
        console.log('return today');
        return true;
      }
      console.log('returning false');

      return false;
    });
  }
  return res;
};

const returnColor = '#488948';
const cancelColor = '#dadfe1';
const overdueColor = '#c5050b';
const inProgressColor = '#006a8a';

const getColor = (status) => {
  if (status === 'Returned' || status === 'Completed') {
    return returnColor;
  }
  if (status === 'Cancelled') {
    return cancelColor;
  }
  if (status === 'Overdue' || status === 'Return Overdue') {
    return overdueColor;
  }
  return inProgressColor;
};

const fullViewHeadCells = [
  { id: 'number', numeric: true, disablePadding: false, label: 'Request No.' },
  { id: 'PIName', numeric: true, disablePadding: false, label: 'PI' },
  { id: 'building', numeric: false, disablePadding: false, label: 'Building' },
  { id: 'animalId', numeric: false, disablePadding: false, label: 'Animal ID' },
  { id: 'fastStartUnix', numeric: false, disablePadding: false, label: 'Fast Start' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'statusIndicator', numeric: false, disablePadding: false, label: '' },
];

const dayViewHeadCells = [
  { id: 'PIName', numeric: true, disablePadding: false, label: 'PI' },
  { id: 'building', numeric: false, disablePadding: false, label: 'Building' },
  { id: 'animalId', numeric: false, disablePadding: false, label: 'Animal ID' },
  { id: 'fastStartUnix', numeric: false, disablePadding: false, label: 'Fast Start' },
  { id: 'statusIndicator', numeric: false, disablePadding: false, label: '' },
];

const getStoredView = () => {
  const view = localStorage.getItem('view');
  return view || 'full';
};

export default function FastRequestsTable({ user: { PVI } }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('number');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterInput, setFilterInput] = React.useState('');
  const [toast, setToast] = useState(false);
  const [view, setView] = useState(getStoredView());
  const [headCells, setHeadCells] = useState(getStoredView() === 'full' ? fullViewHeadCells : dayViewHeadCells);

  const { loading, error, data, refetch } = useQuery(GET_FAST_REQUESTS, {
    variables: { terms: JSON.stringify({}) },
  });

  useInterval(refetch, 5000);

  const rows = processRows({ data, filterInput, view });

  console.log('rows: ', rows);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeView = (e) => {
    const view = e.target.value;
    setView(view);
    window.localStorage.setItem('view', view);
    if (view === 'day') {
      setHeadCells(dayViewHeadCells);
    } else {
      setHeadCells(fullViewHeadCells);
    }
  };
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  if (loading) {
    return (
      <StandardWrapper>
        <LinearProgress />
      </StandardWrapper>
    );
  }

  const dayRows = processRows({ data, filterInput: '', view: 'day' });
  const toFast = [];
  const toReturn = [];
  dayRows.forEach((x) => {
    if (['Submitted', 'Overdue'].includes(x.status)) {
      toFast.push(x);
    } else {
      toReturn.push(x);
    }
  });
  return (
    <StandardWrapper>
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconButton aria-label="back to home" href="/">
            <KeyboardArrowLeft />
          </IconButton>
        </Grid>
        <Grid item>
          <RadioGroup aria-label="view" name="view" value={view} onChange={handleChangeView}>
            <Badge badgeContent={dayRows.length > 0 ? dayRows.length : null} color="primary">
              <FormControlLabel value="day" control={<Radio />} label="Day View" />
            </Badge>
            <FormControlLabel value="full" control={<Radio />} label="Full View" />
          </RadioGroup>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Grid container>
          <Grid item xs={12}>
            <EnhancedTableToolbar filterInput={filterInput} onFilterInputChange={setFilterInput} view={view} />
          </Grid>
          <Grid item xs={12}>
            {view === 'full' ? (
              <span>
                <TableContainer>
                  <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
                    <EnhancedTableHead
                      headCells={headCells}
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />
                    <TableBody>
                      {stableSort(rows, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              tabIndex={-1}
                              key={row._id}
                              onClick={
                                row.number
                                  ? () => {
                                      window.location.href = `/fast-requests/${row.number}`;
                                    }
                                  : null
                              }
                              style={row.number ? { cursor: 'pointer' } : null}
                            >
                              {view === 'full' && (
                                <TableCell component="th" id={labelId} scope="row" align="center">
                                  {row.number}
                                </TableCell>
                              )}
                              <TableCell align="left">{row.PIName}</TableCell>
                              <TableCell align="left">{row.building}</TableCell>
                              <TableCell align="left">{row.animalId}</TableCell>
                              <TableCell align="left">{row.fastStartUnix}</TableCell>
                              <TableCell align="left">{row.status}</TableCell>
                              <TableCell align="left">
                                {row.status.includes('Overdue') ? (
                                  <OverdueStatusIcon fontSize="small" style={{ color: getColor(row.status) }} />
                                ) : (
                                  <StatusIcon fontSize="small" style={{ color: getColor(row.status) }} />
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 33 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </span>
            ) : (
              // day view

              <TableContainer>
                <Typography variant="h5" style={{ marginTop: 50, textDecoration: 'underline', textAlign: 'center' }}>
                  Awaiting Fasting
                </Typography>
                {toFast.length > 0 ? (
                  <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
                    <EnhancedTableHead
                      headCells={headCells}
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={dayRows.length}
                    />
                    <TableBody>
                      {stableSort(toFast, getComparator(order, orderBy)).map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={row._id}
                            onClick={
                              row.number
                                ? () => {
                                    window.location.href = `/fast-requests/${row.number}`;
                                  }
                                : null
                            }
                            style={row.number ? { cursor: 'pointer' } : null}
                          >
                            <TableCell align="left">{row.PIName}</TableCell>
                            <TableCell align="left">{row.building}</TableCell>
                            <TableCell align="left">{row.animalId}</TableCell>
                            <TableCell align="left">{row.fastStartUnix}</TableCell>
                            <TableCell align="left">
                              {row.status.includes('Overdue') && (
                                <OverdueStatusIcon fontSize="small" style={{ color: getColor(row.status) }} />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                ) : (
                  <Typography style={{ textAlign: 'center', marginTop: 16 }}>No animals need to be fasted.</Typography>
                )}

                <Typography
                  variant="h5"
                  style={{ marginTop: 50, textDecoration: 'underline', textAlign: 'center', width: '100%' }}
                >
                  Awaiting Procedure or Return
                </Typography>
                {toReturn.length > 0 ? (
                  <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
                    <EnhancedTableHead
                      headCells={headCells}
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={dayRows.length}
                    />
                    <TableBody>
                      {stableSort(toReturn, getComparator(order, orderBy)).map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={row._id}
                            onClick={
                              row.number
                                ? () => {
                                    window.location.href = `/fast-requests/${row.number}`;
                                  }
                                : null
                            }
                            style={row.number ? { cursor: 'pointer' } : null}
                          >
                            <TableCell align="left">{row.PIName}</TableCell>
                            <TableCell align="left">{row.building}</TableCell>
                            <TableCell align="left">{row.animalId}</TableCell>
                            <TableCell align="left">{row.fastStartUnix}</TableCell>
                            <TableCell align="left">
                              {row.status.includes('Overdue') && (
                                <OverdueStatusIcon fontSize="small" style={{ color: getColor(row.status) }} />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 33 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                ) : (
                  <Typography style={{ textAlign: 'center', marginTop: 16 }}>
                    No animals need to be returned.
                  </Typography>
                )}
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid container></Grid>
        <Grid
          container
          direction="column"
          spacing={3}
          alignItems="center"
          justifyContent="center"
          style={{ paddingLeft: 25, paddingRight: 25 }}
        >
          <Grid item xs={12}>
            <EnhancedTableToolbar filterInput={filterInput} onFilterInputChange={setFilterInput} view={view} />
          </Grid>
          {stableSort(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <Grid item xs>
                  <Card
                    style={{ marginLeft: 25, marginRight: 25, width: 384 }}
                    className="fast-card"
                    variant="outlined"
                  >
                    <CardContent>
                      <Grid container direction="column" spacing={3}>
                        <Grid item align="left">
                          <strong>PI: </strong>
                          {row.PIName}
                        </Grid>
                        <Grid item align="left">
                          <strong>Building: </strong>
                          {row.building}
                        </Grid>
                        <Grid item align="left">
                          <strong>Animal ID: </strong>
                          {row.animalId}
                        </Grid>
                        <Grid item align="left">
                          <strong>Fast Start Date: </strong>
                          {row.fastStartUnix}
                        </Grid>
                        <Grid item align="left">
                          <strong>Status: </strong>
                          {row.status}
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Button
                        onClick={
                          row.number
                            ? () => {
                                window.location.href = `/fast-requests/${row.number}`;
                              }
                            : null
                        }
                        size="large"
                        color="secondary"
                      >
                        Details
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
        <br />
        <br />
      </Hidden>
      <Snackbar open={toast} autoHideDuration={6000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          {toast}
        </Alert>
      </Snackbar>
    </StandardWrapper>
  );
}

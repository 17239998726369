import { gql } from '@apollo/client';

import { CREATE_ERROR } from '../../global/graphql';

export { CREATE_ERROR };

export const GET_FACILITIES = gql`
  query facilities {
    facilities {
      _id
      name
      isBRMS
      rooms {
        _id
        number
      }
    }
  }
`;

export const GET_ROOMS = gql`
  query rooms {
    rooms {
      _id
      number
      building {
        _id
        name
      }
    }
  }
`;

export const GET_PROTOCOL_OPTIONS = gql`
  query protocolOptions($fragment: String) {
    protocolOptions(fragment: $fragment) {
      _id
      label
    }
  }
`;

export const SUBMIT_VCR = gql`
  mutation submitVCR($input: VCRInput) {
    submitVCR(input: $input)
  }
`;

export const SUBMIT_AND_SEPARATE = gql`
  mutation submitAndSeparate($input: VCRInput) {
    submitAndSeparate(input: $input)
  }
`;

export const GET_VCRS = gql`
  query vcrs($userId: ID!, $statuses: [String], $createdAt: [Int]) {
    vcrs(userId: $userId, statuses: $statuses, createdAt: $createdAt) {
      _id
      createdAt
      protocolLabel
      type
      reason
      rack
      additionalDescription
      submittedBy {
        _id
        nameLastFirst
      }
      protocol {
        members
      }
      staffResolutionRequestedAt
      staffResolutionRequester {
        _id
        nameLastFirst
      }
      resolvedAt
      resolvedBy {
        _id
        nameLastFirst
      }
      history {
        createdAt
        submittedBy {
          nameLastFirst
        }
        text
      }
      location
      reportNumber
      charged
      deadline
      status
      statusPriority
      timeRemaining
      roomNumber
      buildingName
    }
  }
`;

export const REQUEST_ACT_RESOLUTION = gql`
  mutation requestACTResolution($vcrId: ID!, $userId: ID!) {
    requestACTResolution(vcrId: $vcrId, userId: $userId)
  }
`;

export const MARK_RESOLVED = gql`
  mutation markResolved($vcrId: ID!, $userId: ID!, $charged: Boolean!) {
    markResolved(vcrId: $vcrId, userId: $userId, charged: $charged)
  }
`;

export const ADD_NOTE = gql`
  mutation addVCRNote($vcrId: ID!, $submittedBy: ID!, $text: String!) {
    addVCRNote(vcrId: $vcrId, submittedBy: $submittedBy, text: $text)
  }
`;

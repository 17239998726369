import React, { useState } from 'react';
import { Grid, TextField, Typography, Hidden, Button } from '@material-ui/core';
import UserSearch from '../inputs/UserSearch';

const CreateNotificationCondition = ({ onSubmit = console.log, canAddUser = false, validate = () => true }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [userText, setUserText] = useState();
  const [PVI, setPVI] = useState();
  const [key, setKey] = useState(false);

  const resetForm = () => {
    setEmailAddress('');
    setUserText('');
    setPVI('');
    setKey(!key);
  };

  const handleSelectUser = (user) => {
    const { PVI, emailAddress, firstName, lastName } = user;
    setPVI(PVI);
    setEmailAddress(emailAddress);
    setUserText(`${firstName} ${lastName}`);
  };

  const handleSubmit = () => {
    onSubmit({
      emailAddress,
      PVI,
    });
    resetForm();
  };

  const canSubmit = () => {
    if (!emailAddress) return false;
    if (!validate()) return false;
    return true;
  };

  return (
    <Grid container direction="column" justifyContent="flex-start">
      {canAddUser && (
        <Grid item>
          <UserSearch
            sendInput={(name, value) => setUserText(value)}
            name="userText"
            val={userText}
            key={key}
            onSelectUser={handleSelectUser}
            required={false}
            showPVI={false}
          />
        </Grid>
      )}
      <Grid item>
        <TextField
          variant="outlined"
          style={{ width: 300, margin: 10 }}
          required
          key={key}
          label="Email Address"
          type="email"
          value={emailAddress}
          onChange={(e) => {
            setEmailAddress(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          style={{ width: 300, margin: 10, height: 50, borderWidth: 2 }}
          variant="outlined"
          onClick={handleSubmit}
          disabled={!canSubmit()}
        >
          Add
        </Button>
      </Grid>
      {canAddUser && (
        <Typography variant="caption">
          Adding via user dropdown will grant the user permission to access this page.
        </Typography>
      )}
    </Grid>
  );
};

export default CreateNotificationCondition;

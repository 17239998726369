import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StandardWrapper from './design/StandardWrapper'

const useStyles = makeStyles((theme) => ({
  loginButton: {
    float: "right",
    marginRight: theme.spacing(2),
  },
  actionsRow: {
    width: '100%',
    minHeight: 20,
  },
  centeredText: {
    textAligh: "center"
  }
}));



const NotAuthenticated = () => {

  const { actionsRow } = useStyles();
  return (
    <StandardWrapper>
      <div className={actionsRow}>
      </div>
      <Container>
        <h1>
          You are not logged in.
            </h1>
        <Typography>
          Click <a href="/login">here</a> to sign in with your NetID.
            </Typography>
        <br /><br />
      </Container>
    </StandardWrapper>
  );
}

export default NotAuthenticated;
import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import StandardWrapper from './design/StandardWrapper';
import { LinearProgress } from '@material-ui/core';



const GET_NOTIFICATION_CONDITIONS = gql`
  query NotificationConditions($terms: String) {
    notificationConditions(terms: $terms) {
      _id
      emailAddress
      value
      user {
        _id
        PVI
        firstName
        lastName
      }
      event {
        name
        label
      }
      protocol {
        protocolNumber
        PI {
            firstName
            lastName
        }
      }
      building {
        _id
        name
        fpmCode
      }
    }
  }
`;

const DELETE_NOTIFICAITON_CONDITION = gql`
  mutation DelteNotificationCondition($_id: String, $_ids: [String]) {
    deleteNotificationCondition(_id: $_id, _ids: $_ids)
  }
`;

const CREATE_ERROR = gql`
  mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
    createError(PVI: $PVI, action: $action, error: $error, data: $data)
  }
`;


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'userDisplay',
        numeric: false,
        disablePadding: true,
        label: 'User',
    },
    {
        id: 'eventDisplay',
        numeric: false,
        disablePadding: false,
        label: 'Event',
    },
    {
        id: 'protocolDisplay',
        numeric: false,
        disablePadding: false,
        label: 'Protocol',
    },
    {
        id: 'buildingDisplay',
        numeric: false,
        disablePadding: false,
        label: 'Building',
    },
    {
        id: 'species',
        numeric: false,
        disablePadding: false,
        label: 'Species',
    },
    {
        id: 'emailAddress',
        numeric: false,
        disablePadding: false,
        label: 'Email Address',
    },
    {
        id: 'phoneNumber',
        numeric: false,
        disablePadding: false,
        label: 'Phone Number',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const { numSelected, onDelete, allowDelete } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Custom Notification Conditions
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete" onClick={onDelete} disabled={!allowDelete}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
            {/* </Tooltip> */}
            <Tooltip title="Add Notification">
                <IconButton aria-label="add notification" onClick={() => { window.location.href = 'custom-notification-form' }}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const getRows = (data = []) => {
    console.log("data: ", data)

    return data.map(nc => {
        const row = { ...nc };
        if (nc.user) {
            if (nc.user.firstName && nc.user.lastName) {
                row.userDisplay = `${nc.user.firstName} ${nc.user.lastName}`
            } else {
                row.userDisplay = nc.user.PVI
            }
        }
        if (nc.event) {
            row.eventDisplay = nc.event.label
        } else {
            row.eventDisplay = "Any"
        }
        if (nc.protocol) {
            row.protocolDisplay = nc.protocol.protocolNumber
        } else {
            row.protocolDisplay = "Any"
        }
        if (nc.building) {
            row.buildingDisplay = `${nc.building.fpmCode}: ${nc.building.name}`
        } else {
            row.buildingDisplay = "Any"
        }
        return row
    })
}

export default function EnhancedTable({ user }) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [rows, setRows] = React.useState([])

    const [createError] = useMutation(CREATE_ERROR);

    const { loading, error, data, refetch } = useQuery(GET_NOTIFICATION_CONDITIONS, {
        variables: { terms: JSON.stringify({ autoGenerated: false }) },
    });

    console.log("rows: ", rows)

    React.useEffect(() => {
        if (!loading) {
            setRows(getRows(data?.notificationConditions))
        }
    }, [loading, data])



    const [deleteNotificationCondition] = useMutation(DELETE_NOTIFICAITON_CONDITION, {
        onCompleted: () => {
            setSelected([]);
            refetch();
        },
        onError: (e) => {
            createError({
                variables: {
                    PVI: user.PVI,
                    action: 'deleteNotificationCondition',
                    error: e ? JSON.stringify(e) : undefined,
                    data: JSON.stringify({
                        user,
                        selected,
                        rows,
                    }),
                },
            });
        },
    });

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, rows],
    );

    const handleDelete = () => {
        deleteNotificationCondition({ variables: { _ids: selected } });
    };


    const getTotalCount = (data) => {
        if (!data || !Array.isArray(data.notificationConditions)) {
            return 0;
        }
        return data.notificationConditions.length;
    };

    return (
        <StandardWrapper>
            <EnhancedTableToolbar
                numSelected={selected.length}
                onDelete={handleDelete}
                allowDelete={selected.length > 0 && selected.length < getTotalCount(data)}
            />
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={'small'}
                >
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                    />
                    {loading ? <LinearProgress /> : (
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const isItemSelected = isSelected(row._id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        key={row._id}
                                        hover
                                        onClick={(event) => handleClick(event, row._id)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                        >
                                            {row.userDisplay}
                                        </TableCell>
                                        <TableCell align="right">{row.eventDisplay}</TableCell>
                                        <TableCell align="right">{row.protocolDisplay}</TableCell>
                                        <TableCell align="right">{row.buildingDisplay}</TableCell>
                                        <TableCell align="right">{row.species}</TableCell>
                                        <TableCell align="right">{row.emailAddress}</TableCell>
                                        <TableCell align="right">{row.phoneNumber}</TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>

                    )}
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </StandardWrapper>
    );
}
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useMutation } from '@apollo/client';
import Divider from '@material-ui/core/Divider';
import { ListItemSecondaryAction, CircularProgress } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import StandardWrapper from './design/StandardWrapper';
import { buildingCodeToName, buildingNameToCode, inventoryItemsMatch } from '../utils';
import { gql } from '@apollo/client';
import { KeyboardArrowLeft } from '@material-ui/icons';
import InventoryBuildingSearch from './inputs/InventoryBuildingSearch';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
    marginTop: 0,
  },
}));

const formatNumber = (x) => {
  if (!x) return null;
  if (typeof x === 'number') return x;
  if (typeof x === 'string') return parseInt(x);
};

const CREATE_ERROR = gql`
  mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
    createError(PVI: $PVI, action: $action, error: $error, data: $data)
  }
`;

const REMOVE_ITEM_FROM_CART = gql`
  mutation ($item: InputInventoryItem!) {
    removeInventoryItemFromCart(item: $item) {
      _id
    }
  }
`;

const UPDATE_CART = gql`
  mutation ($items: [InputCartItem]!) {
    updateInventoryItemQuantitiesInCart(items: $items) {
      _id
    }
  }
`;

const SUBMIT_CART = gql`
  mutation ($building: String!) {
    submitInventoryCart(building: $building) {
      _id
    }
  }
`;

export default function InventoryCart({ cart, refetch, user, onClose, onSubmit }) {
  const { items } = cart;
  const classes = useStyles();
  const [updatedItems, setUpdatedItems] = React.useState([]);
  const [toast, setToast] = useState();
  const [errorDialog, setErrorDialog] = useState();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [building, setBuilding] = useState();
  const [key, setKey] = useState(true);

  const [createError] = useMutation(CREATE_ERROR);

  const [removeInventoryItemFromCart] = useMutation(REMOVE_ITEM_FROM_CART, {
    onError(e) {
      console.log('e: ', e);

      setLoading(false);
      setErrorDialog(true);
      setToast(false);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'removeInventoryItemFromCart',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        refetch();
      } else setToast(false);
    },
  });

  const [updateCart] = useMutation(UPDATE_CART, {
    onError(e) {
      console.log('e: ', e);

      setLoading(false);
      setErrorDialog(true);
      setToast(false);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'updateCartQuantities',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            updatedItems,
            cart,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        refetch();
      } else setToast(false);
    },
  });

  const [submitCart, { loading: updateCartLoading }] = useMutation(SUBMIT_CART, {
    onError(e) {
      console.log('e: ', e);

      setLoading(false);
      setErrorDialog(true);
      setToast(false);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'submitCart',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            updatedItems,
            cart,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        refetch();
        onSubmit();
      } else setToast(false);
    },
  });

  const handleDelete = (item) => {
    let _item = { ...item };
    delete _item.__typename;
    removeInventoryItemFromCart({ variables: { item: _item } });
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToast(false);
    setErrorDialog(false);
  };

  const handleChange = ({ item, quantity }) => {
    let updated = false;
    let _updatedItems = [...updatedItems];
    _updatedItems = _updatedItems.map((x) => {
      const { item: _item } = x;
      if (inventoryItemsMatch(item, _item)) {
        updated = true;
        return { item, quantity: formatNumber(quantity) };
      }
      return x;
    });
    if (!updated) {
      _updatedItems.push({ item, quantity: formatNumber(quantity) });
    }
    const res = _updatedItems.map((x) => {
      let _item = { ...x.item };
      delete _item.__typename;
      return {
        item: _item,
        quantity: x.quantity,
      };
    });
    setUpdatedItems(res);
    if (typeof formatNumber(quantity) === 'number' && quantity > 0 && quantity < 1000000000) {
      handleUpdate(res);
    }
  };

  const handleUpdate = (res) => {
    updateCart({
      variables: {
        items: res.map((x) => {
          if (!x.quantity) return { ...x, quantity: 0 };
          return x;
        }),
      },
    });
  };

  const handleSubmit = () => {
    console.log("building: ", building)

    submitCart({ variables: { building: buildingNameToCode(building) || building } });
  };

  return (
    <StandardWrapper className={classes.root}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconButton
            aria-label="back to item selection"
            onClick={() => {
              window.location.href = '/order-inventory';
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h5" className={classes.title}>
          Your Shopping Cart
        </Typography>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            // sleep(500);
            handleSubmit();
          }}
        >
          <div className={classes.demo}>
            {items.length > 0 ? (
              <List>
                <Divider />
                {items.map(({ item, quantity }) => {
                  let _quantity;
                  let quantitySet = false;
                  updatedItems.forEach((x) => {
                    if (inventoryItemsMatch(item, x.item)) {
                      console.log('x: ', x);
                      _quantity = x.quantity;
                      quantitySet = true;
                    }
                  });
                  if (!quantitySet) {
                    _quantity = quantity;
                  }
                  console.log('_quantity: ', _quantity);

                  const { name, unit, vendor, descripiton, code, _id } = item;
                  return (
                    <span key={name + unit + vendor + code + descripiton + _id}>
                      <ListItem>
                        <ListItemText
                          primary={name}
                          secondary={descripiton}
                          style={{ width: 300, textOverflow: 'ellipsis' }}
                        />
                        <Grid container justifyContent="flex-start">
                          <Grid item>
                            <TextField
                              variant="outlined"
                              style={{ width: 150, margin: 10 }}
                              required
                              label="Quantity"
                              placeholder="Enter a number"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              inputProps={{
                                min: 1,
                                max: 1000000000,
                              }}
                              value={_quantity}
                              onChange={(e) => {
                                handleChange({ item, quantity: e.target.value });
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <div style={{ paddingTop: 40 }}>
                              <Typography style={{ textAlign: 'left', textOverflow: 'ellipsis' }}>
                                x&nbsp;{unit}
                              </Typography>
                            </div>
                          </Grid>
                        </Grid>
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                              handleDelete(item);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </span>
                  );
                })}
              </List>
            ) : (
              <Typography variant="h5">You Have No Items In Your Cart</Typography>
            )}
          </div>
          <Grid container justifyContent="space-between" style={{ marginTop: 16 }}>
            <Grid item>
              <InventoryBuildingSearch
                key={key}
                val={building}
                sendInput={(x, value) => {
                  setBuilding(value);
                }}
                label="Building"
                required
              />
            </Grid>
            <Grid item>
              <Button
                style={{ margin: 18 }}
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading || !building || items.length < 1 || updateCartLoading}
              >
                Submit
                {loading && (
                  <CircularProgress
                    disableShrink
                    style={{ position: 'absolute', zIndex: 2, color: 'white' }}
                    size={24}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <Dialog open={errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Failed to update item</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </StandardWrapper>
  );
}

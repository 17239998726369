import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ALL_BUILDINGS from '../../constants/buildings'

const BuildingSearch = ({ key, name, sendInput, val, required = true, label, freeSolo = false }) => {
    const handleSelect = (value) => sendInput(name, value);

    return (
        <Autocomplete
            className="to-clear"
            key={key}
            freeSolo={freeSolo}
            options={ALL_BUILDINGS.map(x => x.name)}
            style={{ width: 300, display: "inline-block", margin: 10 }}
            value={val}
            onChange={(e, value) => handleSelect(value)}
            renderInput={params => {
                return (
                    <TextField
                        {...params}
                        required={required}
                        className="to-clear"
                        placeholder="Enter building"
                        label={label || "Building"}
                        variant="outlined"
                        value={val}
                        onChange={freeSolo ? (e, value) => handleSelect(e.target.value) : console.log}
                    />
                )
            }}
        />
    );
}


export default BuildingSearch
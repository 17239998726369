/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Autocomplete, createFilterOptions, FilterOptionsState } from '@material-ui/lab';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Error } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import { isAuthError } from '../../utils';
import { Redirect, useLocation } from 'react-router-dom';

const GET_ALL_PROTOCOL_OUTLINES = gql`
{
	allProtocolOutlines {
    _id
    protocolNumber
    label
    PIPVI
    buildings
    species
  }
}
`
const loadingProps = {
    startAdornment: (
        <InputAdornment position="start">
            <CircularProgress />
        </InputAdornment>
    ),
};

const errorProps = {
    startAdornment: (
        <InputAdornment position="start">
            <Error />
        </InputAdornment>
    ),
};

const filterOptions = createFilterOptions({
    limit: 5,
});

const ProtocolSearch = ({ key, name, val = "", onSelectProtocol, sendInput = console.log, required = true }) => {
    const [protocols, setProtocols] = useState();
    const { loading, error, data } = useQuery(GET_ALL_PROTOCOL_OUTLINES);
    const location = useLocation();
    if (!loading && data && !protocols) {
        const { allProtocolOutlines } = data
        if (Array.isArray(allProtocolOutlines) && allProtocolOutlines.length > 0) {
            setProtocols(allProtocolOutlines)
        }
    } else if (error) {
        if (isAuthError(error)) {
            window.location.href = '/login'
        }
        console.error('Error fetching protocols in ProtocolSearch Component')
        console.error(error)
        // throw error
    }

    const handleSelect = (e, value) => {
        if (value) {
            sendInput(name, value.protocolNumber);
        }
        onSelectProtocol(value);
    }

    return (
        <Autocomplete
            className="to-clear"
            key={key}
            id="protocol-outline-search"
            filterOptions={filterOptions}
            options={protocols ? protocols : []}
            getOptionLabel={option => option.label}
            style={{ width: 300, display: "inline-block", margin: 10 }}
            onChange={handleSelect}
            renderInput={params => {
                // if (params.inputProps) {
                //     params.inputProps.value = val
                // }
                return protocols ? (
                    <TextField
                        {...params}
                        required={required}
                        className="to-clear"
                        label="Protocol"
                        variant="outlined"
                        placeholder="Enter protocol"
                        value={val}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) //Enter
                                e.preventDefault();
                        }}
                    />
                ) : (
                    <TextField
                        {...params}
                        required
                        className="to-clear"
                        label="Protocol"
                        variant="outlined"
                        placeholder="Enter protocol"
                        InputProps={loading ? loadingProps : errorProps}
                        value={val}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) //Enter
                                e.preventDefault();
                        }}
                    />
                )
            }}
        />
    );
}

export default ProtocolSearch;
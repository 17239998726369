import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import FormDivider from '../design/FormDivider';

const TransferContainerSection = ({ sendInput, strainRequired, width = 300 }) => {
  return (
    <>
      <Grid container>
        <Grid item xs>
          <TextField
            required
            style={{ width: width, margin: 10 }}
            label="Number of Cages / Tanks"
            type="number"
            onWheel={(e) => e.target.blur()}
            variant="outlined"
            onChange={(e) => sendInput('numCages', parseInt(e.target.value))}
          ></TextField>
        </Grid>
        <Grid item xs>
          <TextField
            required={strainRequired}
            variant="outlined"
            style={{ width: width, margin: 10 }}
            label="Strain"
            placeholder="Enter strain"
            onChange={(e) => sendInput('strain', e.target.value)}
          ></TextField>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <TextField
            required
            style={{ width: width, margin: 10 }}
            label="Total Males"
            type="number"
            onWheel={(e) => e.target.blur()}
            variant="outlined"
            onChange={(e) => sendInput('numMales', parseInt(e.target.value))}
          ></TextField>
        </Grid>
        <Grid item xs>
          <TextField
            required
            style={{ width: width, margin: 10 }}
            label="Total Females"
            type="number"
            onWheel={(e) => e.target.blur()}
            variant="outlined"
            onChange={(e) => sendInput('numFemales', parseInt(e.target.value))}
          ></TextField>
        </Grid>
      </Grid>
    </>
  );
};

export default TransferContainerSection;

import React, { useState } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';

const SelectInput = ({ menu, label, required, onChange, val, width = 300 }) => {
  return (
    <FormControl variant="outlined" style={{ width: width, margin: 10 }} required={required}>
      <InputLabel>{label}</InputLabel>
      <Select onChange={onChange} label={label} value={val} required={required} style={{ textAlign: 'left' }}>
        {menu.map((item, index) => (
          <MenuItem value={item.value} key={index} style={{ justifyContent: 'left' }}>
            {item.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;

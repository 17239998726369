import React, { useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  IconButton,
  Chip,
  Select,
  MenuItem,
  Hidden,
  Typography,
  Divider,
} from '@material-ui/core';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import StandardWrapper from '../design/StandardWrapper';
import moment from 'moment-timezone';
import { KeyboardArrowLeft } from '@material-ui/icons';
import _ from 'lodash';

const APIKeyForm = ({ user }) => {
  const [expiresInQuantity, setExpiresInQuantity] = useState();
  const [expiresInUnit, setExpiresInUnit] = useState('years');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [groups, setGroups] = useState([]);
  const [groupText, setGroupText] = useState('');
  const [PVI, setPVI] = useState();
  const [apiKey, setAPIKey] = useState();

  const [errorDialog, setErrorDialog] = useState();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState();
  const [key, setKey] = useState(false);
  const [validationError, setValidationError] = useState('');

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast();
    setErrorDialog(false);
  };

  const resetForm = () => {
    setExpiresInQuantity();
    setExpiresInUnit('years');
    setName('');
    setDescription('');
    setGroups([]);
    setGroupText('');
    setPVI();
    setKey(!key);
  };

  const CREATE_ERROR = gql`
    mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
      createError(PVI: $PVI, action: $action, error: $error, data: $data)
    }
  `;
  const [createError] = useMutation(CREATE_ERROR);

  const CREATE_API_KEY = gql`
    mutation name($expiresIn: String!, $name: String!, $description: String!, $groups: [String], $PVI: String) {
      createAPIKey(expiresIn: $expiresIn, name: $name, description: $description, groups: $groups, PVI: $PVI) {
        key
      }
    }
  `;

  const validate = () => {
    if (!PVI && groups.length <= 0) {
      setValidationError('Must include either groups, PVI, or both.');
      return false;
    }
    switch (expiresInUnit) {
      case 'days': {
        if (expiresInQuantity > 730) {
          setValidationError('Expiration cannot be longer than two years');
          return false;
        }
        break;
      }
      case 'years': {
        if (expiresInQuantity > 2) {
          setValidationError('Expiration cannot be longer than two years');
          return false;
        }
        break;
      }
      default: {
        setValidationError('Must select valid duration unit');
        return false;
      }
    }

    return true;
  };

  const getVariables = () => {
    return {
      expiresIn: `${expiresInQuantity} ${expiresInUnit}`,
      name,
      description,
      groups,
      PVI,
    };
  };

  const [createAPIKey] = useMutation(CREATE_API_KEY, {
    onError(e) {
      setLoading(false);
      setErrorDialog(true);
      setToast();
      createError({
        variables: {
          PVI: user.PVI,
          action: 'createAPIKey',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            vars: getVariables(),
          }),
        },
      });
    },
    onCompleted(res) {
      setAPIKey(res.createAPIKey.key);
      if (!errorDialog) {
        setLoading(false);
        setToast('API Key Created!');
        resetForm();
      } else setToast();
    },
  });

  const handleSubmit = () => {
    const variables = getVariables();
    console.log('variables: ', variables);
    createAPIKey({ variables });
  };

  const getMax = () => {
    switch (expiresInUnit) {
      case 'days': {
        return 730;
      }
      case 'years': {
        return 2;
      }
      default:
        setValidationError('Must select valid duration unit');
    }
  };

  const handleCopyKey = () => {
    navigator.clipboard.writeText(apiKey);
    setToast('API Key Copied to Clipboard!');
  };

  return (
    <StandardWrapper>
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconButton aria-label="back to home" href="/">
            <KeyboardArrowLeft />
          </IconButton>
        </Grid>
      </Grid>
      {apiKey ? (
        <>
          <Grid item xs={12}>
            <Typography variant="h4">Your API Key:</Typography>
            <Button style={{ margin: 10, width: 300 }} variant="contained" color="primary" onClick={handleCopyKey}>
              Copy key to Clipboard
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button style={{ margin: 10, width: 300 }} variant="contained" onClick={() => setAPIKey()}>
              Close
            </Button>
          </Grid>

          <Snackbar open={!!toast} autoHideDuration={6000} onClose={handleToastClose}>
            <Alert onClose={handleToastClose} variant="filled" severity="success">
              {toast}
            </Alert>
          </Snackbar>
        </>
      ) : (
        <>
          <Grid container justifyContent="center">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (validate()) {
                  setLoading(true);
                  handleSubmit();
                }
              }}
            >
              <Grid item xs={12}>
                <h1>New API Key</h1>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item>
                  <TextField
                    required
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    InputProps={{ inputProps: { min: 1, max: getMax() } }}
                    key={key}
                    variant="outlined"
                    style={{ width: 140, margin: 10 }}
                    label="Duration length"
                    value={expiresInQuantity}
                    onChange={(e) => setExpiresInQuantity(parseInt(e.target.value))}
                  />
                  <FormControl style={{ width: 140, margin: 10 }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={expiresInUnit}
                      onChange={(e) => {
                        setExpiresInUnit(e.target.value);
                      }}
                      variant="outlined"
                    >
                      <MenuItem value={'years'}>Years</MenuItem>
                      <MenuItem value={'days'}>Days</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <TextField
                    variant="outlined"
                    style={{ width: 300, margin: 10 }}
                    required
                    key={key}
                    label="Key Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container justifyContent="center">
                <Grid item>
                  <TextField
                    variant="outlined"
                    style={{ width: 300, margin: 10 }}
                    required={groups.length < 1}
                    key={key}
                    label="Group"
                    value={groupText}
                    onChange={(e) => {
                      setGroupText(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    style={{ marginTop: 18, width: 175 }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      let arr = [];
                      if (Array.isArray(groups)) {
                        arr = [...groups];
                      }
                      setGroups(_.uniq([...arr, groupText]));
                      setGroupText('');
                    }}
                    disabled={groupText < 1}
                  >
                    Add Group
                  </Button>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                {groups.length > 0 && (
                  <Grid item>
                    <Typography variant="h6">Bearer Belongs to These Groups</Typography>
                    {groups.map((group) => (
                      <Chip
                        key={group + 'group'}
                        label={group}
                        onDelete={(e) => {
                          setGroups(groups.filter((x) => x !== group));
                        }}
                      />
                    ))}
                    <Divider style={{ marginTop: 10, marginBottom: 20 }} />
                  </Grid>
                )}
              </Grid>

              <Hidden xsDown>
                <Grid container>
                  <Grid item>
                    <TextField
                      variant="outlined"
                      key={key}
                      required
                      label="Description"
                      multiline
                      fullWidth
                      rows={3}
                      rowsMax={6}
                      value={description}
                      style={{ margin: 10, width: 620 }}
                      onChange={(e) => setDescription(e.target.value)}
                    ></TextField>
                  </Grid>
                </Grid>

                <Grid container justifyContent="flex-end">
                  <Grid item style={{ marginTop: 16 }}>
                    <Button
                      style={{ width: 125, margin: 10 }}
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={loading}
                    >
                      Submit
                      {loading && (
                        <CircularProgress
                          disableShrink
                          style={{ position: 'absolute', zIndex: 2, color: 'white' }}
                          size={24}
                        />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid item>
                  <TextField
                    variant="outlined"
                    required
                    key={key}
                    label="Description"
                    multiline
                    fullWidth
                    rows={3}
                    rowsMax={6}
                    value={description}
                    style={{ margin: 10, width: 300 }}
                    onChange={(e) => setDescription(e.target.value)}
                  ></TextField>
                </Grid>

                <Grid item>
                  <Button
                    style={{ margin: 10, width: 300 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}
                  >
                    Submit
                    {loading && (
                      <CircularProgress
                        disableShrink
                        style={{ position: 'absolute', zIndex: 2, color: 'white' }}
                        size={24}
                      />
                    )}
                  </Button>
                </Grid>
              </Hidden>
            </form>
          </Grid>
          <Snackbar open={!!toast} autoHideDuration={6000} onClose={handleToastClose}>
            <Alert onClose={handleToastClose} variant="filled" severity="success">
              <AlertTitle>Success</AlertTitle>
              {toast}
            </Alert>
          </Snackbar>
          <Dialog open={!!errorDialog} onClose={handleToastClose}>
            <DialogTitle>{'Error'}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Failed to create API Key. The BRMS IT office has been alerted of this issue.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleToastClose} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={!!validationError}
            onClose={() => {
              setValidationError();
            }}
          >
            <DialogTitle>{'Invalid Input'}</DialogTitle>
            <DialogContent>
              <DialogContentText>{validationError}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setValidationError();
                }}
                color="primary"
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </StandardWrapper>
  );
};

export default APIKeyForm;

import React from 'react';
import MessageForm from './MessageForm'
import MessageList from './MessageList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Divider } from '@material-ui/core';

const Messenger = ({
    onSubmit,
    validate,
    label,
    rows,
    defaultValue,
    placeholder,
    buttonText,
    messages,
    title
}) => {

    return (
        <div style={{ marginTop: 30 }}>
            <MessageForm
                onSubmit={onSubmit}
                validate={validate}
                label={label}
                rows={rows}
                defaultValue={defaultValue}
                placeholder={placeholder}
                buttonText={buttonText}
            />

            <Accordion style={{ marginTop: 30 }} defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                >
                    <Typography>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <MessageList messages={messages} />
                </AccordionDetails>
                <Divider />
            </Accordion>


        </div>
    );
}

export default Messenger;

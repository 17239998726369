import React, { useState, useEffect } from 'react';
import { TextField, Grid } from '@material-ui/core';
import PhoneInput from './PhoneInput';

const ContactGroup = ({ userInfo, sendInput, breedingCore, val }) => {

    const iprops = {
        inputProps: {
            pattern: ".{13}"
        }
    }

    const [isBreedingCore, setIsBreedingCore] = useState(breedingCore ? breedingCore : false);

    useEffect(() => {
        setIsBreedingCore(breedingCore);
    }, [breedingCore]);

    return (
        <>
            <Grid container>
                <Grid item xs>
                    <TextField
                        required
                        style={{ width: 300, marginTop: 10, margin: 10 }}
                        variant="outlined"
                        className="to-clear"
                        name="receivingContactName"
                        value={val.receivingContactName || userInfo.firstName + ' ' + userInfo.lastName}
                        label="Contact Name"
                        placeholder="Enter name"
                        onChange={e => sendInput(e.target.name, e.target.value)}
                    ></TextField>
                </Grid>
                <Grid item xs>
                    <PhoneInput
                        isRequired={true}
                        name="receivingContactPhone"
                        label="Contact Phone"
                        sendInput={sendInput}
                        val={val.receivingContactPhone || null}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs>
                    <TextField
                        required
                        style={{ width: 300, margin: 10 }}
                        variant="outlined"
                        name="receivingContactEmail"
                        className="to-clear"
                        label="Contact Email"
                        value={"receivingContactEmail" in val ? val.receivingContactEmail : userInfo.emailAddress}
                        placeholder="Enter email"
                        type="email"
                        onChange={e => sendInput(e.target.name, e.target.value)}
                    ></TextField>
                </Grid>
                <Grid item xs>
                    {
                        !isBreedingCore && <PhoneInput
                            isRequired={true}
                            name="receivingContactEmergencyPhone"
                            label="Emergency Contact Phone"
                            sendInput={sendInput}
                            val={val.receivingContactEmergencyPhone || null}
                        />
                    }
                </Grid>
            </Grid>
        </>
    );
}

export default ContactGroup;
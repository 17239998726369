import React from 'react';
import { Redirect } from 'react-router-dom';
import { LinearProgress, Grid, Paper } from '@material-ui/core';
import { getLoginUrl } from '../utils';
const Login = ({ pathname }) => {
  // store pathname as state and props will be lost when redirected to netidIDP
  if (pathname) {
    localStorage.setItem('pathname', pathname);
  }
  window.location = getLoginUrl();
  return (
    <Grid container id="body-wrapper" justifyContent="center">
      <Grid item xs={12} md={10} lg={9} xl={6}>
        <Paper
          style={{
            // display: useMargins ? "inline-block" : "block",
            paddingLeft: '5%',
            paddingRight: '5%',
            paddingBottom: 16,
            marginBottom: 25,
            marginTop: 10,
            paddingTop: 32,
            marginLeft: 0,
            marginRight: 0,
          }}
          elevation={2}
        >
          <LinearProgress />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Login;

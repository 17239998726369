import { useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { GET_SPECIES } from './graphql';
import { QUERY_RESOURCES } from '../ResourceScheduler/graphql';

import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material/';
import DeleteIcon from '@mui/icons-material/Delete';

export default function EditRoomForm({
  building,
  room,
  closeForm,
  addRoom,
  updateRoom,
  deleteRoom,
  userIsFac,
  userIsAdmin,
}) {
  /* ----------------------------------------- */
  /* STATE                                     */
  /* ----------------------------------------- */

  const addingRoom = !room.number;
  const [roomData, setRoomData] = useState({
    _id: room._id,
    building: building._id,
    buildingName: building.name,
    floor: room.floor,
    number: room.number,
    type: room.type || '',
    species: room.species?.map((s) => {
      return { id: s._id, label: s.name };
    }),
    resources: room.resources?.map((r) => {
      return { id: r._id, label: r.resourceName };
    }),
    hazards: room.hazards,
    notes: room.notes,
  });

  const [viewMode, setViewMode] = useState(!addingRoom);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const roomTypes = [
    '-',
    'Animal Housing',
    'Procedure',
    'Storage',
    'Behavioral',
    'Hallway',
    'Quarantine',
    'Empty',
    'Cage Wash',
    'Autoclave',
    'Imaging',
  ];

  /* ----------------------------------------------- */
  /* BACKEND CALLS                                   */
  /* ----------------------------------------------- */
  // Get species data to populate Select options
  const [querySpecies, { data: speciesData }] = useLazyQuery(GET_SPECIES);
  const speciesOptions = speciesData?.species?.map((s) => ({ id: s._id, label: s.name })) || [];

  // Get resource data to populate Select options
  const [queryResources, { data: resourceData }] = useLazyQuery(QUERY_RESOURCES);
  const resourceOptions = resourceData?.resources?.map((r) => ({ id: r._id, label: r.resourceName })) || [];

  // Consolidate building's room's resources to populate Autocomplete options
  let resourceList = [];
  building.rooms?.forEach(
    (room) =>
      (resourceList = room.resources
        ?.map((r) => {
          return { id: r._id, label: r.resourceName };
        })
        ?.concat(resourceList))
  );

  /* ------------------------------------------ */
  /* HANDLERS                                   */
  /* ------------------------------------------ */

  const handleSubmit = (e) => {
    e.preventDefault();

    const input = {
      ...roomData,
      species: roomData.species?.map((s) => s.id),
      resources: roomData.resources?.map((r) => r.id),
      type: roomData.type === '-' ? null : roomData.type,
    };
    delete input.buildingName;

    addingRoom ? addRoom({ variables: { input } }) : updateRoom({ variables: { input } });
  };

  /* ----------------------------------------- */
  /*  RENDER                                   */
  /* ----------------------------------------- */

  return (
    <>
      {viewMode ? (
        <>
          <Stack spacing={2} padding={2}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '2em',
                paddingInline: '1em',
              }}
            >
              <Typography variant="h6">{`Details for ${building.name} - ${room.number}`} </Typography>
            </Box>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {['number', 'floor', 'type', 'species', 'resources', 'hazards', 'notes'].map((e, i) => (
                    <TableRow key={i}>
                      <TableCell sx={{ fontWeight: '600' }}>
                        {e.replace(/\b\w/g, (match) => match.toUpperCase())}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'pre-line' }}>
                        {Array.isArray(room[e])
                          ? room[e].map((element) => element.name || element.resourceName).join(',\n')
                          : room[e]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <Button variant="outlined" onClick={closeForm}>
                  Close
                </Button>
                {(userIsAdmin || userIsFac) && (
                  <Button variant="contained" type="submit" onClick={() => setViewMode((x) => !x)}>
                    Edit
                  </Button>
                )}
              </Box>
            </Box>
          </Stack>
        </>
      ) : (
        <form onSubmit={(data) => handleSubmit(data)}>
          <Stack spacing={2} padding={2}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '2em' }}>
              <Typography variant="h5">
                {addingRoom ? `Add room to ${building.name}` : `${building.name} - ${room.number}`}
              </Typography>
              {!addingRoom && (
                <IconButton onClick={() => setDeleteDialogOpen(true)} color="primary" disabled={viewMode}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>

            <TextField
              label="Room Number"
              value={roomData.number || ''}
              onChange={(e) => setRoomData({ ...roomData, number: e.target.value })}
              required
              fullWidth
              disabled={!addingRoom}
            />

            <TextField
              label="Floor"
              value={roomData.floor || ''}
              onChange={(e) => setRoomData({ ...roomData, floor: e.target.value })}
              required
              fullWidth
            />

            <FormControl fullWidth>
              <InputLabel>Room type</InputLabel>
              <Select
                value={roomData.type}
                label="Room type"
                onChange={(e) => {
                  setRoomData({ ...roomData, type: e.target.value });
                }}
                fullWidth
              >
                {roomTypes.map((type, i) => (
                  <MenuItem key={i} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Autocomplete
              value={roomData.species}
              renderInput={(params) => <TextField {...params} label="Species" />}
              onChange={(e, v) => {
                setRoomData({ ...roomData, species: v });
              }}
              onFocus={querySpecies}
              options={speciesOptions}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => <Chip key={index} label={option.label} {...getTagProps({ index })} />)
              }
              multiple
            />

            <Autocomplete
              value={roomData.resources}
              renderInput={(params) => <TextField {...params} label="Resources" />}
              onChange={(e, v) => {
                setRoomData({ ...roomData, resources: v });
              }}
              onFocus={queryResources}
              options={resourceOptions || 'Loading...'}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => <Chip key={index} label={option.label} {...getTagProps({ index })} />)
              }
              multiple
            />

            <TextField
              label="Hazards"
              placeholder="Hazard notes"
              rows={2}
              value={roomData.hazards || ''}
              onChange={(e) => setRoomData({ ...roomData, hazards: e.target.value })}
            />

            <TextField
              label="Notes"
              placeholder="General notes"
              rows={2}
              value={roomData.notes || ''}
              onChange={(e) => setRoomData({ ...roomData, notes: e.target.value })}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <Button variant="outlined" onClick={closeForm}>
                  Close
                </Button>
                <Button variant="contained" type="submit" disabled={viewMode}>
                  Save
                </Button>
              </Box>
            </Box>
          </Stack>
        </form>
      )}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <Stack spacing={2} padding={2}>
          <Typography variant="h5">Warning</Typography>
          <Typography>{`Deleting room ${room.number} from ${building.name} is a permanent action and cannot be undone. Are you sure?`}</Typography>
          <Box>
            <Button variant="outlined" onClick={() => setDeleteDialogOpen(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => deleteRoom({ variables: { _id: room._id } })}>
              Delete
            </Button>
          </Box>
        </Stack>
      </Dialog>
    </>
  );
}

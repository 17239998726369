import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const SMPHAnimals = [
  'Mouse',
  'Rat',
  'Rabbit',
  'Domestic Pig',
  'Cat',
  'Dog',
  'Ferret',
  'Frog',
  'Gerbil',
  'Guinea Pig',
  'Hamster',
  'Zebrafish',
  'USDA Covered Mice',
  'USDA Covered Rats',
];

const SpeciesSearch = ({
  key,
  noType,
  isRequired,
  sendInput,
  protocol,
  fromAnimalOrder,
  useStandardList,
  val,
  defaultValue,
}) => {
  const [species = protocol ? protocol.species : null] = useState();
  const handleSelect = (value) => sendInput('species', value);
  const getOptions = () => {
    // let res = fromAnimalOrder || useStandardList || !Array.isArray(species) ? SMPHAnimals : species
    let res = SMPHAnimals;
    return defaultValue ? [defaultValue, ...res] : res;
  };

  return (
    <Autocomplete
      className="to-clear"
      key={key}
      freeSolo={!noType}
      id="species-search"
      options={SMPHAnimals}
      style={{ width: 300, display: 'inline-block', margin: 10 }}
      value={defaultValue}
      onChange={(e, value) => handleSelect(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          required={isRequired}
          onChange={(e) => handleSelect(e.target.value)}
          className="to-clear"
          placeholder="Enter species"
          label="Species"
          value={val}
          variant="outlined"
        />
      )}
    />
  );
};

export default SpeciesSearch;

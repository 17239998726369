import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Navbar from './components/Navbar';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider, Paper, useMediaQuery } from '@material-ui/core';
import AnimalOrderForm from './components/forms/AnimalOrderForm';
import InternalTransferForm from './components/forms/InternalTransferForm';
import ImportForm from './components/forms/ImportForm';
import ExportForm from './components/forms/ExportForm';
import Dashboard from './components/Dashboard';
import Auth from './components/Auth';
import Admin from './components/Admin';
import legacySARs from './components/LegacySARs';
import legacyDARs from './components/LegacyDARs';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import './App.css';
// import { ApolloClient } from 'apollo-client';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
// import { InMemoryCache } from 'apollo-cache-inmemory'
import Login from './components/Login';
import NotAuthenticated from './components/NotAuthenticated';
import Footer from './components/Footer';
import PrivateRoute from './components/PrivateRoute';
import AdminNotificationsTable from './components/AdminNotificationsTable';
import InventoryItemsTable from './components/InventoryItemsTable';
import SpecialConditionsLookup from './components/forms/SpecialConditionsLookup';
import { Grid } from '@material-ui/core';
// import { createUploadLink } from 'apollo-upload-client'
import SurplusItems from './components/SurplusItems';
import { getServerUrl } from './utils';
import InventorySelectionForm from './components/forms/InventorySelectionForm';
import InventoryOrdersTable from './components/InventoryOrdersTable';
import InventoryOrder from './components/InventoryOrder';
import BRMSInventoryLinks from './components/BRMSInventoryLinks';
import FastRequestForm from './components/forms/FastRequestForm';
import ProcedureRequestForm from './components/forms/ProcedureRequestForm';
import FastRequestsTable from './components/FastRequestsTable';
import FastRequest from './components/FastRequest';
import ProcedureRequestsTable from './components/ProcedureRequestsTable';
import FastingLinks from './components/FastingLinks';
import APIKeyForm from './components/forms/APIKeyForm';
import EmergencyAlertForm from './components/forms/EmergencyAlertForm';
import FastRequestTableDisplayView from './components/FastRequestTableDisplayView';
import ProcedureLinks from './components/ProcedureLinks';
import ProcedureRequest from './components/ProcedureRequest';
import Facilities from './components/Facilities/Facilities';
import SDSLinks from './components/SDSLinks';
import AddSDSLink from './components/forms/AddSDSLink';
import ResourceScheduler from './components/ResourceScheduler';
import UpdateArrow from './components/UpdateArrow';
import UserNotifications from './components/UserNotifications';
import NotificationAdmin from './components/NotificationAdmin';
import AddResourceForm from './components/forms/AddResourceForm';
import VCTDashboard from './components/VCT/VCTDashboard';

import CustomNotifications from './components/CustomNotifications';
import CustomNotificationConditionForm from './components/forms/CustomNotificationCondition';
import NoMatch from './components/NoMatch';

const theme = createTheme({
  palette: {
    primary: {
      main: '#c5050c',
    },
    secondary: {
      main: '#0479a8',
    },
  },
});

const defaultClient = new ApolloClient({
  link: createHttpLink({
    uri: getServerUrl(),
  }),
  cache: new InMemoryCache({
    dataIdFromObject: (o) => JSON.stringify(o),
  }),
});

function App() {
  const [client, setClient] = useState();
  const [authenticated, setAuthenticated] = useState();
  const [authorized, setAuthorized] = useState();
  const [user, setUser] = useState();
  const [authInitialized, setAuthInitialized] = useState(false);
  const useMargins = useMediaQuery('(min-width:900px)');

  const onAuth = ({ authorized, authenticated, client, user }) => {
    setAuthorized(authorized);
    setAuthenticated(authenticated);
    setClient(client);
    setUser(user);
    setAuthInitialized(true);
  };

  const resetState = () => {
    setAuthorized();
    setAuthenticated();
    setClient();
    setUser();
    setAuthInitialized();
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      //assign interval to a variable to clear it.
      const token = localStorage.getItem('rmaToken');
      if (authInitialized && !token) {
        // Force auth check if token is missing
        // Prevents preserved session after logout in separate tab
        resetState();
      }
    }, 1000);

    return () => clearInterval(intervalId); //This is important
  }, [authInitialized]);

  const logout = () => {
    localStorage.removeItem('rmaToken');
    localStorage.removeItem('shib_idp_session_ss');
    localStorage.removeItem('sessionExpiration');
    resetState();
    window.location.href = 'https://login.wisc.edu/logout';
  };

  const privateRouteProps = {
    authenticated,
    authorized,
    authInitialized,
    user,
    onAuth,
    resetState,
  };
  return (
    <ApolloProvider client={client ? client : defaultClient}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <div className="App">
            <Navbar user={user} authorizedGroups={['admin', 'superUser', 'husbandrySuperuser']} logout={logout} />
            <br />

            <Switch>
              <Route exact path="/Login" component={Login} />
              <Route exact path="/auth" children={<Auth onAuth={onAuth} />} />
              <Route exact path="/auth/:token" children={<Auth onAuth={onAuth} />} />
              <Route path="/authentication-required" component={NotAuthenticated} />
              <Route path="/public-sds" component={SDSLinks} />
              {/* <PrivateRoute
                {...privateRouteProps}
                authorizedGroups={['admin', 'superuser', 'husbandrySuperuser', 'brmsSuperuser']}
                exact
                path="/admin"
                component={Admin}
              /> */}
              <PrivateRoute {...privateRouteProps} exact path="/" component={Dashboard} />
              <PrivateRoute {...privateRouteProps} path="/animal-order" component={AnimalOrderForm} />
              <PrivateRoute {...privateRouteProps} path="/internal-transfer" component={InternalTransferForm} />
              <PrivateRoute {...privateRouteProps} path="/import" component={ImportForm} />
              <PrivateRoute {...privateRouteProps} path="/export" component={ExportForm} />
              <PrivateRoute {...privateRouteProps} path="/request-procedure" component={ProcedureRequestForm} />
              <PrivateRoute {...privateRouteProps} path="/request-fast" component={FastRequestForm} />
              <PrivateRoute {...privateRouteProps} path="/fast-requests" exact component={FastRequestsTable} />
              <PrivateRoute {...privateRouteProps} path="/fast-requests/:number" component={FastRequest} />
              {/* <PrivateRoute {...privateRouteProps} path="/usda-fasting" component={FastingLinks} /> */}
              <PrivateRoute {...privateRouteProps} path="/procedures" component={ProcedureLinks} />
              <PrivateRoute
                {...privateRouteProps}
                path="/procedure-requests"
                exact
                component={ProcedureRequestsTable}
              />
              <PrivateRoute {...privateRouteProps} path="/procedure-requests/:number" component={ProcedureRequest} />
              <PrivateRoute {...privateRouteProps} exact path="/user-notifications" component={UserNotifications} />
              <PrivateRoute
                {...privateRouteProps}
                path="/user-notifications/unsubscribe/:protocolId"
                component={UserNotifications}
              />
              <PrivateRoute {...privateRouteProps} path="/notification-admin" component={NotificationAdmin} />
              <PrivateRoute
                {...privateRouteProps}
                path="/legacy-sar"
                authorizedGroups={['husbandrySuperuser', 'admin']}
                component={legacySARs}
              />
              <PrivateRoute
                {...privateRouteProps}
                path="/legacy-dar"
                authorizedGroups={['husbandrySuperuser', 'admin']}
                component={legacyDARs}
              />
              <PrivateRoute
                {...privateRouteProps}
                path="/inventory-items"
                authorizedGroups={['brmsSuperuser', 'admin']}
                component={InventoryItemsTable}
              />
              <PrivateRoute
                {...privateRouteProps}
                path="/custom-notifications"
                authorizedGroups={['superuser', 'admin', 'brmsSuperuser']}
                component={CustomNotifications}
              />
              <PrivateRoute
                {...privateRouteProps}
                path="/custom-notification-form"
                authorizedGroups={['superuser', 'admin', 'brmsSuperuser']}
                component={CustomNotificationConditionForm}
              />
              <PrivateRoute
                {...privateRouteProps}
                path="/order-inventory"
                exact
                authorizedGroups={['admin', 'brmsSuperuser', 'brms']}
                component={InventorySelectionForm}
              />
              <PrivateRoute
                {...privateRouteProps}
                path="/inventory-orders"
                exact
                authorizedGroups={['admin', 'brmsSuperuser', 'brms']}
                component={InventoryOrdersTable}
              />
              <PrivateRoute
                {...privateRouteProps}
                path="/inventory-orders/:number"
                authorizedGroups={['admin', 'brmsSuperuser', 'brms']}
                component={InventoryOrder}
              />
              {/* <PrivateRoute
                {...privateRouteProps}
                path="/brms-inventory-tools"
                authorizedGroups={['admin', 'brmsSuperuser', 'brms']}
                component={BRMSInventoryLinks}
              /> */}
              {/* <PrivateRoute {...privateRouteProps} path="/surplus-items" authorizedGroups={['admin', 'brmsSuperuser']} component={SurplusItems} /> */}
              <PrivateRoute
                {...privateRouteProps}
                path="/special-considerations"
                authorizedGroups={['brmsSuperuser', 'admin', 'brms', 'husbandry']}
                component={SpecialConditionsLookup}
              />
              <PrivateRoute
                {...privateRouteProps}
                path="/create-api-key"
                authorizedGroups={['admin']}
                component={APIKeyForm}
              />
              <PrivateRoute
                {...privateRouteProps}
                path="/emergency-alert"
                authorizedGroups={['admin', 'brmsSuperuser']}
                component={EmergencyAlertForm}
              />
              <PrivateRoute
                {...privateRouteProps}
                path="/fast-request-display"
                authorizedGroups={['fastRequestReadOnly', 'admin']}
                component={FastRequestTableDisplayView}
              />
              <PrivateRoute
                {...privateRouteProps}
                path="/facilities"
                exact
                authorizedGroups={['admin', 'facilitySupervisor', 'brmsSuperuser']}
                component={Facilities}
              />
              <PrivateRoute
                {...privateRouteProps}
                path="/vct-dashboard"
                exact
                component={VCTDashboard} //
              />
              <PrivateRoute
                {...privateRouteProps}
                path="/vct-dashboard/:vcrId"
                exact
                component={VCTDashboard} //
              />
              <PrivateRoute
                {...privateRouteProps}
                path="/vct-dashboard/?form"
                exact
                component={VCTDashboard}
                authorizedGroups={['admin', 'brms', 'brmsSuperuser']}
              />

              <PrivateRoute
                {...privateRouteProps}
                path="/add-sds"
                exact
                authorizedGroups={['brmsSuperuser', 'admin']}
                component={AddSDSLink}
              />
              <PrivateRoute {...privateRouteProps} path="/sds" component={SDSLinks} />
              <PrivateRoute
                {...privateRouteProps}
                path="/add-resource"
                authorizedGroups={['brmsSuperuser', 'admin']}
                component={AddResourceForm}
              />
              <PrivateRoute {...privateRouteProps} exact path="/resource-scheduler" component={ResourceScheduler} />
              <PrivateRoute
                {...privateRouteProps}
                path="/resource-scheduler/:resourceId"
                component={ResourceScheduler}
              />
              <PrivateRoute
                {...privateRouteProps}
                path="/update-arrow"
                exact
                authorizedGroups={['admin']}
                component={UpdateArrow}
              />
              <Route path="*" component={NoMatch} />
            </Switch>
            <Footer />
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  );
}

console.log(process.env.NODE_ENV);
export default App;

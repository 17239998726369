import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';


const ConditionalClearInput = ({section, effect, label}) => {
    
    return (
        <FormControlLabel
        className="checkbox"
        style={{ width:300, marginLeft:10, marginRight:10 }}
        control={
            <Checkbox
            onChange={e => effect(e.target.checked)}
            ></Checkbox>
        }
        label={label}
        ></FormControlLabel>
        );
    };

ConditionalClearInput.defaultProps = {
    label: "Save this section after submission" 
}

export default ConditionalClearInput;



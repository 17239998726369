import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));
const EmailChips = ({ options = [], onDelete = console.log, canDelete = false }) => {
    const classes = useStyles();

    return (
        <Container component="ul" className={classes.root}>
            {options.map((val, i) => {

                return (
                    <li key={i + 'emailChip'}>
                        <Chip
                            label={val}
                            onDelete={canDelete ? () => onDelete(val) : null}
                            className={classes.chip}
                        />
                    </li>
                );
            })}
        </Container>
    );
}

export default EmailChips
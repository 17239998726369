import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { buildingCodeToName } from '../utils'
import NoImage from '../static/no-image.png'

const useStyles = makeStyles({
  root: {
    width: 345,
  },
  media: {
    maxHeight: 200,
    maxWidth: '100%'
  },
  text: {
    textAlign: "left",
    overflow: "hidden",
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  descriptionText: {
    textAlign: "left",
    height: 60,
    overflow: "hidden",
    textOverflow: 'ellipsis',
  },
  content: {
    paddingTop: 16
  }
});

const SurplusItem = ({ surplusItem, onClick = console.log }) => {
  const classes = useStyles();
  const { root, media, text, descriptionContainer, content } = classes
  const { name, description, quantity, images, building, dateSubmitted, submittedBy: { firstName, lastName, emailAddress } } = surplusItem;

  return (
    <Card className={root} onClick={onClick}>
      <CardActionArea className={content}>
        <img src={images.length > 0 ? `data:image/png;base64,${images[0].encoding}` : NoImage} className={media} />
        <CardContent>
          <Typography variant="h5" component="h2" className={text}>
            {name}
          </Typography>
          <Typography variant="body2" component="p" className={text}>
            <span style={{ fontWeight: "bold" }}>Qty:</span>&nbsp;{quantity}
          </Typography>
          <Typography variant="body2" component="p" className={text}>
            <span style={{ fontWeight: "bold" }}>Building:</span>&nbsp;{buildingCodeToName(building) || building}
          </Typography>
          <Typography gutterBottom variant="body2" component="p" className={text}>
            <span style={{ fontWeight: "bold" }}>Contact:</span>&nbsp;{`${firstName} ${lastName}`}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p" className={text}>
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

// const SurplusItem = ({ surplusItem: { name, description, quantity, images, building, dateSubmitted } }) => {

//   return (
//     <Grid item md={4} sm={6} xs={12}>
//       <Paper style={styles.root}>
//         <div style={styles.container} >
//           {images.length > 0 && <img src={`data:image/png;base64,${images[0]}`} style={styles.image} />}
//         </div>
//         <div style={styles.container}>
//           <Typography>{name}</Typography>
//           <Typography>Qty:&nbsp;{quantity}</Typography>
//           <Typography>{building}</Typography>
//           <Typography>{description}</Typography>
//         </div>
//       </Paper>
//     </Grid>
//   );
// }

// const styles = {
//   image: { maxWidth: 200, maxHeight: 200, margin: '0px, auto' },
//   container: {
//     height: 200,
//     width: '100%'
//   },
//   root: { padding: 20, margin: '10px, auto' }
// }

export default SurplusItem;
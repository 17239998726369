import { gql } from '@apollo/client';
import { CREATE_ERROR } from '../../global/graphql';

export { CREATE_ERROR };

export const GET_FACILITIES = gql`
  query facilities {
    facilities {
      _id
      name
      fpmCode
      address
      supervisor {
        _id
        firstName
        lastName
        emailAddress
      }
      contacts {
        _id
        firstName
        lastName
        emailAddress
      }
      isActive
      isBRMS
      rooms {
        _id
        number
        floor
        type
        species {
          _id
          name
          isUSDA
        }
        resources {
          _id
          resourceName
        }
        hazards
        notes
        updatedAt
      }
    }
  }
`;

export const ADD_ROOM = gql`
  mutation addRoom($input: RoomInput) {
    addRoom(input: $input)
  }
`;

export const UPDATE_ROOM = gql`
  mutation updateRoom($input: RoomInput) {
    updateRoom(input: $input)
  }
`;

export const DELETE_ROOM = gql`
  mutation deleteRoom($_id: ID) {
    deleteRoom(_id: $_id)
  }
`;

export const ADD_CONTACT = gql`
  mutation addContact($buildingId: ID!, $userId: ID!) {
    addContact(buildingId: $buildingId, userId: $userId)
  }
`;
export const REMOVE_CONTACT = gql`
  mutation removeContact($buildingId: ID!, $userId: ID!) {
    removeContact(buildingId: $buildingId, userId: $userId)
  }
`;

export const UPDATE_BRMS = gql`
  mutation updateBRMS($buildingId: ID!, $isBRMS: Boolean!) {
    updateBRMS(buildingId: $buildingId, isBRMS: $isBRMS)
  }
`;
export const UPDATE_ACTIVE = gql`
  mutation updateActive($buildingId: ID!, $isActive: Boolean!) {
    updateActive(buildingId: $buildingId, isActive: $isActive)
  }
`;
export const GET_SPECIES = gql`
  query species {
    species {
      _id
      name
    }
  }
`;

export const GET_USER_OPTIONS = gql`
  query userOptions($fragment: String) {
    userOptions(fragment: $fragment) {
      _id
      PVI
      firstName
      lastName
      emailAddress
    }
  }
`;

import React, { useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import StandardWrapper from '../design/StandardWrapper';

const CreateInventoryItem = ({ user, onSubmit, onClose }) => {
  const [name, setName] = useState();
  const [unit, setUnit] = useState('');
  const [vendor, setVendor] = useState();
  const [code, setCode] = useState();
  const [description, setDescription] = useState();
  const [toast, setToast] = useState();
  const [errorDialog, setErrorDialog] = useState();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [key, setKey] = useState(false);

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToast(false);
    setErrorDialog(false);
  };

  const resetForm = () => {
    setName('');
    setUnit('');
    setVendor('');
    setCode('');
    setDescription('');
    setKey(!key);
  };

  const CREATE_ERROR = gql`
    mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
      createError(PVI: $PVI, action: $action, error: $error, data: $data)
    }
  `;
  const [createError] = useMutation(CREATE_ERROR);

  const CREATE_INVENTORY_ITEM = gql`
    mutation CreateInventoryItem(
      $name: String!
      $unit: String!
      $vendor: String!
      $code: String
      $description: String
    ) {
      createInventoryItem(name: $name, unit: $unit, vendor: $vendor, code: $code, description: $description) {
        _id
      }
    }
  `;

  const getVariables = () => {
    return {
      name,
      unit,
      vendor,
      code,
      description,
    };
  };
  const [createInventoryItem] = useMutation(CREATE_INVENTORY_ITEM, {
    onError(e) {
      setLoading(false);
      setErrorDialog(true);
      setToast(false);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'createInventoryItem',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            vars: getVariables(),
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setLoading(false);
        setToast(true);
        resetForm();
        onSubmit();
        setSubmitting(false);
      } else setToast(false);
    },
  });

  const handleSubmit = () => {
    const variables = getVariables();
    console.log('variables: ', variables);
    createInventoryItem({ variables });
  };
  return submitting ? (
    <CircularProgress />
  ) : (
    <StandardWrapper>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setLoading(true);
          // sleep(500);
          handleSubmit();
        }}
      >
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <h1> Create New Inventory Item </h1>
          </Grid>
          <Grid item xs={9}>
            <TextField
              variant="outlined"
              style={{ width: 300, margin: 10 }}
              required
              label="Name"
              placeholder="E.g. Large Nitrile Gloves"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              variant="outlined"
              style={{ width: 300, margin: 10 }}
              required
              label="Unit"
              placeholder="E.g. 24 boxes"
              value={unit}
              onChange={(e) => {
                setUnit(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              variant="outlined"
              style={{ width: 300, margin: 10 }}
              required
              label="Vendor"
              placeholder="E.g. Verona Safety"
              value={vendor}
              onChange={(e) => {
                setVendor(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              variant="outlined"
              style={{ width: 300, margin: 10 }}
              label="Item No."
              placeholder="Optional ID number"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              variant="outlined"
              label="Description"
              placeholder="Optional additional description"
              multiline
              fullWidth
              style={{ width: 300, margin: 10 }}
              rows={3}
              rowsMax={6}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={9}>
            {onClose && (
              <Button
                style={{ width: 125, marginRight: 50 }}
                variant="contained"
                // color="secondary"
                onClick={onClose}
              >
                Close
              </Button>
            )}
            <Button style={{ width: 125 }} variant="contained" color="primary" type="submit" disabled={loading}>
              Submit
              {loading && (
                <CircularProgress disableShrink style={{ position: 'absolute', zIndex: 2, color: 'white' }} size={24} />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar open={toast} autoHideDuration={6000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          Item has been created!
        </Alert>
      </Snackbar>
      <Dialog open={errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Failed to create item</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </StandardWrapper>
  );
};

export default CreateInventoryItem;

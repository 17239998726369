import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Divider, ListItemText, Typography, Paper, Tooltip } from '@material-ui/core';
import { getName, capitalize } from '../../utils';
import moment from 'moment-timezone';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: 0,
        borderRadius: 0
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
}));

const MessageList = ({ messages = [] }) => {
    const classes = useStyles();

    return (
        <span className={classes.root}>
            <List className={classes.root} component={Paper}>
                {messages.map(({ text, dateSubmitted, submittedBy }, i) => {
                    return (
                        <span>
                            <ListItem alignItems="flex-start" style={{ backgroundColor: (messages.length - i) % 2 === 0 ? '#fff' : '#f7f7f7' }}>
                                <Tooltip title={moment(dateSubmitted).format("M/D/YYYY h:mm a")} placement="left">
                                    <ListItemText
                                        primary={capitalize(moment(dateSubmitted).fromNow())}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    component="span"
                                                    variant="body2"

                                                    color="textPrimary"
                                                >
                                                    {getName(submittedBy)}:&nbsp;
                                                </Typography>
                                                {text}
                                            </React.Fragment>
                                        }
                                    />
                                </Tooltip>
                            </ListItem>
                            <Divider />
                        </span>
                    )
                })}
            </List>
        </span>
    );
}

export default MessageList;
import React, { useState } from 'react';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  CircularProgress,
  Typography,
  Box,
  Stack,
  FormHelperText,
  Dialog,
} from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { RRule } from 'rrule';

export default function CancelReservationForm(props) {
  const {
    reservation,
    cancelReservation,
    setSubmissionLoading,
    submissionLoading,
    onComplete = console.log,
    onError = console.log,
    onClose = console.log,
  } = props;

  const [cancelType, setCancelType] = useState('occurrence');

  const getSubmitTitle = () => {
    if (isSeries) {
      return cancelType === 'series' ? 'Delete Series' : 'Delete Occurrence';
    }
    return 'Cancel Reservation';
  };

  const deleteOccurrence = async () => {
    const { id, startDate, rRule } = reservation;
    if (!id || !startDate || !moment(startDate).isValid || !rRule || _.isEmpty(rRule)) {
      onError('There was a problem deleting this occurrence');
    }
    try {
      const res = await cancelReservation({
        variables: {
          _id: id,
          cancelOccurrenceInSeries: true,
          occurrenceDate: moment(startDate).utc(),
        },
      });

      onComplete(res);
    } catch (e) {
      onError(e);
    }
    setSubmissionLoading(false);
  };

  const deleteEvent = async () => {
    const { id } = reservation;
    if (!id) {
      onError('There was a problem deleting this occurrence');
    }
    try {
      const res = await cancelReservation({
        variables: {
          _id: id,
        },
      });

      onComplete(res);
    } catch (e) {
      onError(e);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmissionLoading(true);

    if (isSeries && cancelType === 'occurrence') {
      deleteOccurrence();
    } else {
      deleteEvent();
    }
  };

  const stringifySeries = () => {
    const rule = new RRule(reservation.rRule);
    return rule.toText();
  };

  const isSeries = !!reservation.rRule && !_.isEmpty(reservation.rRule);
  const getTitle = () => {
    if (isSeries) {
      return cancelType === 'occurrence'
        ? `Cancelling single occurrence on 
        ${moment(reservation.startDate).format('ddd M/D/YYYY hh:mm a')}`
        : `Cancelling series: 
        ${stringifySeries()}`;
    }
    return `Cancelling reservation on ${moment(reservation.startDate).format('ddd M/D/YYYY hh:mm a')}`;
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack spacing={2} padding={2}>
        <Typography variant="h5">Cancel Reservation</Typography>
        {isSeries ? (
          <>
            <Typography variant="h6" sx={{ paddingInline: '.5em' }}>
              {getTitle()}
            </Typography>

            <RadioGroup defaultValue="occurrence" onChange={(e) => setCancelType(e.target.value)}>
              <FormControlLabel value="occurrence" control={<Radio />} label="Cancel this occurrence" />
              <FormControlLabel value="series" control={<Radio />} label="Cancel entire series" />
            </RadioGroup>
          </>
        ) : (
          <Typography>{getTitle()}</Typography>
        )}
        {isSeries && cancelType === 'series' && (
          <FormHelperText>
            Deleting the series will also delete any individual occurrences that were modified after the series was
            created.
          </FormHelperText>
        )}
        <Box sx={{ display: 'flex' }}>
          {/* Close Form */}
          <Button style={{ width: 125, margin: 10 }} variant="outlined" onClick={onClose}>
            Close
          </Button>

          {/* Submit Form */}
          <Button style={{ width: 125, margin: 10 }} variant="contained" color="primary" type="submit">
            {getSubmitTitle()}
          </Button>
        </Box>
      </Stack>
      <Dialog open={submissionLoading}>
        <Box padding={5}>
          <CircularProgress />
        </Box>
      </Dialog>
    </Box>
  );
}

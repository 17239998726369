import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { AlertTitle, Alert } from '@material-ui/lab';
import {
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  TextField,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { sleep } from '../../utils';
import { gql } from '@apollo/client';

const CREATE_USER_MUTATION = gql`
  mutation CreateUser($emailAddress: String!, $PVI: String!, $firstName: String!, $lastName: String!) {
    createUser(emailAddress: $emailAddress, PVI: $PVI, firstName: $firstName, lastName: $lastName) {
      displayName
      PVI
      emailAddress
      firstName
      lastName
      displayName
    }
  }
`;

const CreateUserForm = ({ user }) => {
  const [createUser] = useMutation(CREATE_USER_MUTATION, {
    onError(err) {
      setLoading(false);
      console.log(err);
      setErrorDialog(true);
      setToast(false);
    },
    onCompleted() {
      if (!errorDialog) {
        setLoading(false);
        setToast(true);
        resetState();
      } else setToast(false);
    },
  });

  const [toast, setToast] = useState();
  const [errorDialog, setErrorDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const [PVI, setPVI] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const resetState = () => {
    setPVI('');
    setEmailAddress('');
    setFirstName('');
    setLastName('');
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
    setErrorDialog(false);
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setLoading(true);
          sleep(500);
          createUser({
            variables: {
              PVI,
              firstName,
              lastName,
              emailAddress,
            },
          });
        }}
        onKeyPress={(event) => {
          if (event.which === 13 /* Enter */) {
            event.preventDefault();
          }
        }}
      >
        <br></br>
        <Grid container justifyContent="center" style={{ maxWidth: 500 }}>
          <Grid item xs={10}>
            <h1> Create User </h1>
          </Grid>
          <Grid item xs={10}>
            <TextField
              required
              variant="outlined"
              style={{ width: '100%', margin: 10 }}
              label="PVI"
              placeholder="PVI"
              value={PVI}
              onChange={(e) => {
                setPVI(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              required
              variant="outlined"
              style={{ width: '100%', margin: 10 }}
              label="Email Address"
              type="email"
              value={emailAddress}
              onChange={(e) => {
                setEmailAddress(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              required
              variant="outlined"
              style={{ width: '100%', margin: 10 }}
              label="First Name"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              required
              variant="outlined"
              style={{ width: '100%', margin: 10 }}
              label="Last Name"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={10}>
            <Button
              style={{ width: 120, float: 'right' }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading || !(PVI && emailAddress && firstName && lastName)}
            >
              Submit
              {loading && (
                <CircularProgress disableShrink style={{ position: 'absolute', zIndex: 2, color: 'white' }} size={24} />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Success */}
      <Snackbar open={toast} autoHideDuration={6000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          User has been created!
        </Alert>
      </Snackbar>

      {/* Non-specific error */}
      <Dialog open={errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>User creation attempt failed.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateUserForm;
